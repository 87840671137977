import React, { useState, useReducer, useContext, useEffect, useRef, useMemo } from 'react';
import { Container, Row, Col, Table, Dropdown, DropdownButton, Modal, Button, Form, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import dottedLoaderSmall from './../../assets/images/dottedLoaderSmall.gif';
import { ThemeContext } from "../../projectContext/ThemeContext";
import { CloseButton } from 'react-bootstrap';

export default function SuggestedTitleModal(props) {

    const ThemeContextData = useContext(ThemeContext);

    const handleCheckboxChange = (e) => {
        if (document.getElementById(e.target.id).checked) {
            props.titleAdded(e.target.id);
        }
        else {
            props.titleRemove();
        }
    }


    return (
        <>
            <Modal className='customModal suggestTitleModal' show={props.show} centered >
                <Modal.Header className='flex-wrap justify-content-center'>
                    <div>
                        <Modal.Title className='justify-content-center mb-2'>
                            Suggested Article Title
                        </Modal.Title>
                        <p className='mb-0'>Select any One title for your article from the list or click 'Close' to enter your own.</p>
                    </div>
                    <CloseButton onClick={() => props.suggestTitleModalClose()} />
                </Modal.Header>
                <Modal.Body className='p-0'>
                    <div className='pb-4'>
                        {props.loader ?
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img src={dottedLoaderSmall} alt='dotted' className='img-fluid' style={{ width: '64px', height: '64px' }} />
                            </div> :
                            props.suggestKeywordModalResponse ? <div className='py-5 d-flex flex-column align-items-center'>
                                <span className='mb-4'>
                                    <svg width="118" height="118" viewBox="0 0 118 118" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_816_835)">
                                            <path d="M114.69 101.651C116.803 103.763 116.803 107.186 114.69 109.298C113.634 110.354 112.251 110.882 110.868 110.882C109.484 110.882 108.1 110.354 107.044 109.298L85.1697 87.4269L85.167 87.4242C86.6209 86.3916 88.0073 85.2213 89.3109 83.9177C90.6136 82.6177 91.7813 81.2313 92.8139 79.78H92.8166L114.69 101.651Z" fill="#FFC843" />
                                            <path d="M92.9238 87.5592C93.9798 86.5032 95.3635 85.9748 96.7472 85.9748C98.1309 85.9748 99.5146 86.5032 100.571 87.5592L100.621 87.5827L92.8166 79.78H92.8139C91.7822 81.2313 90.6137 82.6177 89.311 83.9177C88.0074 85.2213 86.6219 86.3916 85.168 87.4242L85.1698 87.4269L92.6798 94.9351C90.8217 92.8105 90.8991 89.584 92.9238 87.5592Z" fill="#FFA816" />
                                            <path d="M1.72852 7.25977H111.489V55.6375H1.72852V7.25977Z" fill="white" />
                                            <path d="M43.4473 55.6374H105.769C104.281 50.6203 101.56 45.892 97.5984 41.93C84.9001 29.2317 64.3164 29.2317 51.6181 41.93C47.656 45.892 44.9354 50.6203 43.4473 55.6374Z" fill="#E1EAF8" />
                                            <path d="M93.1047 61.0526C93.1047 76.0259 80.9655 88.1651 65.9913 88.1651C51.0171 88.1651 38.8779 76.0259 38.8779 61.0526C38.8779 46.0784 51.0171 33.9392 65.9913 33.9392C80.9655 33.9392 93.1047 46.0784 93.1047 61.0526Z" fill="#987CFF" />
                                            <path d="M40.0117 68.8281C43.3544 80.0122 53.7201 88.165 65.9917 88.165C78.2632 88.165 88.6289 80.0122 91.9716 68.8281H40.0117Z" fill="white" />
                                            <path d="M98.4012 55.6375C99.7615 63.9317 97.8997 72.6535 92.8141 79.78C91.7815 81.2313 90.6138 82.6177 89.3112 83.9176C88.0076 85.2212 86.6212 86.3916 85.1672 87.4242C72.4698 96.4827 54.7255 95.3123 43.3308 83.9176C35.6327 76.2195 32.6024 65.6206 34.2408 55.6375C35.3014 49.1556 38.3317 42.9401 43.3308 37.9409C56.0291 25.2427 76.6129 25.2427 89.3112 37.9409C94.3103 42.9401 97.3397 49.1556 98.4012 55.6375ZM88.3668 68.8328C91.3215 60.5746 89.493 50.9867 82.8787 44.3734C73.7338 35.2275 58.9082 35.2275 49.7633 44.3734C43.1517 50.9849 41.3205 60.5665 44.2725 68.8247C45.4015 71.9873 47.2326 74.9546 49.7633 77.4861C58.9082 86.6311 73.7338 86.6311 82.8787 77.4861C85.4094 74.9546 87.2378 71.9927 88.3668 68.8328Z" fill="#FFC843" />
                                            <path d="M60.4908 58.8568C60.8284 59.1944 61.2704 59.3628 61.7133 59.3628C62.1554 59.3628 62.5983 59.1944 62.9359 58.8568L66.311 55.4808L69.687 58.8568C70.0246 59.1935 70.4667 59.3628 70.9087 59.3628C71.3516 59.3628 71.7936 59.1944 72.1312 58.8568C72.8064 58.1816 72.8064 57.0869 72.1312 56.4117L68.7561 53.0366L72.1312 49.6606C72.8064 48.9863 72.8064 47.8916 72.1312 47.2164C71.456 46.5412 70.3622 46.5412 69.687 47.2164L66.311 50.5924L62.9359 47.2164C62.2607 46.5412 61.166 46.5412 60.4908 47.2164C59.8165 47.8916 59.8165 48.9863 60.4908 49.6606L63.8668 53.0366L60.4908 56.4117C59.8156 57.0869 59.8156 58.1816 60.4908 58.8568Z" fill="white" />
                                            <path d="M115.913 100.429L95.0539 79.5693C98.6379 74.0696 100.562 67.6471 100.562 60.9284C100.562 51.7826 97.0003 43.1841 90.5328 36.7175C84.0652 30.2499 75.4677 26.6885 66.321 26.6885C57.1751 26.6885 48.5767 30.2499 42.1091 36.7175C35.6425 43.1841 32.0811 51.7826 32.0811 60.9284C32.0811 70.0751 35.6425 78.6727 42.1091 85.1402C48.7846 91.8148 57.5532 95.153 66.321 95.153C72.8173 95.153 79.3118 93.3191 84.9565 89.6559L105.821 110.521C107.212 111.911 109.04 112.606 110.867 112.606C112.695 112.606 114.522 111.911 115.913 110.521C118.696 107.738 118.696 103.211 115.913 100.429ZM44.5543 82.696C38.7403 76.882 35.5381 69.1514 35.5381 60.9293C35.5381 52.7062 38.7403 44.9765 44.5543 39.1617C50.3691 33.3478 58.0988 30.1455 66.321 30.1455C74.5431 30.1455 82.2737 33.3478 88.0885 39.1617C93.9025 44.9765 97.1047 52.7071 97.1047 60.9293C97.1047 69.1514 93.9025 76.882 88.0885 82.696C76.0862 94.6983 56.5566 94.6983 44.5543 82.696ZM113.469 108.076C112.035 109.51 109.7 109.51 108.266 108.076L87.7915 87.6015C88.7385 86.839 89.6541 86.0189 90.5337 85.1402C91.4087 84.2651 92.228 83.3496 92.9959 82.4007L113.469 102.874C114.903 104.308 114.903 106.641 113.469 108.076Z" fill="#434C54" />
                                            <path d="M27.3303 53.909H3.45703V8.98828H109.76V53.909H105.366C104.411 53.909 103.637 54.6823 103.637 55.6375C103.637 56.5918 104.411 57.366 105.366 57.366H111.488C112.443 57.366 113.217 56.5918 113.217 55.6375V7.25977C113.217 6.30548 112.443 5.53125 111.488 5.53125H1.72852C0.773331 5.53125 0 6.30548 0 7.25977V55.6375C0 56.5918 0.773331 57.366 1.72852 57.366H27.3303C28.2855 57.366 29.0589 56.5918 29.0589 55.6375C29.0589 54.6823 28.2855 53.909 27.3303 53.909Z" fill="#434C54" />
                                            <path d="M15.5137 17.4958C14.5594 17.4958 13.7852 18.2692 13.7852 19.2244C13.7852 20.1786 14.5594 20.9529 15.5137 20.9529H97.7037C98.658 20.9529 99.4322 20.1786 99.4322 19.2244C99.4322 18.2692 98.658 17.4958 97.7037 17.4958H15.5137Z" fill="#A9B1B8" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_816_835">
                                                <rect width="118" height="118" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                                <p className='h4 fw-normal'>No Relevant Keywords found.</p>
                            </div> :
                                <div className='table-responsive position-relative'>
                                    <Table className='mb-0 mb-3'>
                                        <thead>
                                            <tr>
                                                <th className='select'>Select</th>
                                                <th className='title'>Article Title</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.suggestTitleList.map(item =>
                                                <tr>
                                                    <td>
                                                        <Form.Check
                                                            type={'checkbox'}
                                                            id={`${item}`}
                                                            checked={props.suggestTitleCheckedData.includes(item) ? true : false}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                    </td>
                                                    <td>
                                                        <p className='mb-0 fw-medium'>{item}</p>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                        }
                        <div className='d-flex gap-3 justify-content-center'>
                            {props.loader ? "" :
                                <Button type='button' variant='primary' className='d-inline-flex custom-btn gap-2 px-4'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.regenerateCall();
                                    }}
                                >
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_830_1653)">
                                            <path d="M0 15.6093C0.028125 15.4968 0.05625 15.3843 0.0890625 15.2765C0.196875 14.9296 0.384375 14.6296 0.642187 14.3718C3.81562 11.1983 6.98438 8.02491 10.1625 4.85616C10.7812 4.23741 11.5359 4.05928 12.3609 4.34053C13.1906 4.62178 13.6594 5.24053 13.7719 6.10303C13.8469 6.6796 13.6781 7.2046 13.3172 7.65928C13.2516 7.74366 13.1766 7.81866 13.1016 7.89366C9.95625 11.039 6.81563 14.1796 3.67031 17.3249C3.225 17.7702 2.70469 18.0093 2.07188 18.0046C1.0875 17.9952 0.215625 17.264 0.0421875 16.289C0.0328125 16.2515 0.01875 16.214 0.0046875 16.1765C0 15.9843 0 15.7968 0 15.6093ZM12.4078 6.3421C12.375 6.02335 12.1641 5.7421 11.8734 5.65772C11.55 5.56397 11.3109 5.69522 11.0906 5.91553C7.95938 9.05147 4.82812 12.178 1.69688 15.3093C1.65 15.3562 1.60313 15.3983 1.56563 15.4499C1.24219 15.853 1.44375 16.4343 1.94063 16.5655C2.25 16.6452 2.47969 16.5093 2.69063 16.2983C5.82188 13.1671 8.95313 10.0358 12.0797 6.9046C12.1266 6.85772 12.1828 6.81553 12.2109 6.76397C12.2812 6.62803 12.3422 6.48272 12.4078 6.3421Z" fill="#552DEA" />
                                            <path d="M7.63191 0C7.86629 0.107812 8.07254 0.248437 8.13347 0.520312C8.15222 0.614062 8.15691 0.717187 8.15691 0.815625C8.1616 1.00781 8.15691 1.2 8.15691 1.40625C8.39597 1.40625 8.61629 1.40625 8.8366 1.40625C9.25847 1.40625 9.56785 1.70625 9.56316 2.11875C9.55847 2.51719 9.25379 2.80781 8.8366 2.8125C8.62097 2.8125 8.40535 2.8125 8.15691 2.8125C8.15691 3.05625 8.1616 3.29062 8.15691 3.52969C8.15222 3.89062 7.87566 4.19062 7.53347 4.21875C7.16316 4.25156 6.82566 4.02187 6.76941 3.66562C6.73191 3.44062 6.75535 3.21094 6.75066 2.98125C6.75066 2.93437 6.75066 2.8875 6.75066 2.8125C6.50691 2.8125 6.27254 2.81719 6.03347 2.8125C5.69597 2.80781 5.41004 2.56406 5.3491 2.24062C5.28816 1.89844 5.48035 1.55625 5.81316 1.44844C5.89285 1.42031 5.9866 1.41094 6.07097 1.41094C6.2866 1.40625 6.50222 1.41094 6.75066 1.41094C6.75066 1.2375 6.76472 1.06875 6.74597 0.904687C6.70847 0.46875 6.87254 0.164062 7.28035 0C7.39754 0 7.51472 0 7.63191 0Z" fill="#552DEA" />
                                            <path d="M18 2.2875C17.8828 2.43281 17.7844 2.60625 17.6391 2.71406C17.5359 2.79375 17.3672 2.79844 17.2219 2.80781C17.0203 2.82188 16.8141 2.8125 16.5938 2.8125C16.5938 3.05156 16.5938 3.27187 16.5938 3.49219C16.5891 3.91875 16.2937 4.22344 15.8812 4.21875C15.4828 4.21406 15.1922 3.90937 15.1875 3.49219C15.1875 3.27187 15.1875 3.05156 15.1875 2.8125C14.9484 2.8125 14.7281 2.8125 14.5078 2.8125C14.0812 2.80781 13.7812 2.5125 13.7812 2.1C13.7859 1.70156 14.0906 1.41094 14.5078 1.40625C14.7234 1.40625 14.9391 1.40625 15.1875 1.40625C15.1875 1.23281 15.2016 1.06875 15.1828 0.9C15.1453 0.46875 15.3047 0.164062 15.7172 0C15.8344 0 15.9516 0 16.0687 0C16.4719 0.164062 16.6359 0.46875 16.5984 0.895313C16.5844 1.05938 16.5938 1.22812 16.5938 1.40625C16.8187 1.40625 17.025 1.39688 17.2266 1.41094C17.3672 1.42031 17.5359 1.42969 17.6391 1.50469C17.7844 1.6125 17.8828 1.78594 18 1.93125C18 2.05313 18 2.17031 18 2.2875Z" fill="#552DEA" />
                                            <path d="M18 10.7251C17.8828 10.8704 17.7844 11.0438 17.6391 11.1516C17.5359 11.2313 17.3672 11.236 17.2219 11.2454C17.0203 11.2594 16.8141 11.2501 16.5938 11.2501C16.5938 11.4891 16.5938 11.7094 16.5938 11.9297C16.5891 12.3563 16.2937 12.661 15.8812 12.6563C15.4828 12.6516 15.1922 12.3469 15.1875 11.9297C15.1875 11.7094 15.1875 11.4891 15.1875 11.2501C14.9484 11.2501 14.7281 11.2501 14.5078 11.2501C14.0812 11.2501 13.7812 10.9501 13.7812 10.5376C13.7859 10.1391 14.0906 9.84849 14.5078 9.8438C14.7281 9.8438 14.9484 9.8438 15.1875 9.8438C15.1875 9.60005 15.1875 9.37037 15.1875 9.14537C15.1922 8.74224 15.4875 8.44224 15.8812 8.43755C16.2844 8.43287 16.5891 8.73755 16.5938 9.14537C16.5938 9.36568 16.5938 9.59068 16.5938 9.8438C16.7672 9.8438 16.9312 9.85787 17.1 9.83912C17.5312 9.79693 17.8359 9.96568 18 10.3688C18 10.4907 18 10.6079 18 10.7251Z" fill="#552DEA" />
                                            <path d="M11.8503 0C11.8925 0.0234375 11.93 0.0421875 11.9722 0.065625C12.2722 0.210938 12.4362 0.534375 12.3706 0.848438C12.3003 1.17656 12.0143 1.40625 11.6768 1.40625C11.3393 1.40625 11.0534 1.17188 10.9831 0.848438C10.9175 0.529688 11.0815 0.210938 11.3815 0.065625C11.4237 0.046875 11.4612 0.0234375 11.5034 0C11.6159 0 11.7284 0 11.8503 0Z" fill="#552DEA" />
                                            <path d="M11.6625 12.6563C11.2734 12.6516 10.9688 12.3422 10.9688 11.9485C10.9688 11.5501 11.2828 11.2454 11.6859 11.2501C12.075 11.2547 12.375 11.5688 12.3703 11.9626C12.3703 12.3516 12.0563 12.6563 11.6625 12.6563Z" fill="#552DEA" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_830_1653">
                                                <rect width="18" height="18" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    Regenerate!</Button>
                            }

                            <Button type='button' variant='primary' className='d-inline-flex gap-2 px-4'
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.suggestTitleModalClose();
                                }}
                            >
                                <span className='icon icon-save'></span>
                                Save</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}