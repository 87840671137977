import { getTokenSession, subUser } from "./Auth";
import { getCreditsFunction } from "./CreditsController";

const savedImages = async (ThemeContextData) => {
    let _images = null;
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
    };
    const response = await fetch(
        ThemeContextData.apiURL + 'picture-me/my-images',
        requestOptions
    )
        .then((response) => response.json())
        .then((result) => {
            _images = result.response;
            // return _images
            // setSavedImage(result.response);
        });
    return _images
}

const PictureDeleteController = async (ThemeContextData, PictureId, setTryLater) => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
        picture_id: PictureId
    })

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw
    };
    try {
        const response = await fetch(`${ThemeContextData.apiURL}picture-me/delete-picture`, requestOptions);
        const result = await response.json();

        if (result.response.ok) {
            return { success: true }
        }
    } catch (error) {
        setTryLater(true)
    }
    // return _images

}

function fetchProjectImages(ThemeContextData, itemId) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
        projectId: itemId
    });
    var requestOptions = {
        method: "Post",
        headers: myHeaders,
        redirect: "follow",
        body: raw
    };

    // Return the fetch promise
    return fetch(ThemeContextData.apiURL + 'picture-me/project-images', requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.ok) {
                return result.response.images; // Return images here
            } else {
                throw new Error('Failed to fetch images');
            }
        })
        .catch(error => {
            console.error('Error fetching project images:', error);
            return []; // Return an empty array in case of an error
        });
}

async function handleSubmitProvider(
    e,
    {
        setTryLater,
        imageGenerating,
        ThemeContextData,
        setImageGenerating,
        setGenerateLoader,
        setBlanceExhaustedModal,
        setGeneratedImage,
        savedImagesCallback,
        getCreditsFunctionCallback,
        setIsSubmitting,
        imageStyleOption,
        imageStyleData,
        artMediumOption,
        imageDetailOption,
        detailData,
        expressionOption,
        expressionData,
        lookOption,
        lookData,
        describeImage,
        imageName,
        getIdsOnly,
        projectOption,
        counter,
        imageSizeOption,
        getGPTVersion,
    }
) {
    e.preventDefault();
    setIsSubmitting(true);

    if (!imageGenerating) {
        setImageGenerating(true);
        setGenerateLoader(true);

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getTokenSession());
        myHeaders.append("accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        const style = imageStyleOption === null ? imageStyleData[0].text : imageStyleOption.text;
        const art_medium = artMediumOption.text;
        const detail = imageDetailOption === null ? detailData[0].text : imageDetailOption.text;
        const expression = expressionOption === null ? expressionData[0].text : expressionOption.text;
        const look = lookOption === null ? lookData[0].text : lookOption.text;

        const formData = JSON.stringify({
            style,
            art_medium,
            detail,
            expression,
            look,
            describeImage,
            name: imageName,
            categories: getIdsOnly,
            projectId: projectOption?.id,
            quantity: counter,
            res: imageSizeOption.text,
            gpt: getGPTVersion,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: formData,
        };

        try {
            const response = await fetch(ThemeContextData.apiURL + 'picture-me/generate-image', requestOptions);
            const result = await response.json();

            if (!result.response.ok && result.response.message === 'Not enough balance for images left.') {
                setBlanceExhaustedModal(true);
            } else {
                setGeneratedImage(result.response);
                await savedImages(ThemeContextData);
                await getCreditsFunction(ThemeContextData);
            }
        } catch (error) {
            setTryLater(true);
            console.error('Error:', error);
        } finally {
            setImageGenerating(false);
            setIsSubmitting(false);
            setGenerateLoader(false);
        }
    }
}

export {
    savedImages,
    PictureDeleteController,
    fetchProjectImages,
    handleSubmitProvider
}