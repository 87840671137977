import English from "../assets/flags/English.png";
import Afrikaans from "../assets/flags/Afrikaans.png";
import Arabic from "../assets/flags/Arabic.png";
import Armenia from "../assets/flags/Armenia.png";
import Azerbaijan from "../assets/flags/Azerbaijan.png";
import Belarus from "../assets/flags/Belarus.png";
import Bosnia from "../assets/flags/Bosnia.png";
import Bulgarian from "../assets/flags/Bulgarian.png";
import Catalonia from "../assets/flags/Catalonia.png";
import Chinese from "../assets/flags/Chinese.png";
import Croatia from "../assets/flags/Croatia.png";
import Czech from "../assets/flags/Czech.png";
import Danish from "../assets/flags/Danish.png";
import Dutch from "../assets/flags/Dutch.png";
import Estonian from "../assets/flags/Estonia.png";
import Finnish from "../assets/flags/Finnish.png";
import French from "../assets/flags/French.png";
import Filipino from "../assets/flags/Filipino.png";
import Galician from "../assets/flags/Indonesian.png";
import German from "../assets/flags/German.png";
import Greek from "../assets/flags/Greek.png";
import Hebrew from "../assets/flags/Hebrew.png";
import Hindi from "../assets/flags/Hindi.png";
import Hungarian from "../assets/flags/Hungarian.png";
import Iceland from "../assets/flags/Iceland.png";
import Indonesian from "../assets/flags/Indonesian.png";
import Italian from "../assets/flags/Italian.png";
import Japanese from "../assets/flags/Japanese.png";
import Karnatka from "../assets/flags/Karnatka.png";
import Kazakh from "../assets/flags/Kazakh.png";
import Korean from "../assets/flags/Korean.png";
import Latvian from "../assets/flags/Latvian.png";
import Lithuanian from "../assets/flags/Lithuanian.png";
import Macedonian from "../assets/flags/Macedonian.png";
import Malay from "../assets/flags/Malay.png";
import Marathi from "../assets/flags/Marathi.png";
import Maori from "../assets/flags/Maori.png";
import Nepali from "../assets/flags/Nepali.png";
import Norwegian from "../assets/flags/Norwegian.png";
import Persian from "../assets/flags/Persian.png";
import Polish from "../assets/flags/Polish.png";
import Portuguese from "../assets/flags/Portuguese.png";
import Romanian from "../assets/flags/Romanian.png";
import Russia from "../assets/flags/Russia.png";
import Serbia from "../assets/flags/Serbia.png";
import Slovak from "../assets/flags/Slovak.png";
import Slovenia from "../assets/flags/Slovenia.png";
import Spanish from "../assets/flags/Spanish.png";
import Tanzania from "../assets/flags/Tanzania.png";
import Swedish from "../assets/flags/Swedish.png";
import Tamil from "../assets/flags/Tamil.png";
import Thai from "../assets/flags/Thai.png";
import Turkish from "../assets/flags/Turkish.png";
import Ukraine from "../assets/flags/Ukraine.png";
import Pakistan from "../assets/flags/Pakistan.png";
import Vietnamese from "../assets/flags/Vietnamese.png";
import Wales from "../assets/flags/Wales.png";

function langDataFunction(){
    return [
        {
            value: 1,
            text: 'English',
            flag: English
        },
        {
            value: 2,
            text: 'Afrikaans',
            flag: Afrikaans
        },
        {
            value: 3,
            text: 'Arabic',
            flag: Arabic
        },
        {
            value: 4,
            text: 'Armenian',
            flag: Armenia
        },
        {
            value: 5,
            text: 'Azerbaijani',
            flag: Azerbaijan
        },
        {
            value: 6,
            text: 'Belarusian',
            flag: Belarus
        },
        {
            value: 7,
            text: 'Bosnian',
            flag: Bosnia
        },
        {
            value: 8,
            text: 'Bulgarian',
            flag: Bulgarian
        },
        {
            value: 9,
            text: 'Catalan',
            flag: Catalonia
        },
        {
            value: 10,
            text: 'Chinese',
            flag: Chinese
        },
        {
            value: 11,
            text: 'Croatian',
            flag: Croatia
        },
        {
            value: 12,
            text: 'Czech',
            flag: Czech
        },
        {
            value: 13,
            text: 'Danish',
            flag: Danish
        },
        {
            value: 14,
            text: 'Dutch',
            flag: Dutch
        },
        {
            value: 15,
            text: 'Estonian',
            flag: Estonian
        },
        {
            value: 16,
            text: 'Finnish',
            flag: Finnish
        },
        {
            value: 17,
            text: 'French',
            flag: French
        },
        {
            value: 18,
            text: 'Filipino',
            flag: Filipino
        },
        {
            value: 19,
            text: 'Galician',
            flag: Galician
        },
        {
            value: 20,
            text: 'German',
            flag: German
        },
        {
            value: 21,
            text: 'Greek',
            flag: Greek
        },
        {
            value: 22,
            text: 'Hebrew',
            flag: Hebrew
        },
        {
            value: 23,
            text: 'Hindi',
            flag: Hindi
        },
        {
            value: 24,
            text: 'Hungarian',
            flag: Hungarian
        },
        {
            value: 25,
            text: 'Icelandic',
            flag: Iceland
        },
        {
            value: 26,
            text: 'Indonesian',
            flag: Indonesian
        },
        {
            value: 27,
            text: 'Italian',
            flag: Italian
        },
        {
            value: 28,
            text: 'Japanese',
            flag: Japanese
        },
        {
            value: 29,
            text: 'Kannada',
            flag: Karnatka
        },
        {
            value: 30,
            text: 'Kazakh',
            flag: Kazakh
        },
        {
            value: 31,
            text: 'Korean',
            flag: Korean
        },
        {
            value: 32,
            text: 'Latvian',
            flag: Latvian
        },
        {
            value: 33,
            text: 'Lithuanian',
            flag: Lithuanian
        },
        {
            value: 34,
            text: 'Macedonian',
            flag: Macedonian
        },
        {
            value: 35,
            text: 'Malay',
            flag: Malay
        },
        {
            value: 36,
            text: 'Marathi',
            flag: Marathi
        },
        {
            value: 37,
            text: 'Maori',
            flag: Maori
        },
        {
            value: 38,
            text: 'Nepali',
            flag: Nepali
        },
        {
            value: 39,
            text: 'Norwegian',
            flag: Norwegian
        },
        {
            value: 40,
            text: 'Persian',
            flag: Persian
        },
        {
            value: 41,
            text: 'Polish',
            flag: Polish
        },
        {
            value: 42,
            text: 'Portuguese',
            flag: Portuguese
        },
        {
            value: 43,
            text: 'Romanian',
            flag: Romanian
        },
        {
            value: 44,
            text: 'Russia',
            flag: Russia
        },
        {
            value: 45,
            text: 'Serbian',
            flag: Serbia
        },
        {
            value: 46,
            text: 'Slovak',
            flag: Slovak
        },
        {
            value: 47,
            text: 'Slovenian',
            flag: Slovenia
        },
        {
            value: 48,
            text: 'Spanish',
            flag: Spanish
        },
        {
            value: 49,
            text: 'Swahili',
            flag: Tanzania
        },
        {
            value: 50,
            text: 'Swedish',
            flag: Swedish
        },
        {
            value: 51,
            text: 'Tamil',
            flag: Tamil
        },
        {
            value: 52,
            text: 'Thai',
            flag: Thai
        },
        {
            value: 53,
            text: 'Turkish',
            flag: Turkish
        },
        {
            value: 54,
            text: 'Ukrainian',
            flag: Ukraine
        },
        {
            value: 55,
            text: 'Urdu',
            flag: Pakistan
        },
        {
            value: 56,
            text: 'Vietnamese',
            flag: Vietnamese
        },
        {
            value: 57,
            text: 'Welsh',
            flag: Wales
        }
    ]
}

function langFilterDataFunction(){
    return {
        English: {
            value: 1,
            text: 'English',
            flag: English
        },
        Afrikaans: {
            value: 2,
            text: 'Afrikaans',
            flag: Afrikaans
        },
        Arabic: {
            value: 3,
            text: 'Arabic',
            flag: Arabic
        },
        Armenian: {
            value: 4,
            text: 'Armenian',
            flag: Armenia
        },
        Azerbaijani: {
            value: 5,
            text: 'Azerbaijani',
            flag: Azerbaijan
        },
        Belarusian: {
            value: 6,
            text: 'Belarusian',
            flag: Belarus
        },
        Bosnian: {
            value: 7,
            text: 'Bosnian',
            flag: Bosnia
        },
        Bulgarian: {
            value: 8,
            text: 'Bulgarian',
            flag: Bulgarian
        },
        Catalan: {
            value: 9,
            text: 'Catalan',
            flag: Catalonia
        },
        Chinese: {
            value: 10,
            text: 'Chinese',
            flag: Chinese
        },
        Croatian: {
            value: 11,
            text: 'Croatian',
            flag: Croatia
        },
        Czech: {
            value: 12,
            text: 'Czech',
            flag: Czech
        },
        Danish: {
            value: 13,
            text: 'Danish',
            flag: Danish
        },
        Dutch: {
            value: 14,
            text: 'Dutch',
            flag: Dutch
        },
        Estonian: {
            value: 15,
            text: 'Estonian',
            flag: Estonian
        },
        Finnish: {
            value: 16,
            text: 'Finnish',
            flag: Finnish
        },
        French: {
            value: 17,
            text: 'French',
            flag: French
        },
        Filipino: {
            value: 18,
            text: 'Filipino',
            flag: Filipino
        },
        Galician: {
            value: 19,
            text: 'Galician',
            flag: Galician
        },
        German: {
            value: 20,
            text: 'German',
            flag: German
        },
        Greek: {
            value: 21,
            text: 'Greek',
            flag: Greek
        },
        Hebrew: {
            value: 22,
            text: 'Hebrew',
            flag: Hebrew
        },
        Hindi: {
            value: 23,
            text: 'Hindi',
            flag: Hindi
        },
        Hungarian: {
            value: 24,
            text: 'Hungarian',
            flag: Hungarian
        },
        Icelandic: {
            value: 25,
            text: 'Icelandic',
            flag: Iceland
        },
        Indonesian: {
            value: 26,
            text: 'Indonesian',
            flag: Indonesian
        },
        Italian: {
            value: 27,
            text: 'Italian',
            flag: Italian
        },
        Japanese: {
            value: 28,
            text: 'Japanese',
            flag: Japanese
        },
        Kannada: {
            value: 29,
            text: 'Kannada',
            flag: Karnatka
        },
        Kazakh: {
            value: 30,
            text: 'Kazakh',
            flag: Kazakh
        },
        Korean: {
            value: 31,
            text: 'Korean',
            flag: Korean
        },
        Latvian: {
            value: 32,
            text: 'Latvian',
            flag: Latvian
        },
        Lithuanian: {
            value: 33,
            text: 'Lithuanian',
            flag: Lithuanian
        },
        Macedonian: {
            value: 34,
            text: 'Macedonian',
            flag: Macedonian
        },
        Malay: {
            value: 35,
            text: 'Malay',
            flag: Malay
        },
        Marathi: {
            value: 36,
            text: 'Marathi',
            flag: Marathi
        },
        Maori: {
            value: 37,
            text: 'Maori',
            flag: Maori
        },
        Nepali: {
            value: 38,
            text: 'Nepali',
            flag: Nepali
        },
        Norwegian: {
            value: 39,
            text: 'Norwegian',
            flag: Norwegian
        },
        Persian: {
            value: 40,
            text: 'Persian',
            flag: Persian
        },
        Polish: {
            value: 41,
            text: 'Polish',
            flag: Polish
        },
        Portuguese: {
            value: 42,
            text: 'Portuguese',
            flag: Portuguese
        },
        Romanian: {
            value: 43,
            text: 'Romanian',
            flag: Romanian
        },
        Russia: {
            value: 44,
            text: 'Russia',
            flag: Russia
        },
        Serbian: {
            value: 45,
            text: 'Serbian',
            flag: Serbia
        },
        Slovak: {
            value: 46,
            text: 'Slovak',
            flag: Slovak
        },
        Slovenian: {
            value: 47,
            text: 'Slovenian',
            flag: Slovenia
        },
        Spanish: {
            value: 48,
            text: 'Spanish',
            flag: Spanish
        },
        Swahili: {
            value: 49,
            text: 'Swahili',
            flag: Tanzania
        },
        Swedish: {
            value: 50,
            text: 'Swedish',
            flag: Swedish
        },
        Tamil: {
            value: 51,
            text: 'Tamil',
            flag: Tamil
        },
        Thai: {
            value: 52,
            text: 'Thai',
            flag: Thai
        },
        Turkish: {
            value: 53,
            text: 'Turkish',
            flag: Turkish
        },
        Ukrainian: {
            value: 54,
            text: 'Ukrainian',
            flag: Ukraine
        },
        Urdu: {
            value: 55,
            text: 'Urdu',
            flag: Pakistan
        },
        Vietnamese: {
            value: 56,
            text: 'Vietnamese',
            flag: Vietnamese
        },
        Welsh: {
            value: 57,
            text: 'Welsh',
            flag: Wales
        }
    };
}

const languageChangeFunction = (e) => {
    if (e.text === "English") {
        return 'WriteMe is your #1 AI-powered content writing assistant to write high-quality content in a few clicks at only a fraction of the cost!'
    }
    else if (e.text === "Afrikaans") {
        return "Writeme is 'n AI-aangedrewe skryfhulpmiddel wat jou help om binne net 'n paar sekondes hoëgehalte inhoud te skep, teen 'n fraksie van die koste!"
    }
    else if (e.text === "Arabic") {
        return 'وايتمي هو أداة كتابة تعمل بالذكاء الاصطناعي تساعدك على إنشاء محتوى عالي الجودة، في ثوانٍ معدودة، وبجزء بسيط من التكلفة!'
    }
    else if (e.text === "Armenian") {
        return 'Writeme-ը առաջարկում է արհեստական խելքով աշխատող գրական գործիք, որը օգնում է ստեղծել բարձրորակ պարունակություն՝ միայն մեկ քանի վայրկյանում, ծախսերի մի մասի առնվազն'
    }
    else if (e.text === "Azerbaijani") {
        return 'Writeme yüksək keyfiyyətli məzmun yaratmağınıza bir neçə saniyə ərzində, xərclərin bir hissəsi ilə kömək edən süni intellektlə işləyən bir yazı alətidir!'
    }
    else if (e.text === "Belarusian") {
        return 'Writeme - гэта інструмент для пісьма, які працуе на штучным інтэлекты, які дапамагае ствараць высакаякасны кантэнт за лічаныя секунды і за дробязь кошту!'
    }
    else if (e.text === "Bosnian") {
        return 'Writeme je alat za pisanje pokretan AI-jem koji vam pomaže da stvorite visokokvalitetan sadržaj, u samo nekoliko sekundi, po djeliću troškova!'
    }
    else if (e.text === "Bulgarian") {
        return 'писмен инструмент, задвижван от изкуствен интелект, който ви помага да създавате висококачествено съдържание, само за няколко секунди, на част от цената!'
    }
    else if (e.text === "Catalan") {
        return "Writeme és una eina d'escriptura impulsada per IA que t'ajuda a crear contingut d'alta qualitat, en només uns segons, a una fracció del cost!"
    }
    else if (e.text === "Chinese") {
        return 'Writeme 是一个由 AI 驱动的写作工具，它可以帮助您在短短几秒钟内以极低的成本创造高质量内容！'
    }
    else if (e.text === "Croatian") {
        return 'Writeme je alat za pisanje pokretan AI-jem koji vam pomaže stvoriti visokokvalitetan sadržaj, u samo nekoliko sekundi, po djeliću cijene!'
    }
    else if (e.text === "Czech") {
        return '"Writeme je psací nástroj poháněný AI, který vám pomáhá vytvářet vysoce kvalitní obsah během několika sekund za zlomek ceny!'
    }
    else if (e.text === "Danish") {
        return 'Writeme er et AI-drevet skriveværktøj, der hjælper dig med at skabe indhold af høj kvalitet på bare få sekunder til en brøkdel af prisen!'
    }
    else if (e.text === "Dutch") {
        return "Writeme is een door AI aangedreven schrijfhulpmiddel dat je helpt om binnen enkele seconden inhoud van hoge kwaliteit te creëren, voor een fractie van de kosten!"
    }
    else if (e.text === "Estonian") {
        return "Writeme on tehisintellektil põhinev kirjutamisvahend, mis aitab sul luua kõrgekvaliteedilist sisu vaid mõne sekundiga, murdosa hinnaga!"
    }
    else if (e.text === "Finnish") {
        return "Writeme on tekoälyllä toimiva kirjoitustyökalu, joka auttaa sinua luomaan laadukasta sisältöä vain muutamassa sekunnissa, murto-osalla kustannuksista!"
    }
    else if (e.text === "French") {
        return "Writeme est un outil d'écriture alimenté par l'IA qui vous aide à créer du contenu de haute qualité, en quelques secondes seulement, pour une fraction du coût !"
    }
    else if (e.text === "Galician") {
        return "Writeme é unha ferramenta de escritura potenciada por IA que che axuda a crear contido de alta calidade, en só uns segundos, por unha fracción do custo!"
    }
    else if (e.text === "German") {
        return "Writeme ist ein von KI angetriebenes Schreibwerkzeug, das Ihnen hilft, hochwertige Inhalte in nur wenigen Sekunden zu einem Bruchteil der Kosten zu erstellen!"
    }
    else if (e.text === "Greek") {
        return "Το Writeme είναι ένα εργαλείο συγγραφής που λειτουργεί με τεχνητή νοημοσύνη και σας βοηθά να δημιουργήσετε περιεχόμενο υψηλής ποιότητας, σε μόλις λίγα δευτερόλεπτα, με ένα κλάσμα του κόστους!"
    }
    else if (e.text === "Hebrew") {
        return "Writeme הוא כלי כתיבה המופעל על ידי מלאכותית שעוזר לך ליצור תוכן איכותי במהירות של כמה שניות, בחלק קטן מהעלות!"
    }
    else if (e.text === "Hindi") {
        return "Writeme एक AI-संचालित लेखन उपकरण है जो आपको कुछ ही सेकंड में उच्च-गुणवत्ता वाली सामग्री बनाने में मदद करता है, लागत का एक अंश में!"
    }
    else if (e.text === "Hungarian") {
        return "A Writeme egy AI által támogatott íróeszköz, amely segít neked minőségi tartalmat létrehozni, mindössze néhány másodperc alatt, a költségek töredékéért!"
    }
    else if (e.text === "Icelandic") {
        return "Writeme er ritverkfæri knúið af gervigreind sem hjálpar þér að búa til hágæða efni á aðeins örfáum sekúndum, á broti af kostnaðinum!"
    }
    else if (e.text === "Indonesian") {
        return "Writeme adalah alat penulisan yang ditenagai oleh AI yang membantu Anda membuat konten berkualitas tinggi, hanya dalam beberapa detik, dengan sebagian kecil dari biaya!"
    }
    else if (e.text === "Italian") {
        return "Writeme è uno strumento di scrittura alimentato dall'IA che ti aiuta a creare contenuti di alta qualità, in pochi secondi, a una frazione del costo!"
    }
    else if (e.text === "Japanese") {
        return "WritemeはAIパワーを搭載したライティングツールで、ほんの数秒で、コストのほんの一部で、高品質のコンテンツを作成するのに役立ちます！"
    }
    else if (e.text === "Kannada") {
        return "Writeme ಎಂಬುದು ಒಂದು AI ಶಕ್ತಿಯ ಬರವಣಿಗೆ ಸಾಧನವಾಗಿದ್ದು, ಕೇವಲ ಕೆಲವು ಸೆಕೆಂಡುಗಳಲ್ಲಿ, ವೆಚ್ಚದ ಒಂದು ಭಾಗದಲ್ಲಿ, ನೀವು ಉನ್ನತ ಗುಣಮಟ್ಟದ ವಿಷಯವನ್ನು ಸೃಷ್ಟಿಸಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ!"
    }
    else if (e.text === "Kazakh") {
        return "Writeme - бұл сізге бірнеше секунд ішінде, шығынның бір бөлігі қана қажет болатын, жоғары сапалы мазмұн жасауға көмектесетін, AI қуаттандыратын жазба құралы!"
    }
    else if (e.text === "Korean") {
        return 'Writeme는 단 몇 초 만에 비용의 일부만으로 고품질 콘텐츠를 생성할 수 있도록 도와주는 AI 기반 글쓰기 도구입니다!'
    }
    else if (e.text === "Latvian") {
        return "Writeme ir AI darbināms rakstīšanas rīks, kas palīdz jums izveidot augstas kvalitātes saturu, tikai dažu sekunžu laikā, par daļu no izmaksām!"
    }
    else if (e.text === "Lithuanian") {
        return "Writeme yra dirbtinio intelekto varomas rašymo įrankis, kuris padeda jums sukurti aukštos kokybės turinį, vos per kelias sekundes, už nedidelę dalį išlaidų!"
    }
    else if (e.text === "Macedonian") {
        return "Writeme е алатка за пишување поддржана од вештачка интелигенција која ви помага да создадете содржина од висок квалитет, за само неколку секунди, за дел од трошокот!"
    }
    else if (e.text === "Malay") {
        return "Writeme adalah alat penulisan yang dikuasai oleh AI yang membantu anda mencipta kandungan berkualiti tinggi, dalam masa beberapa saat sahaja, dengan sebahagian kecil daripada kosnya!"
    }
    else if (e.text === "Marathi") {
        return "Writeme हे एक AI संचालित लेखन साधन आहे जे तुम्हाला काही सेकंदात, खर्चाच्या एक भागात, उच्च-दर्जाचे सामग्री तयार करण्यास मदत करते!"
    }
    else if (e.text === "Maori") {
        return "Ko Writeme he taputapu tuhituhi mana AI e awhina ana i a koe ki te hanga ihirangi kounga teitei, i roto i etahi hekona noa iho, i te wahanga iti o te utu!"
    }
    else if (e.text === "Nepali") {
        return "Writeme एक AI-संचालित लेखन उपकरण हो जसले तपाईंलाई केही सेकेन्डमा, खर्चको एक अंशमा, उच्च-गुणस्तरको सामग्री सिर्जना गर्न मद्दत गर्दछ!"
    }
    else if (e.text === "Norwegian") {
        return "Writeme er et AI-drevet skriveverktøy som hjelper deg med å lage innhold av høy kvalitet, på bare noen få sekunder, til en brøkdel av kostnaden!"
    }
    else if (e.text === "Persian") {
        return "Writeme یک ابزار نوشتاری محور AI است که به شما کمک می‌کند در عرض چند ثانیه، با بخشی از هزینه، محتوای با کیفیت بالا ایجاد کنید!"
    }
    else if (e.text === "Polish") {
        return "Writeme to narzędzie do pisania napędzane AI, które pomaga tworzyć wysokiej jakości treści w zaledwie kilka sekund, za ułamek kosztów!"
    }
    else if (e.text === "Portuguese") {
        return "Writeme é uma ferramenta de escrita alimentada por IA que ajuda você a criar conteúdo de alta qualidade, em apenas alguns segundos, por uma fração do custo!"
    }
    else if (e.text === "Romanian") {
        return "Writeme este un instrument de scris bazat pe AI care te ajută să creezi conținut de înaltă calitate, în doar câteva secunde, la o fracțiune din cost!"
    }
    else if (e.text === "Russia") {
        return 'Writeme - это инструмент для написания текстов на основе искусственного интеллекта, который помогает вам создавать контент высокого качества всего за несколько секунд и по части стоимости!'
    }
    else if (e.text === "Serbian") {
        return "Writeme је алат за писање на бази вештачке интелигенције који вам помаже да створите садржај високог квалитета, за само неколико секунди, по делу цене!"
    }
    else if (e.text === "Slovak") {
        return "Writeme je nástroj na písanie poháňaný umelou inteligenciou, ktorý vám pomáha vytvárať kvalitný obsah, za pár sekúnd, za zlomok ceny!"
    }
    else if (e.text === "Slovenian") {
        return "Writeme je orodje za pisanje, ki ga poganja umetna inteligenca, ki vam pomaga ustvarjati visokokakovostno vsebino, v samo nekaj sekundah, za delček stroškov!"
    }
    else if (e.text === "Spanish") {
        return "Writeme es una herramienta de escritura impulsada por IA que te ayuda a crear contenido de alta calidad, en solo unos segundos, ¡a una fracción del costo!"
    }
    else if (e.text === "Swahili") {
        return "Writeme ni chombo cha kuandika kinachotumia AI ambacho kinakusaidia kuunda maudhui yenye ubora wa hali ya juu, katika sekunde chache tu, kwa sehemu ndogo ya gharama!"
    }
    else if (e.text === "Swedish") {
        return "Writeme är ett AI-drivet skrivverktyg som hjälper dig att skapa högkvalitativt innehåll, på bara några sekunder, till en bråkdel av kostnaden!"
    }
    else if (e.text === "Tamil") {
        return "Writeme என்பது AI ஆல் இயக்கப்படும் எழுதும் கருவி ஆகும், இது உங்களுக்கு சில விநாடிகளில், செலவின் ஒரு பகுதியில், உயர்நிலை தரமான உள்ளடக்கத்தை உருவாக்க உதவுகிறது!"
    }
    else if (e.text === "Thai") {
        return "Writeme เป็นเครื่องมือเขียนที่ขับเคลื่อนด้วย AI ซึ่งช่วยให้คุณสร้างเนื้อหาคุณภาพสูงได้ในเวลาเพียงไม่กี่วินาที ด้วยค่าใช้จ่ายเพียงเสี้ยวส่วน!"
    }
    else if (e.text === "Turkish") {
        return "Writeme, sadece birkaç saniyede yüksek kaliteli içerik oluşturmanıza yardımcı olan, maliyetin bir kısmı ile AI destekli bir yazma aracıdır!"
    }
    else if (e.text === "Ukrainian") {
        return "Writeme - це інструмент для письма на базі ШІ, який допомагає вам створювати високоякісний контент всього за кілька секунд і за частку вартості!"
    }
    else if (e.text === "Urdu") {
        return "Writeme ایک اے آئی سے چلنے والا لکھائی کا آلہ ہے جو آپ کو چند سیکنڈوں میں، لاگت کے ایک حصے میں، اعلیٰ معیار کی مواد تخلیق کرنے میں مدد دیتا ہے!"
    }
    else if (e.text === "Vietnamese") {
        return "Writeme là công cụ viết do AI hỗ trợ, giúp bạn tạo ra nội dung chất lượng cao, chỉ trong vài giây, với một phần nhỏ của chi phí!"
    }
    else if (e.text === "Welsh") {
        return "Mae Writeme yn offeryn ysgrifennu a bwerir gan AI sy'n eich helpu i greu cynnwys o ansawdd uchel, mewn ychydig eiliadau, am ran fach o'r gost!"
    }
    else if (e.text === "Filipino") {
        return '"Ang Writeme ay isang tool sa pagsusulat na pinapabagsak ng AI na tumutulong sa iyo sa paglikha ng mataas na kalidad na nilalaman, sa loob lang ng ilang segundo, sa isang bahagya lamang ng gastos!"'
    }
}

export {
    langDataFunction,
    langFilterDataFunction,
    languageChangeFunction
}