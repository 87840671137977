import { getTokenSession, subUser } from "./Auth";

const SaveEmailTemplateController = async (ThemeContextData, id, htmlContent, json_design) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        campaign_id: id,
        content: htmlContent,
        content_json: json_design
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}automate-email/update-template-content`,
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {
            return { success: true };
        } else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        console.error("Error creating chat:", error);
        return { success: false, error: "API request failed" };
    }
};

const EmailSuggestionAPIFunction = async (
    ThemeContextData,
    setTryLater,
    AIModalOption,
    selectedIntentOption,
    selectedLangOption,
    title,
    audienceID,
) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    console.log(AIModalOption)
    console.log(selectedIntentOption)
    console.log(selectedLangOption)
    console.log(title)
    console.log(audienceID);

    const raw = JSON.stringify({
        gpt: AIModalOption.text,
        intent: selectedIntentOption.text,
        language: selectedLangOption.text,
        subject: title,
        audience_id: audienceID.id

    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw,
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'automate-email/suggest-email-outline',
            requestOptions
        );
        const result = await response.json();
        console.log(result);

        if (result.response.ok) {
            return { success: true, data: result.response.response }
        }
        else if (result.response.message) {
            return { success: false, exhausted: true }
        } else {
            return { success: false }
        }
    } catch (error) {
        setTryLater(true)
        // setSuggestKeywordModalResponse(true);
        // setLoader(prevState => ({
        //     ...prevState,
        //     suggestKeywordLoader: false
        // }));
        // setSuggestKeywordCount(0);
    }
};

const SendEmailBuilderController = async (ThemeContextData, id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        campaign_id: id
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}automate-email/send-campaign-content`,
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {
            return { success: true };
        } else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        console.error("Error creating chat:", error);
        return { success: false, error: "API request failed" };
    }
};

const GetEmailBuilderController = async (ThemeContextData, id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        campaign_id: id,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}automate-email/get-campaign`,
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {

            let start = result.response.campaign.content_json.replaceAll("```json", "");
            start = start.replaceAll("```", "");
            return { success: true, content_json: JSON.parse(start), title: result.response.campaign.subject, projectId: result.response.campaign.project.id };
        } else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        console.error("Error creating chat:", error);
        return { success: false, error: "API request failed" };
    }
};

const CustomEmailBuilderController = async (ThemeContextData, temp_name, temp_desc, temp_data) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        template_name: temp_name,
        template_desc: temp_desc,
        template_data: temp_data
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-templates/create-email-template`,
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {
            return { success: true, data: result.response.template };
        } else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        console.error("Error creating chat:", error);
        return { success: false, error: "API request failed" };
    }
};

const EditCustomEmailBuilderController = async (ThemeContextData, temp_id, temp_name, temp_desc) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        template_id: temp_id,
        template_name: temp_desc,
        template_desc: temp_name,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-templates/edit-email-template`,
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {
            return { success: true };
        } else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        console.error("Error creating chat:", error);
        return { success: false, error: "API request failed" };
    }
}

const GetCustomEmailBuilderController = async (ThemeContextData, temp_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        template_id: temp_id,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-templates/get-email-template`,
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {
            return { success: true, data: result.response.template };
        } else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        console.error("Error creating chat:", error);
        return { success: false, error: "API request failed" };
    }
};

const UpdateCustomEmailBuilderController = async (ThemeContextData, temp_id, temp_data) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        template_id: temp_id,
        template_data: temp_data
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-templates/update-email-template`,
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {
            return { success: true, data: result.response.template };
        } else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        console.error("Error creating chat:", error);
        return { success: false, error: "API request failed" };
    }
};

const GetAllEmailBuilderController = async (ThemeContextData) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        // body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-templates/get-all-email-template`,
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {

            return { success: true, templates: result.response.templates, pre_designed: result.response.pre_designed };
        } else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        console.error("Error creating chat:", error);
        return { success: false, error: "API request failed" };
    }
};

const DeleteEmailTemplateController = async (ThemeContextData, temp_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        template_id: temp_id,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-templates/delete-template
`,
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {

            return { success: true };
        } else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        console.error("Error creating chat:", error);
        return { success: false, error: "API request failed" };
    }
}

const GetBrankKitEmailBuilderController = async (ThemeContextData) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");


    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}brand-kit/get-all-brand-kit`,
            requestOptions
        );
        const result = await response.json();

        if (result.data.ok) {
            return { success: true, data: result.data.kits };
        } else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        console.error("Error creating chat:", error);
        return { success: false, error: "API request failed" };
    }
};

const CopyEmailController = async (ThemeContextData, setTryLater, id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");


    const requestData = JSON.stringify({
        campaign_id: id,
    });
    
    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: requestData,
    };
    

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}automate-email/duplicate-campaign`,
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {
            return { success: true };
        } else {
            return { success: false };
        }
    } catch (error) {
        setTryLater(true);
        console.error("Error creating chat:", error);
        return { success: false, error: "API request failed" };
    }
};

const GetAudianceForEmailBuilderController = async (ThemeContextData) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");


    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}audience/my-audiences`,
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {
            return { success: true, data: result.response };
        } else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        console.error("Error creating chat:", error);
        return { success: false, error: "API request failed" };
    }
};

const GenerateEmailAutomateController = async (ThemeContextData, id, setBlanceExhaustedModal) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        campaign_id: id,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}automate-email/generate-email`,
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {
            return { success: true, data: result.response };
        } else if (!result.response.ok && result.response.message) {
            setBlanceExhaustedModal(true);
        } else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        console.error("Error creating chat:", error);
        return { success: false, error: "API request failed" };
    }
}

const UpdateEmailAutomateController = async (ThemeContextData, id, jsonData, htmlData) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        campaign_id: id,
        content: htmlData,
        content_json: jsonData
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}automate-email/update-campaign-content`,
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {
            return { success: true };
        } else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        console.error("Error creating chat:", error);
        return { success: false, error: "API request failed" };
    }
};

const SendEmailCampaignController = async (ThemeContextData, id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        campaign_id: id
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}automate-email/send-campaign`,
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {
            return { success: true };
        } else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        console.error("Error creating chat:", error);
        return { success: false, error: "API request failed" };
    }
}


const EmailCampaignRegenerate = async (ThemeContextData, messageData) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var requestData = JSON.stringify({
        usecase: "ChatCall",
        GPT: "GPT-4o",
        messages: messageData,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}chats/chat-turbo`,
            requestOptions
        );
        const result = await response.json();

        if (result.response.data) {
            return { success: true, data: result.response.data };
        } else if (result.response.message === "balance exhausted") {
            return { success: false, message: result.response.message };
        }
        else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        console.error("Error creating chat:", error);
        return { success: false, error: "API request failed" };
    }
}

const AudienceCampaignController = async (ThemeContextData, id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var requestData = JSON.stringify({
        campaign_id: id,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}automate-email/audienceCampaign`,
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {
            return { success: true, data: result.response.audience };
        }
        else {
            return { success: false };
        }
    } catch (error) {
        console.error("Error creating chat:", error);
        return { success: false, error: "API request failed" };
    }
}

// const ChatAPIHandlerController = () => {

//     let newIndexObject = { role: "system", content: "You are a helpful assistant." };
//     let messagesDataArr = [{ role: "user", content: "Regenerate: " + data.props?.text + " " + promptValue }];

//     messagesDataArr = [newIndexObject].concat(messagesDataArr);

//     setGenerating(true);

//     var myHeaders = new Headers();
//     myHeaders.append("Authorization", "Bearer " + getTokenSession());
//     myHeaders.append("accept", "application/json");
//     myHeaders.append("Content-Type", "application/json");

//     // var raw = JSON.stringify({
//     //   GPT: "GPT-3.5-Ext",
//     //   copy: 1,
//     //   language: "English",
//     //   productName: messagesDataArr,
//     //   tone: "Appreciative",
//     //   usecase: "GrammarImprove"
//     // });

//     var raw = JSON.stringify({
//         usecase: "ChatCall",
//         GPT: "GPT-4o",
//         messages: messagesDataArr,
//     });

//     var requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         redirect: "follow",
//         body: raw,
//     };

//     fetch(
//         ThemeContextData.apiURL + 'chats/chat-turbo',
//         requestOptions
//     )
//         .then((response) => response.json())
//         .then((result) => {
//             if (result.response.data) {
//                 console.log(result);
//                 // setGeneratedResult(result.response.data);
//                 let text = result.response.data[0];
//                 console.log(text);
//                 updateData({ ...data, props: { ...data.props, text } });
//             }
//             else if (result.response.message === "balance exhausted") {
//                 setBlanceExhaustedModal(true)
//             }

//             setGenerating(false);

//         })
//         .catch((error) => { });
// }


export {
    GetEmailBuilderController,
    EmailSuggestionAPIFunction,
    SaveEmailTemplateController,
    SendEmailBuilderController,
    CustomEmailBuilderController,
    EditCustomEmailBuilderController,
    GetCustomEmailBuilderController,
    UpdateCustomEmailBuilderController,
    GetAllEmailBuilderController,
    GetBrankKitEmailBuilderController,
    CopyEmailController,
    GetAudianceForEmailBuilderController,
    GenerateEmailAutomateController,
    UpdateEmailAutomateController,
    SendEmailCampaignController,
    DeleteEmailTemplateController,
    EmailCampaignRegenerate,
    AudienceCampaignController
}
