import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Diamond from './../../assets/images/Diamond.png';
import BgCurve from './../../assets/images/Intersect.png';
import Exhusted from './../../assets/images/Exhausted.png';
import { Link } from 'react-router-dom';
import { CloseButton } from 'react-bootstrap';
import {
    getemail,
    getPassword,
} from '../../provider/Auth';

export default function UpgradeUserPlanModal(props) {

    const [userPlan, setUserPlan] = useState(localStorage.getItem('user_plan') !== 'null' ? localStorage.getItem('user_plan').split(",") : "");


    return (
        <>
            {
                userPlan.includes("2") && userPlan.length === 1 ?
                    <Modal
                        className="customModal upgradeChatModal"
                        show={props.show}
                        onHide={() => props.exhaustedModalCloser()}
                        centered
                    >
                        <Modal.Header>
                            <Modal.Title>Upgrade your plan </Modal.Title>
                            <div className="DiamondImg mx-auto">
                                <img src={Diamond} alt="" className='img-fluid' />
                            </div>
                            <img src={BgCurve} className="img-fluid bg-img" alt="Bg Curve" />
                            <CloseButton
                                onClick={() => props.exhaustedModalCloser()}
                            />
                        </Modal.Header>
                        <Modal.Body>
                            <p className="upgradeTitle text-center mb-4">Enjoy multiple premium features by upgrading your plan as you like to access more words, value add-ons, and the latest features for an affordable fee.</p>
                            <div className="upgradePackage d-flex align-items-center justify-content-center mb-4">Packages start at <strong>$10 <span>per month</span></strong></div>
                            <div className="d-flex align-items-center flex-wrap justify-content-center parallel-btn">
                            <span>
                                <form
                                    method="POST"
                                    action="https://backend.writeme.ai/custom/pushlogin.php"
                                >
                                    <input
                                        type="hidden"
                                        onChange={() => { }}
                                        value={getemail()}
                                        name="email"
                                    ></input>
                                    <input
                                        type="hidden"
                                        onChange={() => { }}
                                        value={getPassword()}
                                        name="password"
                                    ></input>
                                    <input
                                        type="hidden"
                                        onChange={() => { }}
                                        value="/amember"
                                        name="redirect"
                                    ></input>
                                    <input
                                        type="hidden"
                                        onChange={() => { }}
                                        value="DummyUsername"
                                        name="username"
                                    ></input>
                                    <Button variant='primary' type="submit" className='gap-2 fw-medium'>

                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_3415_6645)">
                                                <path d="M0.000322809 11.057C0.140875 10.3591 0.583353 9.82264 0.963363 9.25494C1.79106 8.01014 2.62396 6.76535 3.44644 5.52056C3.95139 4.75494 4.65415 4.37473 5.57554 4.37994C8.53754 4.38514 11.4943 4.38514 14.4563 4.37994C15.3413 4.37994 16.0232 4.74452 16.5126 5.47369C17.5277 6.99452 18.5427 8.51535 19.5526 10.0362C20.2918 11.1456 20.1044 12.432 19.0685 13.281C16.9967 14.9789 14.9196 16.6716 12.8426 18.3695C12.4105 18.7237 11.9733 19.0726 11.5464 19.432C11.182 19.7393 10.7603 19.906 10.3075 20.0101C10.0992 20.0101 9.891 20.0101 9.68278 20.0101C9.15181 19.9008 8.69371 19.6456 8.27726 19.2966C7.1997 18.3956 6.10131 17.5154 5.01334 16.6247C3.64426 15.5049 2.27518 14.3851 0.900895 13.2654C0.474034 12.9164 0.187725 12.4789 0.052379 11.9424C0.0367622 11.8799 0.0159397 11.8226 -0.00488281 11.7601C0.000322809 11.5258 0.000322809 11.2914 0.000322809 11.057ZM9.99512 5.93723C8.54275 5.93723 7.09559 5.94244 5.64322 5.93202C5.23197 5.92681 4.94566 6.07785 4.71662 6.42681C3.74316 7.90598 2.7593 9.37473 1.78065 10.8435C1.43187 11.3643 1.48392 11.7133 1.96805 12.1091C4.45634 14.1404 6.94462 16.1768 9.43291 18.2081C9.81292 18.5206 10.1669 18.5206 10.5417 18.2133C13.0456 16.1716 15.5495 14.1247 18.0534 12.0779C18.4855 11.7237 18.5375 11.3487 18.2252 10.8851C17.2309 9.39035 16.2315 7.90077 15.2424 6.40598C15.0342 6.08827 14.7635 5.94244 14.3835 5.94244C12.9259 5.94244 11.4631 5.93723 9.99512 5.93723Z" fill="white" />
                                                <path d="M5.5074 0C5.70001 0.145833 5.90823 0.28125 6.08522 0.447917C6.5277 0.869792 6.95456 1.30729 7.38663 1.74479C7.7354 2.09375 7.75102 2.57292 7.43868 2.89583C7.12114 3.22396 6.63181 3.20833 6.27783 2.85417C5.79891 2.375 5.32 1.89583 4.84108 1.41667C4.34134 0.911458 4.45066 0.317708 5.09616 0.03125C5.10136 0.0208333 5.10657 0.0104167 5.11698 0C5.24712 0 5.37726 0 5.5074 0Z" fill="white" />
                                                <path d="M14.8779 -0.000325521C14.9144 0.0205078 14.956 0.0413411 14.9924 0.0621745C15.4974 0.296549 15.6327 0.916341 15.2475 1.31217C14.727 1.85905 14.1908 2.3903 13.6494 2.91113C13.3371 3.21322 12.8529 3.18717 12.5614 2.88509C12.2647 2.58301 12.2543 2.10384 12.5614 1.79134C13.082 1.25488 13.613 0.728841 14.1491 0.202799C14.2428 0.109049 14.373 0.0569661 14.4875 -0.0107422C14.6176 -0.000325521 14.7478 -0.000325521 14.8779 -0.000325521Z" fill="white" />
                                                <path d="M10.1933 0C10.3182 0.078125 10.4744 0.135417 10.5576 0.244792C10.667 0.395833 10.7607 0.588542 10.7711 0.765625C10.7971 1.29167 10.7815 1.81771 10.7815 2.34375C10.7763 2.78646 10.4379 3.11979 10.0006 3.11979C9.56337 3.11979 9.22501 2.78646 9.2198 2.34375C9.21459 1.89583 9.24062 1.44271 9.21459 1C9.18336 0.520833 9.34994 0.182292 9.80283 0C9.93297 0 10.0631 0 10.1933 0Z" fill="white" />
                                                <path d="M7.88666 10.6195C9.29217 10.6195 10.6925 10.6195 12.1084 10.6195C11.9939 10.1612 11.8846 9.71849 11.7753 9.27578C11.7128 9.02578 11.6503 8.77058 11.5879 8.52058C11.4733 8.04662 11.7076 7.63516 12.1553 7.52058C12.5769 7.41641 12.9934 7.68203 13.1079 8.14037C13.3057 8.91641 13.5035 9.68724 13.6857 10.4633C13.717 10.6039 13.7742 10.6352 13.9096 10.6352C14.3729 10.63 14.831 10.63 15.2943 10.6352C15.7419 10.6352 16.0803 10.9737 16.0803 11.406C16.0855 11.8487 15.7419 12.1925 15.2891 12.1925C14.8726 12.1977 14.4562 12.1977 14.0397 12.1925C13.9044 12.1872 13.8315 12.2289 13.7794 12.3591C13.5348 12.9477 13.2849 13.531 13.0298 14.1143C12.8841 14.4425 12.6029 14.5987 12.249 14.5779C11.947 14.5622 11.6816 14.3591 11.5774 14.0622C11.5046 13.8539 11.5254 13.656 11.6087 13.4581C11.7857 13.0466 11.9627 12.6352 12.1449 12.2081C10.7081 12.2081 9.28697 12.2081 7.84501 12.2081C7.94392 12.4425 8.04282 12.6716 8.13653 12.9008C8.21461 13.0883 8.2979 13.2706 8.37598 13.4581C8.55818 13.8956 8.3916 14.3435 7.98556 14.5258C7.57432 14.7029 7.13184 14.5206 6.93923 14.0831C6.68416 13.505 6.43949 12.9268 6.19483 12.3435C6.14798 12.2289 6.09072 12.1872 5.96578 12.1925C5.54413 12.2029 5.12247 12.1977 4.69561 12.1925C4.24272 12.1925 3.89915 11.8539 3.90436 11.406C3.90436 10.9737 4.24272 10.6352 4.6904 10.63C5.13809 10.6247 5.59098 10.6195 6.03866 10.6352C6.22086 10.6404 6.28332 10.5779 6.31976 10.4112C6.49676 9.65599 6.68936 8.90078 6.87677 8.14037C6.97567 7.74974 7.26719 7.50495 7.63679 7.50495C8.01159 7.49974 8.32393 7.72891 8.40722 8.09349C8.43845 8.2237 8.43325 8.36953 8.40201 8.49453C8.24584 9.16641 8.07406 9.83308 7.90748 10.4997C7.89707 10.5362 7.89707 10.5727 7.88666 10.6195Z" fill="white" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_3415_6645">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                        Upgrade Plan
                                    </Button>
                                </form>
                            </span>
                                <span className='fw-medium' style={{ cursor: "pointer", color: "#552DEA" }} onClick={() => props.exhaustedModalCloser()}
                                >Continue with Free Plan</span>
                            </div>
                        </Modal.Body>
                    </Modal>
                    : ""}
            {userPlan.includes("4") ||
            userPlan.includes("5") ||
            userPlan.includes("13") ||
            userPlan.includes("15") ?

                <Modal className="customModal creditExhausted" show={props.show}
                       onHide={() => props.exhaustedModalCloser()}
                       centered>
                    <Modal.Header >
                        <Modal.Title>Credits Exhausted</Modal.Title>
                        <CloseButton onClick={() => props.exhaustedModalCloser()} />
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <div className="text-center mb-3">
                            <img src={Exhusted} className="img-fluid" alt="delete"></img>
                        </div>
                        <span className="d-block title font-weight-normal mb-4">You’ve exhausted your current quota of credits. Please subscribe to one of our Premium Plans to get more..</span>
                        <div className="d-flex align-items-center flex-wrap justify-content-center gap-3">
                                <span>
                                    <form
                                        method="POST"
                                        action="https://backend.writeme.ai/custom/pushlogin.php"
                                    >
                                        <input
                                            type="hidden"
                                            onChange={() => { }}
                                            value={getemail()}
                                            name="email"
                                        ></input>
                                        <input
                                            type="hidden"
                                            onChange={() => { }}
                                            value={getPassword()}
                                            name="password"
                                        ></input>
                                        <input
                                            type="hidden"
                                            onChange={() => { }}
                                            value="/amember"
                                            name="redirect"
                                        ></input>
                                        <input
                                            type="hidden"
                                            onChange={() => { }}
                                            value="DummyUsername"
                                            name="username"
                                        ></input>
                                        <Button variant='primary' type="submit" className='gap-2 fw-medium'>

                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_3415_6645)">
                                                    <path d="M0.000322809 11.057C0.140875 10.3591 0.583353 9.82264 0.963363 9.25494C1.79106 8.01014 2.62396 6.76535 3.44644 5.52056C3.95139 4.75494 4.65415 4.37473 5.57554 4.37994C8.53754 4.38514 11.4943 4.38514 14.4563 4.37994C15.3413 4.37994 16.0232 4.74452 16.5126 5.47369C17.5277 6.99452 18.5427 8.51535 19.5526 10.0362C20.2918 11.1456 20.1044 12.432 19.0685 13.281C16.9967 14.9789 14.9196 16.6716 12.8426 18.3695C12.4105 18.7237 11.9733 19.0726 11.5464 19.432C11.182 19.7393 10.7603 19.906 10.3075 20.0101C10.0992 20.0101 9.891 20.0101 9.68278 20.0101C9.15181 19.9008 8.69371 19.6456 8.27726 19.2966C7.1997 18.3956 6.10131 17.5154 5.01334 16.6247C3.64426 15.5049 2.27518 14.3851 0.900895 13.2654C0.474034 12.9164 0.187725 12.4789 0.052379 11.9424C0.0367622 11.8799 0.0159397 11.8226 -0.00488281 11.7601C0.000322809 11.5258 0.000322809 11.2914 0.000322809 11.057ZM9.99512 5.93723C8.54275 5.93723 7.09559 5.94244 5.64322 5.93202C5.23197 5.92681 4.94566 6.07785 4.71662 6.42681C3.74316 7.90598 2.7593 9.37473 1.78065 10.8435C1.43187 11.3643 1.48392 11.7133 1.96805 12.1091C4.45634 14.1404 6.94462 16.1768 9.43291 18.2081C9.81292 18.5206 10.1669 18.5206 10.5417 18.2133C13.0456 16.1716 15.5495 14.1247 18.0534 12.0779C18.4855 11.7237 18.5375 11.3487 18.2252 10.8851C17.2309 9.39035 16.2315 7.90077 15.2424 6.40598C15.0342 6.08827 14.7635 5.94244 14.3835 5.94244C12.9259 5.94244 11.4631 5.93723 9.99512 5.93723Z" fill="white" />
                                                    <path d="M5.5074 0C5.70001 0.145833 5.90823 0.28125 6.08522 0.447917C6.5277 0.869792 6.95456 1.30729 7.38663 1.74479C7.7354 2.09375 7.75102 2.57292 7.43868 2.89583C7.12114 3.22396 6.63181 3.20833 6.27783 2.85417C5.79891 2.375 5.32 1.89583 4.84108 1.41667C4.34134 0.911458 4.45066 0.317708 5.09616 0.03125C5.10136 0.0208333 5.10657 0.0104167 5.11698 0C5.24712 0 5.37726 0 5.5074 0Z" fill="white" />
                                                    <path d="M14.8779 -0.000325521C14.9144 0.0205078 14.956 0.0413411 14.9924 0.0621745C15.4974 0.296549 15.6327 0.916341 15.2475 1.31217C14.727 1.85905 14.1908 2.3903 13.6494 2.91113C13.3371 3.21322 12.8529 3.18717 12.5614 2.88509C12.2647 2.58301 12.2543 2.10384 12.5614 1.79134C13.082 1.25488 13.613 0.728841 14.1491 0.202799C14.2428 0.109049 14.373 0.0569661 14.4875 -0.0107422C14.6176 -0.000325521 14.7478 -0.000325521 14.8779 -0.000325521Z" fill="white" />
                                                    <path d="M10.1933 0C10.3182 0.078125 10.4744 0.135417 10.5576 0.244792C10.667 0.395833 10.7607 0.588542 10.7711 0.765625C10.7971 1.29167 10.7815 1.81771 10.7815 2.34375C10.7763 2.78646 10.4379 3.11979 10.0006 3.11979C9.56337 3.11979 9.22501 2.78646 9.2198 2.34375C9.21459 1.89583 9.24062 1.44271 9.21459 1C9.18336 0.520833 9.34994 0.182292 9.80283 0C9.93297 0 10.0631 0 10.1933 0Z" fill="white" />
                                                    <path d="M7.88666 10.6195C9.29217 10.6195 10.6925 10.6195 12.1084 10.6195C11.9939 10.1612 11.8846 9.71849 11.7753 9.27578C11.7128 9.02578 11.6503 8.77058 11.5879 8.52058C11.4733 8.04662 11.7076 7.63516 12.1553 7.52058C12.5769 7.41641 12.9934 7.68203 13.1079 8.14037C13.3057 8.91641 13.5035 9.68724 13.6857 10.4633C13.717 10.6039 13.7742 10.6352 13.9096 10.6352C14.3729 10.63 14.831 10.63 15.2943 10.6352C15.7419 10.6352 16.0803 10.9737 16.0803 11.406C16.0855 11.8487 15.7419 12.1925 15.2891 12.1925C14.8726 12.1977 14.4562 12.1977 14.0397 12.1925C13.9044 12.1872 13.8315 12.2289 13.7794 12.3591C13.5348 12.9477 13.2849 13.531 13.0298 14.1143C12.8841 14.4425 12.6029 14.5987 12.249 14.5779C11.947 14.5622 11.6816 14.3591 11.5774 14.0622C11.5046 13.8539 11.5254 13.656 11.6087 13.4581C11.7857 13.0466 11.9627 12.6352 12.1449 12.2081C10.7081 12.2081 9.28697 12.2081 7.84501 12.2081C7.94392 12.4425 8.04282 12.6716 8.13653 12.9008C8.21461 13.0883 8.2979 13.2706 8.37598 13.4581C8.55818 13.8956 8.3916 14.3435 7.98556 14.5258C7.57432 14.7029 7.13184 14.5206 6.93923 14.0831C6.68416 13.505 6.43949 12.9268 6.19483 12.3435C6.14798 12.2289 6.09072 12.1872 5.96578 12.1925C5.54413 12.2029 5.12247 12.1977 4.69561 12.1925C4.24272 12.1925 3.89915 11.8539 3.90436 11.406C3.90436 10.9737 4.24272 10.6352 4.6904 10.63C5.13809 10.6247 5.59098 10.6195 6.03866 10.6352C6.22086 10.6404 6.28332 10.5779 6.31976 10.4112C6.49676 9.65599 6.68936 8.90078 6.87677 8.14037C6.97567 7.74974 7.26719 7.50495 7.63679 7.50495C8.01159 7.49974 8.32393 7.72891 8.40722 8.09349C8.43845 8.2237 8.43325 8.36953 8.40201 8.49453C8.24584 9.16641 8.07406 9.83308 7.90748 10.4997C7.89707 10.5362 7.89707 10.5727 7.88666 10.6195Z" fill="white" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_3415_6645">
                                                        <rect width="20" height="20" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>

                                            Upgrade Plan
                                        </Button>
                                    </form>
                                </span>
                            <span className='fw-medium' style={{ cursor: "pointer", color: "#552DEA" }} onClick={() => props.exhaustedModalCloser()}
                            >No, thanks</span>
                        </div>
                    </Modal.Body>
                </Modal>
                : ""}
        </>

    )
}