import React, { useEffect, useState, useContext } from 'react'
import HeaderWithoutMenu from '../../components/HeaderWithoutMenu/HeaderWithoutMenu';
import { DeleteInstanceController, GetAllInstanceMethodController, GetAllStatusInstanceController, UpdateStatusAutomateController } from '../../provider/SenderMethodAutomateController';
import { ThemeContext } from '../../projectContext/ThemeContext';
import { Dropdown, DropdownButton, Container, InputGroup, Form, Row, Col, Button, Table, Stack, Tabs, Tab } from 'react-bootstrap';
import EditEmailCampaignInstanceModal from '../../components/EditEmailCampaignInstanceModal/EditEmailCampaignInstanceModal';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import MessageModal from '../../components/MessageModal/MessageModal';
import { Link } from 'react-router-dom';
import CreateEmailCampaignInstanceModal from '../../components/CreateEmailCampaignInstanceModal/CreateEmailCampaignInstanceModal';
import MyTemplateModal from '../../components/MyTemplateModal/MyTemplateModal';
import { GenerateEmailAutomateController } from '../../provider/EmailBuilderControlller';
import dottedLoaderSmall from './../../assets/images/dottedLoaderSmall.gif';
import UpgradeUserPlanModal from '../../components/UpgradeUserPlanModal/UpgradeUserPlanModal';
import { CopyEmailController } from '../../provider/EmailBuilderControlller';
import AnErrorOccured from '../../components/AnErrorOccured/AnErrorOccured';

export default function EmailCampaignAutomate() {
  const ThemeContextData = useContext(ThemeContext);

  const [createEmailCampaignInstanceModalShow, setCreateEmailCampaignInstanceModalShow] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [automateList, setAutomateList] = useState([]);
  const [editDataTable, setEditDataTable] = useState({});
  const [editEmailCampaignInstanceModalShow, setEditEmailCampaignInstanceModalShow] = useState(false)
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [loaderForTab, setLoaderForTab] = useState(false);
  const [intanceId, setIntanceId] = useState(0);
  const [onlyViewEditModal, setOnlyViewEditModal] = useState(false)
  const [instanceDeleteMessageModal, setInstanceDeleteMessageModal] = useState(false);
  const [myTemplateModalShow, setMyTemplateModalShow] = useState(false);
  const [blanceExhaustedModal, setBlanceExhaustedModal] = useState(false);
  const [dublicateButtonDisabled, setDublicateButtonDisabled] = useState(false);
  const [parentKey, setParentKKey] = useState('home');


  const [allScheduledArticles, setAllScheduledArticles] = useState([]);
  const [allGeneratedArticles, setAllGeneratedArticles] = useState([]);
  const [allContentArticles, setAllContentArticles] = useState([]);
  const [allDesignArticles, setAllDesignArticles] = useState([]);
  const [allFinalArticles, setAllFinalArticles] = useState([]);
  const [allPublishedArticles, setAllPublishedArticles] = useState([]);
  const [allCancelledArticles, setAllCancelledArticles] = useState([]);
  const [generateLoader, setGenerateLoader] = useState({
    loader: false,
    id: 0
  })

  const [automateListSortConfig, setAutomateListSortConfig] = useState({ key: null, direction: "asc" });
  const [allScheduledArticlesSortConfig, setAllScheduledArticlesSortConfig] = useState({ key: null, direction: "asc" });
  const [allGeneratedArticlesSortConfig, setAllGeneratedArticlesSortConfig] = useState({ key: null, direction: "asc" });
  const [allContentArticlesSortConfig, setAllContentArticlesSortConfig] = useState({ key: null, direction: "asc" });
  const [allDesignArticlesSortConfig, setAllDesignArticlesSortConfig] = useState({ key: null, direction: "asc" });
  const [allFinalArticlesSortConfig, setAllFinalArticlesSortConfig] = useState({ key: null, direction: "asc" });
  const [allPublishedArticlesSortConfig, setAllPublishedArticlesSortConfig] = useState({ key: null, direction: "asc" });
  const [allCancelledArticlesSortConfig, setAllCancelledArticlesSortConfig] = useState({ key: null, direction: "asc" });
  const [tryLater, setTryLater] = useState(false)

  const rowsPerPage = 20; // Limit rows per page
  const [currentPage, setCurrentPage] = useState(1);

  const schdulRowsPerPage = 20; // Limit rows per page
  const [schdulCurrentPage, setSchdulCurrentPage] = useState(1);

  const generatedRowsPerPage = 20; // Limit rows per page
  const [generatedCurrentPage, setGeneratedCurrentPage] = useState(1);

  const designRowsPerPage = 20; // Limit rows per page
  const [designCurrentPage, setDesignCurrentPage] = useState(1);

  const contentRowsPerPage = 20; // Limit rows per page
  const [contentCurrentPage, setContentCurrentPage] = useState(1);

  const finalRowsPerPage = 20; // Limit rows per page
  const [finalCurrentPage, setFinalCurrentPage] = useState(1);

  const publishedRowsPerPage = 20; // Limit rows per page
  const [publishedCurrentPage, setPublishedCurrentPage] = useState(1);

  const cancelledRowsPerPage = 20; // Limit rows per page
  const [cancelledCurrentPage, setCancelledCurrentPage] = useState(1);

  const totalPages = Math.ceil(automateList.length / rowsPerPage);
  const schdulTotalPages = Math.ceil(allScheduledArticles.length / schdulRowsPerPage);
  const generatedTotalPages = Math.ceil(allGeneratedArticles.length / generatedRowsPerPage);
  const designTotalPages = Math.ceil(allDesignArticles.length / designRowsPerPage);
  const contentTotalPages = Math.ceil(allContentArticles.length / contentRowsPerPage);
  const finalTotalPages = Math.ceil(allFinalArticles.length / finalRowsPerPage);
  const publishedTotalPages = Math.ceil(allPublishedArticles.length / publishedRowsPerPage);
  const cancelledTotalPages = Math.ceil(allCancelledArticles.length / cancelledRowsPerPage);
  // Get the current rows for the page
  const currentRows = automateList.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const schdulCurrentRows = allScheduledArticles.slice(
    (schdulCurrentPage - 1) * schdulRowsPerPage,
    schdulCurrentPage * schdulRowsPerPage
  );

  const generatedCurrentRows = allGeneratedArticles.slice(
    (generatedCurrentPage - 1) * generatedRowsPerPage,
    generatedCurrentPage * generatedRowsPerPage
  );

  const designCurrentRows = allDesignArticles.slice(
    (designCurrentPage - 1) * designRowsPerPage,
    designCurrentPage * designRowsPerPage
  );

  const contentCurrentRows = allContentArticles.slice(
    (contentCurrentPage - 1) * contentRowsPerPage,
    contentCurrentPage * contentRowsPerPage
  );

  const finalCurrentRows = allFinalArticles.slice(
    (finalCurrentPage - 1) * finalRowsPerPage,
    finalCurrentPage * finalRowsPerPage
  );

  const publishedCurrentRows = allPublishedArticles.slice(
    (publishedCurrentPage - 1) * publishedRowsPerPage,
    publishedCurrentPage * publishedRowsPerPage
  );

  const cancelledCurrentRows = allCancelledArticles.slice(
    (cancelledCurrentPage - 1) * cancelledRowsPerPage,
    cancelledCurrentPage * cancelledRowsPerPage
  );

  // Handle page change
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleSchdulPageChange = (page) => {
    if (page >= 1 && page <= schdulTotalPages) {
      setSchdulCurrentPage(page);
    }
  };

  const handleGeneratedPageChange = (page) => {
    if (page >= 1 && page <= generatedTotalPages) {
      setGeneratedCurrentPage(page);
    }
  };

  const handleDesignPageChange = (page) => {
    if (page >= 1 && page <= designTotalPages) {
      setDesignCurrentPage(page);
    }
  };

  const handleContentPageChange = (page) => {
    if (page >= 1 && page <= contentTotalPages) {
      setContentCurrentPage(page);
    }
  };

  const handleFinalPageChange = (page) => {
    if (page >= 1 && page <= finalTotalPages) {
      setFinalCurrentPage(page);
    }
  };

  const handlePublishedPageChange = (page) => {
    if (page >= 1 && page <= publishedTotalPages) {
      setPublishedCurrentPage(page);
    }
  };

  const handleCancelledPageChange = (page) => {
    if (page >= 1 && page <= cancelledTotalPages) {
      setCancelledCurrentPage(page);
    }
  };

  const desendingOrderFunction = (array) => {
    let desendingOrder = array.sort((a, b) => {
      const dateA = new Date(a.cron_time || a.created_at);
      const dateB = new Date(b.cron_time || b.created_at);

      return dateB - dateA;
    })

    return desendingOrder;
  }

  const getAllInstanceMethod = async () => {
    try {
      const result = await GetAllInstanceMethodController(ThemeContextData);
      const result2 = await GetAllStatusInstanceController(ThemeContextData);

      if (result.success && result2.success) {
        setAutomateList(desendingOrderFunction(result.data))



        setLoaderForTab(false);
        setPageLoader(true);
        setAllScheduledArticles(desendingOrderFunction(result2.data.scheduled));
        setAllGeneratedArticles(desendingOrderFunction(result2.data.generated));
        setAllDesignArticles(desendingOrderFunction(result2.data.design));
        setAllContentArticles(desendingOrderFunction(result2.data.content));
        setAllFinalArticles(desendingOrderFunction(result2.data.final));
        setAllPublishedArticles(desendingOrderFunction(result2.data.sent));
        setAllCancelledArticles(desendingOrderFunction(result2.data.cancelled));
      }
    } catch (error) {

    }
  }

  useEffect(async () => {
    try {
      const result = await GetAllInstanceMethodController(ThemeContextData);
      const result2 = await GetAllStatusInstanceController(ThemeContextData);

      if (result.success && result2.success) {
        setAutomateList(desendingOrderFunction(result.data))

        setPageLoader(true)
        setAllScheduledArticles(desendingOrderFunction(result2.data.scheduled));
        setAllGeneratedArticles(desendingOrderFunction(result2.data.generated));
        setAllDesignArticles(desendingOrderFunction(result2.data.design));
        setAllContentArticles(desendingOrderFunction(result2.data.content));
        setAllFinalArticles(desendingOrderFunction(result2.data.final));
        setAllPublishedArticles(desendingOrderFunction(result2.data.sent));
      }
    } catch (error) {

    }
  }, [])

  const instanceDeleteCloseModal = () => {
    setDeleteModalShow(false)
  }

  const exhaustedModalCloser = () => {
    setBlanceExhaustedModal(false);
  }

  const deleteHandler = async () => {
    try {
      const result = await DeleteInstanceController(ThemeContextData, intanceId);

      if (result.success) {
        setInstanceDeleteMessageModal(true);
        setDeleteModalShow(false);
        setTimeout(nextTab, 1500);
        getAllInstanceMethod(); // Refresh after deletion
      } else {
        // Handle error, show message, etc.
        console.error("Error:", result.error);
      }
    } catch (error) {
      // Handle any additional errors
      console.error("Error:", error);
    }
  }

  const nextTab = () => {
    setInstanceDeleteMessageModal(false);
  }

  function format_date(cronTime) {
    const date = new Date(cronTime);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const month = date.getMonth() + 1;
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesFormatted = minutes < 10 ? '0' + minutes : minutes;
    return date.getDate() + '/' + month + '/' + date.getFullYear() + ' ' + hours + ':' + minutesFormatted + ' ' + ampm;
  }

  const statusItemHandler = async (e, id) => {
    const status = e.currentTarget.textContent.trim(); // Get the clicked item's text
    setLoaderForTab(true);
    try {
      const result = await UpdateStatusAutomateController(ThemeContextData, id, status);

      if (result.success) {
        await getAllInstanceMethod(); // Refresh the list of scheduled instances
      } else {
        // Handle error
        console.error("Error:", result.error);
      }
    } catch (error) {
      // Handle any additional errors
      console.error("Error:", error);
    }
  }

  const emailGenerateFunc = async (e) => {


    const result = await GenerateEmailAutomateController(ThemeContextData, e, setBlanceExhaustedModal);
    if (result.success) {

      getAllInstanceMethod()
      // setGenerateLoader({
      //     loader: false,
      //     id: 0
      // })
    }
  }

  const copySendHolder = async (id) => {
    setPageLoader(true);
    const result = await CopyEmailController(ThemeContextData, setTryLater, id);
    if (result.success) {
      getAllInstanceMethod();
    }
    setDublicateButtonDisabled(false);
    setParentKKey("schedule");
    setPageLoader(false);
  }

  const handleSort = (key, sortConfig, setSortConfig, array, setArray) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc"; // Toggle direction
    }

    const sortedArray = [...array].sort((a, b) => {
      const aValue = (a[key] || "").trim().toString().toLowerCase();
      const bValue = (b[key] || "").trim().toString().toLowerCase();


      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;

    });

    setArray(sortedArray);
    setSortConfig({ key, direction });
  };

  const filterIconDisplayInTableHead = (sortConfig, keyValue) => {
    if (sortConfig.key === keyValue && sortConfig.direction === "asc") {
      return ` ↑`;
    }
    else if (sortConfig.key === keyValue && sortConfig.direction !== "asc") {
      return ` ↓`;
    } else {
      return <span className='icon icon-arrows-down-up ms-1'></span>
    }
  }

  return (
    <>
      <HeaderWithoutMenu articleGenerator={true} text={"AutomateMe"} icon={'icon-automate'} />
      <main className='automateme-page'>
        {!pageLoader ?
          <div style={{ display: 'flex', justifyContent: 'center', flexGrow: 1, alignItems: 'center' }}>
            <img src={dottedLoaderSmall} alt='dotted' className='img-fluid' style={{ width: '80px', height: '80px' }} />
          </div> : <>
            {automateList.length < 1 ? <Container fluid className='h-100 scroll'>
              <Row className='h-100'>
                <Col xs={12} className='d-flex flex-column'>
                  <div className="text-center d-flex justify-content-center flex-column align-items-center h-100">
                    <span className="icon card-square-icon icon-lg icon-automate mb-3 mx-auto"></span>
                    <h1>AutomateMe Emails</h1>
                    <p className='para'>With AutomateMe, you can create, schedule, and automate email campaigns faster than you can say 'Inbox Zero!' <br /> Pick a saved template, whip up a fresh email, or manage your lists—all with just a few clicks.</p>
                    <div className='create-highlight-title'>
                      <span className='icon icon-start-generate'></span>
                      <strong className='h5 fw-medium'>Let’s make your emails shine and your marketing life easier (and maybe even fun)!</strong>
                    </div>
                    <Button
                      onClick={() => setCreateEmailCampaignInstanceModalShow(true)}
                      variant='primary'
                      type='submit'
                      className="px-3 btn btn-primary"
                    ><span className="icon-lg icon-add-circle"></span>Create new Email Campaign</Button>
                    <div className='d-flex gap-2 pt-2'>
                      <Button variant='primary' onClick={() => window.location.href = "/recipt-list-automateme"}>Email List</Button>
                      <Button variant='primary' onClick={() => setMyTemplateModalShow(true)}>My Templates</Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container> :
              <div className='w-100 pt-4 position-relative'>
                <div className='d-flex flex-wrap flex-md-nowrap mb-3 gap-4'>
                  <div className='d-flex gap-3 flex-shrink-0'>
                    <Button variant='primary' onClick={() => window.location.href = "/recipt-list-automateme"}>Email List</Button>
                    <Button variant='primary' onClick={() => setMyTemplateModalShow(true)}>Templates</Button>
                  </div>
                  <div className='d-flex gap-3 search-createInstance-form mb-0'>
                    <InputGroup className=''>
                      <InputGroup.Text id="basic-addon1"><span className='icon-search'></span></InputGroup.Text>
                      <Form.Control
                        placeholder="Search Scheduled Articles"
                        aria-label="Search Scheduled Articles"
                        aria-describedby="basic-addon1"
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                    </InputGroup>
                    <Button
                      onClick={() => setCreateEmailCampaignInstanceModalShow(true)}
                      variant='primary' type='submit' className="px-3 flex-shrink-0 btn btn-primary"><span className="icon-lg icon-add-circle"></span>Create new Campaign</Button>
                  </div>
                </div>
                <Tabs
                  // defaultActiveKey="home"
                  activeKey={parentKey}
                  transition={false}
                  onSelect={(k) => setParentKKey(k)}
                  id="noanim-tab-example"
                  className='automate-tab'
                >
                  <Tab className='' eventKey="home" title={<>Show All</>}>
                    {loaderForTab ?
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={dottedLoaderSmall} alt='dotted' className='img-fluid' style={{ width: '64px', height: '64px' }} />
                      </div> :
                      <div className='automateme-table-holder' >
                        <div className='automateme-table scroll' style={{ maxHeight: '1000px' }}>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th className='date'
                                  onClick={() => handleSort("cron_time", automateListSortConfig, setAutomateListSortConfig, automateList, setAutomateList)}
                                >
                                  <span className='icon icon-calendar me-2'></span>
                                  Posting on
                                  {filterIconDisplayInTableHead(automateListSortConfig, "cron_time")}
                                </th>
                                <th
                                  onClick={() => handleSort("campaign_name", automateListSortConfig, setAutomateListSortConfig, automateList, setAutomateList)}
                                >
                                  Campaign
                                  {filterIconDisplayInTableHead(automateListSortConfig, "campaign_name")}
                                </th>
                                <th
                                  onClick={() => handleSort("sender_name", automateListSortConfig, setAutomateListSortConfig, automateList, setAutomateList)}
                                >
                                  Sender Name
                                  {filterIconDisplayInTableHead(automateListSortConfig, "sender_name")}
                                </th>
                                <th
                                  onClick={() => handleSort("subject", automateListSortConfig, setAutomateListSortConfig, automateList, setAutomateList)}
                                >
                                  Subject
                                  {filterIconDisplayInTableHead(automateListSortConfig, "subject")}
                                </th>
                                <th
                                  onClick={() => handleSort("intent", automateListSortConfig, setAutomateListSortConfig, automateList, setAutomateList)}
                                >
                                  Intent
                                  {filterIconDisplayInTableHead(automateListSortConfig, "intent")}
                                </th>
                                <th>
                                  <span className='icon icon-status me-2'></span>
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                currentRows.map((item, index) => {
                                  return (
                                    <tr key={index}
                                    // className={
                                    //     projects.filteredProject.length > 0 &&
                                    //         !projects.filteredProject.includes(article.project.title) ? "d-none" :
                                    //         searchValue.length > 0 && !article.title.toLowerCase().includes(searchValue.toLowerCase()) ? "d-none" : ""
                                    // }
                                    >
                                      <td>{format_date(item.cron_time)}</td>
                                      <td>{item.campaign_name}</td>
                                      <td>{item.sender_name}</td>
                                      <td>{item.subject}</td>
                                      <td>{item.intent}</td>
                                      <td>

                                        <div className='d-flex align-items-center justify-content-between'>
                                          <Dropdown className='automateDropDown'>
                                            <Dropdown.Toggle id="statusCategory0">
                                              {item.status === "Cancelled" ?
                                                <>
                                                  <svg style={{ width: 20, marginRight: '6px' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.99999 19.9998C7.32891 19.9998 4.81772 18.9597 2.92894 17.071C-3.34157 10.7868 1.12445 0.00874995 10.0001 0C18.876 0.00835933 23.3414 10.7892 17.071 17.0709C15.1823 18.9597 12.6711 19.9998 9.99999 19.9998ZM9.99999 1.56245C5.34756 1.56245 1.56254 5.34747 1.56254 9.9999C2.02601 21.1933 17.9756 21.1901 18.4374 9.99982C18.4374 5.34747 14.6524 1.56245 9.99999 1.56245ZM15.0837 15.0835C15.3887 14.7784 15.3887 14.2837 15.0837 13.9787L6.02122 4.91622C5.71611 4.61111 5.22142 4.61111 4.91635 4.91622C4.61127 5.22134 4.61127 5.71598 4.91635 6.02106L13.9788 15.0835C14.1313 15.2361 14.3313 15.3123 14.5313 15.3123C14.7312 15.3123 14.9311 15.2361 15.0837 15.0835Z" fill="#ED1C24" />
                                                  </svg>
                                                  {item.status}
                                                </> :
                                                item.status === "Scheduled" ?
                                                  <>
                                                    <span className='icon-clock'></span>
                                                    {item.status}
                                                  </> :
                                                  item.status === "Design Reviewed" ?
                                                    <>
                                                      <span className='icon-gogle'></span>
                                                      {item.status}
                                                    </> :
                                                    item.status === "Content Reviewed" ?
                                                      <>
                                                        <span className='icon-gogle'></span>
                                                        {item.status}
                                                      </> :
                                                      item.status === "Final Reviewed" ?
                                                        <>
                                                          <span className='icon-gogle'></span>
                                                          {item.status}
                                                        </> :
                                                        item.status === "Sent" ?
                                                          <>
                                                            <span className='icon-sent'></span>
                                                            {item.status}
                                                          </> :
                                                          item.status === "Generated" ?
                                                            <>
                                                              <span className='icon-magic'></span>
                                                              {item.status}
                                                            </> : ""
                                              }
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-sent'></span>
                                                Sent
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-magic'></span>
                                                Generated
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-gogle'></span>
                                                Design Reviewed
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-gogle'></span>
                                                Content Reviewed
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-gogle'></span>
                                                Final Reviewed
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-clock'></span>
                                                Scheduled
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <svg style={{ width: 15, marginRight: '6px' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M9.99999 19.9998C7.32891 19.9998 4.81772 18.9597 2.92894 17.071C-3.34157 10.7868 1.12445 0.00874995 10.0001 0C18.876 0.00835933 23.3414 10.7892 17.071 17.0709C15.1823 18.9597 12.6711 19.9998 9.99999 19.9998ZM9.99999 1.56245C5.34756 1.56245 1.56254 5.34747 1.56254 9.9999C2.02601 21.1933 17.9756 21.1901 18.4374 9.99982C18.4374 5.34747 14.6524 1.56245 9.99999 1.56245ZM15.0837 15.0835C15.3887 14.7784 15.3887 14.2837 15.0837 13.9787L6.02122 4.91622C5.71611 4.61111 5.22142 4.61111 4.91635 4.91622C4.61127 5.22134 4.61127 5.71598 4.91635 6.02106L13.9788 15.0835C14.1313 15.2361 14.3313 15.3123 14.5313 15.3123C14.7312 15.3123 14.9311 15.2361 15.0837 15.0835Z" fill="#ED1C24" />
                                                </svg>
                                                Cancelled
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                          {item.status === 'Sent' ?
                                            <div>
                                              Total: {item.total} <br /> Sent: {item.sent} <br /> Failed: {item.failed} <br /> Opened: {item.opened}
                                              <Button
                                                disabled={dublicateButtonDisabled ? true : false}
                                                onClick={() => { setDublicateButtonDisabled(true); copySendHolder(item.id) }}
                                                variant='primary'
                                                type='submit'
                                                className="mt-3 btn btn-primary"
                                              ><span className="icon-lg icon-copy"></span>Copy this Email</Button>
                                            </div> :
                                            <div className='icon-group d-flex gap-3'>
                                              {item.status === 'Scheduled' ?
                                                <>
                                                  {generateLoader.id === item.id && generateLoader.loader ?
                                                    <img src={dottedLoaderSmall} alt='dotted' className='img-fluid' style={{ width: '20px', height: '20px' }} /> :
                                                    <span className='icon-magic'
                                                      onClick={() => {
                                                        setGenerateLoader({
                                                          loader: true,
                                                          id: item.id
                                                        })
                                                        emailGenerateFunc(item.id);
                                                      }}
                                                    ></span>
                                                  }
                                                  <span className='icon-edit'
                                                    onClick={() => {
                                                      setEditDataTable(item);
                                                      setEditEmailCampaignInstanceModalShow(true);
                                                    }}
                                                  ></span>
                                                </> :
                                                <>
                                                  <Link target="_blank" className='icon-article' style={{ color: '#552DEA' }} to={`/email-campaign?campaign_id=${item.id}&email-campaign=1`}></Link>
                                                  <span className='icon-eye'
                                                    onClick={() => {
                                                      setEditDataTable(item);
                                                      setEditEmailCampaignInstanceModalShow(true);
                                                      setOnlyViewEditModal(true);
                                                    }}
                                                  ></span>
                                                </>
                                              }
                                              <span className='icon-delete'
                                                onClick={() => {
                                                  setIntanceId(item.id)
                                                  setDeleteModalShow(true);
                                                }}
                                              ></span>
                                            </div>
                                          }
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                })
                              }

                            </tbody>
                          </Table>
                          <div className="pagination-controls d-flex justify-content-center mt-3">
                            <button
                              onClick={() => handlePageChange(currentPage - 1)}
                              disabled={currentPage === 1}
                              className="btn btn-primary me-2"
                            >
                              Previous
                            </button>
                            <span>
                              Page {currentPage} of {totalPages}
                            </span>
                            <button
                              onClick={() => handlePageChange(currentPage + 1)}
                              disabled={currentPage === totalPages}
                              className="btn btn-primary ms-2"
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    }
                  </Tab>
                  <Tab className='' eventKey="schedule" title={<><span className='icon-clock mr-2'></span>Scheduled</>}>
                    {loaderForTab ?
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={dottedLoaderSmall} alt='dotted' className='img-fluid' style={{ width: '64px', height: '64px' }} />
                      </div> :
                      <div className='automateme-table-holder' >
                        <div className='automateme-table scroll' style={{ maxHeight: '1000px' }}>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th className='date'
                                  onClick={() => handleSort("cron_time", allScheduledArticlesSortConfig, setAllScheduledArticlesSortConfig, allScheduledArticles, setAllScheduledArticles)}
                                >
                                  <span className='icon icon-calendar me-2'></span>
                                  Posting on
                                  {filterIconDisplayInTableHead(allScheduledArticlesSortConfig, "cron_time")}
                                </th>
                                <th
                                  onClick={() => handleSort("campaign_name", allScheduledArticlesSortConfig, setAllScheduledArticlesSortConfig, allScheduledArticles, setAllScheduledArticles)}
                                >
                                  Campaign
                                  {filterIconDisplayInTableHead(allScheduledArticlesSortConfig, "campaign_name")}
                                </th>
                                <th
                                  onClick={() => handleSort("sender_name", allScheduledArticlesSortConfig, setAllScheduledArticlesSortConfig, allScheduledArticles, setAllScheduledArticles)}
                                >
                                  Sender Name
                                  {filterIconDisplayInTableHead(allScheduledArticlesSortConfig, "sender_name")}
                                </th>
                                <th
                                  onClick={() => handleSort("subject", allScheduledArticlesSortConfig, setAllScheduledArticlesSortConfig, allScheduledArticles, setAllScheduledArticles)}
                                >
                                  Subject
                                  {filterIconDisplayInTableHead(allScheduledArticlesSortConfig, "subject")}
                                </th>
                                <th
                                  onClick={() => handleSort("intent", allScheduledArticlesSortConfig, setAllScheduledArticlesSortConfig, allScheduledArticles, setAllScheduledArticles)}
                                >
                                  Intent
                                  {filterIconDisplayInTableHead(allScheduledArticlesSortConfig, "intent")}
                                </th>
                                <th>
                                  <span className='icon icon-status me-2'></span>
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                schdulCurrentRows.map((item, index) => {
                                  return (
                                    <tr key={index}
                                    // className={
                                    //     projects.filteredProject.length > 0 &&
                                    //         !projects.filteredProject.includes(article.project.title) ? "d-none" :
                                    //         searchValue.length > 0 && !article.title.toLowerCase().includes(searchValue.toLowerCase()) ? "d-none" : ""
                                    // }
                                    >
                                      <td>{format_date(item.cron_time)}</td>
                                      <td>{item.campaign_name}</td>
                                      <td>{item.sender_name}</td>
                                      <td>{item.subject}</td>
                                      <td>{item.intent}</td>
                                      <td>

                                        <div className='d-flex align-items-center justify-content-between'>
                                          <Dropdown className='automateDropDown'>
                                            <Dropdown.Toggle id="statusCategory0">
                                              {item.status === "Cancelled" ?
                                                <>
                                                  <svg style={{ width: 20, marginRight: '6px' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.99999 19.9998C7.32891 19.9998 4.81772 18.9597 2.92894 17.071C-3.34157 10.7868 1.12445 0.00874995 10.0001 0C18.876 0.00835933 23.3414 10.7892 17.071 17.0709C15.1823 18.9597 12.6711 19.9998 9.99999 19.9998ZM9.99999 1.56245C5.34756 1.56245 1.56254 5.34747 1.56254 9.9999C2.02601 21.1933 17.9756 21.1901 18.4374 9.99982C18.4374 5.34747 14.6524 1.56245 9.99999 1.56245ZM15.0837 15.0835C15.3887 14.7784 15.3887 14.2837 15.0837 13.9787L6.02122 4.91622C5.71611 4.61111 5.22142 4.61111 4.91635 4.91622C4.61127 5.22134 4.61127 5.71598 4.91635 6.02106L13.9788 15.0835C14.1313 15.2361 14.3313 15.3123 14.5313 15.3123C14.7312 15.3123 14.9311 15.2361 15.0837 15.0835Z" fill="#ED1C24" />
                                                  </svg>
                                                  {item.status}
                                                </> :
                                                item.status === "Scheduled" ?
                                                  <>
                                                    <span className='icon-clock'></span>
                                                    {item.status}
                                                  </> :
                                                  item.status === "Design Reviewed" ?
                                                    <>
                                                      <span className='icon-gogle'></span>
                                                      {item.status}
                                                    </> :
                                                    item.status === "Content Reviewed" ?
                                                      <>
                                                        <span className='icon-gogle'></span>
                                                        {item.status}
                                                      </> :
                                                      item.status === "Final Reviewed" ?
                                                        <>
                                                          <span className='icon-gogle'></span>
                                                          {item.status}
                                                        </> :
                                                        item.status === "Sent" ?
                                                          <>
                                                            <span className='icon-sent'></span>
                                                            {item.status}
                                                          </> :
                                                          item.status === "Generated" ?
                                                            <>
                                                              <span className='icon-magic'></span>
                                                              {item.status}
                                                            </> : ""
                                              }
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-sent'></span>
                                                Sent
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-magic'></span>
                                                Generated
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-gogle'></span>
                                                Design Reviewed
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-gogle'></span>
                                                Content Reviewed
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-gogle'></span>
                                                Final Reviewed
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-clock'></span>
                                                Scheduled
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <svg style={{ width: 15, marginRight: '6px' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M9.99999 19.9998C7.32891 19.9998 4.81772 18.9597 2.92894 17.071C-3.34157 10.7868 1.12445 0.00874995 10.0001 0C18.876 0.00835933 23.3414 10.7892 17.071 17.0709C15.1823 18.9597 12.6711 19.9998 9.99999 19.9998ZM9.99999 1.56245C5.34756 1.56245 1.56254 5.34747 1.56254 9.9999C2.02601 21.1933 17.9756 21.1901 18.4374 9.99982C18.4374 5.34747 14.6524 1.56245 9.99999 1.56245ZM15.0837 15.0835C15.3887 14.7784 15.3887 14.2837 15.0837 13.9787L6.02122 4.91622C5.71611 4.61111 5.22142 4.61111 4.91635 4.91622C4.61127 5.22134 4.61127 5.71598 4.91635 6.02106L13.9788 15.0835C14.1313 15.2361 14.3313 15.3123 14.5313 15.3123C14.7312 15.3123 14.9311 15.2361 15.0837 15.0835Z" fill="#ED1C24" />
                                                </svg>
                                                Cancelled
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                          <div className='icon-group d-flex gap-3'>
                                            {item.status === 'Scheduled' ?
                                              <>
                                                {generateLoader.id === item.id && generateLoader.loader ?
                                                  <img src={dottedLoaderSmall} alt='dotted' className='img-fluid' style={{ width: '20px', height: '20px' }} /> :
                                                  <span className='icon-magic'
                                                    onClick={() => {
                                                      setGenerateLoader({
                                                        loader: true,
                                                        id: item.id
                                                      })
                                                      emailGenerateFunc(item.id);
                                                    }}
                                                  ></span>
                                                }
                                                {/* <span className='icon-edit' onClick={() => {
                                                                                        setEditInstanceModal(true)
                                                                                        console.log(article);
                                                                                        setSelectedArticle(article)
                                                                                    }}></span> */}
                                                <span className='icon-edit'
                                                  onClick={() => {
                                                    setEditDataTable(item);
                                                    setEditEmailCampaignInstanceModalShow(true);
                                                    //     console.log(article);
                                                    //     setSelectedArticle(article)
                                                  }}
                                                ></span>
                                              </> :
                                              <>
                                                <Link target="_blank" className='icon-article' style={{ color: '#552DEA' }} to={`/email-campaign?campaign_id=${item.id}&email-campaign=1`}></Link>
                                                <span className='icon-eye'
                                                  onClick={() => {
                                                    setEditDataTable(item);
                                                    setEditEmailCampaignInstanceModalShow(true);
                                                    setOnlyViewEditModal(true);
                                                    //     setEditInstanceModal(true)
                                                    //     setSelectedArticle(article)
                                                  }}
                                                ></span>
                                              </>
                                            }
                                            <span className='icon-delete'
                                              onClick={() => {
                                                setIntanceId(item.id)
                                                setDeleteModalShow(true);
                                              }}
                                            ></span>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                })
                              }

                            </tbody>
                          </Table>
                          <div className="pagination-controls d-flex justify-content-center mt-3">
                            <button
                              onClick={() => handleSchdulPageChange(schdulCurrentPage - 1)}
                              disabled={schdulCurrentPage === 1}
                              className="btn btn-primary me-2"
                            >
                              Previous
                            </button>
                            <span>
                              Page {schdulCurrentPage} of {schdulTotalPages}
                            </span>
                            <button
                              onClick={() => handleSchdulPageChange(schdulCurrentPage + 1)}
                              disabled={schdulCurrentPage === schdulTotalPages}
                              className="btn btn-primary ms-2"
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    }
                  </Tab>
                  <Tab className='' eventKey="generated" title={<><span className='icon-magic mr-2'></span>Generated</>}>
                    {loaderForTab ?
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={dottedLoaderSmall} alt='dotted' className='img-fluid' style={{ width: '64px', height: '64px' }} />
                      </div> :
                      <div className='automateme-table-holder' >
                        <div className='automateme-table scroll' style={{ maxHeight: '1000px' }}>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th className='date'
                                  onClick={() => handleSort("cron_time", allGeneratedArticlesSortConfig, setAllGeneratedArticlesSortConfig, allGeneratedArticles, setAllGeneratedArticles)}
                                >
                                  <span className='icon icon-calendar me-2'></span>
                                  Posting on
                                  {filterIconDisplayInTableHead(allGeneratedArticlesSortConfig, "cron_time")}
                                </th>
                                <th
                                  onClick={() => handleSort("campaign_name", allGeneratedArticlesSortConfig, setAllGeneratedArticlesSortConfig, allGeneratedArticles, setAllGeneratedArticles)}
                                >
                                  Campaign
                                  {filterIconDisplayInTableHead(allGeneratedArticlesSortConfig, "campaign_name")}
                                </th>
                                <th
                                  onClick={() => handleSort("sender_name", allGeneratedArticlesSortConfig, setAllGeneratedArticlesSortConfig, allGeneratedArticles, setAllGeneratedArticles)}
                                >
                                  Sender Name
                                  {filterIconDisplayInTableHead(allGeneratedArticlesSortConfig, "sender_name")}
                                </th>
                                <th
                                  onClick={() => handleSort("subject", allGeneratedArticlesSortConfig, setAllGeneratedArticlesSortConfig, allGeneratedArticles, setAllGeneratedArticles)}
                                >
                                  Subject
                                  {filterIconDisplayInTableHead(allGeneratedArticlesSortConfig, "subject")}
                                </th>
                                <th
                                  onClick={() => handleSort("intent", allGeneratedArticlesSortConfig, setAllGeneratedArticlesSortConfig, allGeneratedArticles, setAllGeneratedArticles)}
                                >
                                  Intent
                                  {filterIconDisplayInTableHead(allGeneratedArticlesSortConfig, "intent")}
                                </th>
                                <th>
                                  <span className='icon icon-status me-2'></span>
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                generatedCurrentRows.map((item, index) => {
                                  return (
                                    <tr key={index}
                                    // className={
                                    //     projects.filteredProject.length > 0 &&
                                    //         !projects.filteredProject.includes(article.project.title) ? "d-none" :
                                    //         searchValue.length > 0 && !article.title.toLowerCase().includes(searchValue.toLowerCase()) ? "d-none" : ""
                                    // }
                                    >
                                      <td>{format_date(item.cron_time)}</td>
                                      <td>{item.campaign_name}</td>
                                      <td>{item.sender_name}</td>
                                      <td>{item.subject}</td>
                                      <td>{item.intent}</td>
                                      <td>

                                        <div className='d-flex align-items-center justify-content-between'>
                                          <Dropdown className='automateDropDown'>
                                            <Dropdown.Toggle id="statusCategory0">
                                              {item.status === "Cancelled" ?
                                                <>
                                                  <svg style={{ width: 20, marginRight: '6px' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.99999 19.9998C7.32891 19.9998 4.81772 18.9597 2.92894 17.071C-3.34157 10.7868 1.12445 0.00874995 10.0001 0C18.876 0.00835933 23.3414 10.7892 17.071 17.0709C15.1823 18.9597 12.6711 19.9998 9.99999 19.9998ZM9.99999 1.56245C5.34756 1.56245 1.56254 5.34747 1.56254 9.9999C2.02601 21.1933 17.9756 21.1901 18.4374 9.99982C18.4374 5.34747 14.6524 1.56245 9.99999 1.56245ZM15.0837 15.0835C15.3887 14.7784 15.3887 14.2837 15.0837 13.9787L6.02122 4.91622C5.71611 4.61111 5.22142 4.61111 4.91635 4.91622C4.61127 5.22134 4.61127 5.71598 4.91635 6.02106L13.9788 15.0835C14.1313 15.2361 14.3313 15.3123 14.5313 15.3123C14.7312 15.3123 14.9311 15.2361 15.0837 15.0835Z" fill="#ED1C24" />
                                                  </svg>
                                                  {item.status}
                                                </> :
                                                item.status === "Scheduled" ?
                                                  <>
                                                    <span className='icon-clock'></span>
                                                    {item.status}
                                                  </> :
                                                  item.status === "Design Reviewed" ?
                                                    <>
                                                      <span className='icon-gogle'></span>
                                                      {item.status}
                                                    </> :
                                                    item.status === "Content Reviewed" ?
                                                      <>
                                                        <span className='icon-gogle'></span>
                                                        {item.status}
                                                      </> :
                                                      item.status === "Final Reviewed" ?
                                                        <>
                                                          <span className='icon-gogle'></span>
                                                          {item.status}
                                                        </> :
                                                        item.status === "Sent" ?
                                                          <>
                                                            <span className='icon-sent'></span>
                                                            {item.status}
                                                          </> :
                                                          item.status === "Generated" ?
                                                            <>
                                                              <span className='icon-magic'></span>
                                                              {item.status}
                                                            </> : ""
                                              }
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-sent'></span>
                                                Sent
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-magic'></span>
                                                Generated
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-gogle'></span>
                                                Design Reviewed
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-gogle'></span>
                                                Content Reviewed
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-gogle'></span>
                                                Final Reviewed
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-clock'></span>
                                                Scheduled
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <svg style={{ width: 15, marginRight: '6px' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M9.99999 19.9998C7.32891 19.9998 4.81772 18.9597 2.92894 17.071C-3.34157 10.7868 1.12445 0.00874995 10.0001 0C18.876 0.00835933 23.3414 10.7892 17.071 17.0709C15.1823 18.9597 12.6711 19.9998 9.99999 19.9998ZM9.99999 1.56245C5.34756 1.56245 1.56254 5.34747 1.56254 9.9999C2.02601 21.1933 17.9756 21.1901 18.4374 9.99982C18.4374 5.34747 14.6524 1.56245 9.99999 1.56245ZM15.0837 15.0835C15.3887 14.7784 15.3887 14.2837 15.0837 13.9787L6.02122 4.91622C5.71611 4.61111 5.22142 4.61111 4.91635 4.91622C4.61127 5.22134 4.61127 5.71598 4.91635 6.02106L13.9788 15.0835C14.1313 15.2361 14.3313 15.3123 14.5313 15.3123C14.7312 15.3123 14.9311 15.2361 15.0837 15.0835Z" fill="#ED1C24" />
                                                </svg>
                                                Cancelled
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                          <div className='icon-group d-flex gap-3'>
                                            <Link target="_blank" className='icon-article' style={{ color: '#552DEA' }} to={`/email-campaign?campaign_id=${item.id}&email-campaign=1`}></Link>

                                            <span className='icon-edit'
                                              onClick={() => {
                                                setEditDataTable(item);
                                                setEditEmailCampaignInstanceModalShow(true)

                                              }}
                                            ></span>


                                            <span className='icon-delete'
                                              onClick={() => {
                                                setIntanceId(item.id)
                                                setDeleteModalShow(true);
                                              }}
                                            ></span>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                })
                              }

                            </tbody>
                          </Table>
                          <div className="pagination-controls d-flex justify-content-center mt-3">
                            <button
                              onClick={() => handleGeneratedPageChange(generatedCurrentPage - 1)}
                              disabled={generatedCurrentPage === 1}
                              className="btn btn-primary me-2"
                            >
                              Previous
                            </button>
                            <span>
                              Page {generatedCurrentPage} of {generatedTotalPages}
                            </span>
                            <button
                              onClick={() => handleGeneratedPageChange(generatedCurrentPage + 1)}
                              disabled={generatedCurrentPage === generatedTotalPages}
                              className="btn btn-primary ms-2"
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    }
                  </Tab>
                  <Tab className='' eventKey="content_reviewed" title={<><span className='icon-gogle mr-2'></span>Content Reviewed</>}>
                    {loaderForTab ?
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={dottedLoaderSmall} alt='dotted' className='img-fluid' style={{ width: '64px', height: '64px' }} />
                      </div> :
                      <div className='automateme-table-holder' >
                        <div className='automateme-table scroll' style={{ maxHeight: '1000px' }}>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th className='date'
                                  onClick={() => handleSort("cron_time", allContentArticlesSortConfig, setAllContentArticlesSortConfig, allContentArticles, setAllContentArticles)}
                                >
                                  <span className='icon icon-calendar me-2'></span>
                                  Posting on
                                  {filterIconDisplayInTableHead(allContentArticlesSortConfig, "cron_time")}
                                </th>
                                <th
                                  onClick={() => handleSort("campaign_name", allContentArticlesSortConfig, setAllContentArticlesSortConfig, allContentArticles, setAllContentArticles)}
                                >
                                  Campaign
                                  {filterIconDisplayInTableHead(allContentArticlesSortConfig, "campaign_name")}
                                </th>
                                <th
                                  onClick={() => handleSort("sender_name", allContentArticlesSortConfig, setAllContentArticlesSortConfig, allContentArticles, setAllContentArticles)}
                                >
                                  Sender Name
                                  {filterIconDisplayInTableHead(allContentArticlesSortConfig, "sender_name")}
                                </th>
                                <th
                                  onClick={() => handleSort("subject", allContentArticlesSortConfig, setAllContentArticlesSortConfig, allContentArticles, setAllContentArticles)}
                                >
                                  Subject
                                  {filterIconDisplayInTableHead(allContentArticlesSortConfig, "subject")}
                                </th>
                                <th
                                  onClick={() => handleSort("intent", allContentArticlesSortConfig, setAllContentArticlesSortConfig, allContentArticles, setAllContentArticles)}
                                >
                                  Intent
                                  {filterIconDisplayInTableHead(allContentArticlesSortConfig, "intent")}
                                </th>
                                <th>
                                  <span className='icon icon-status me-2'></span>
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                contentCurrentRows.map((item, index) => {
                                  return (
                                    <tr key={index}
                                    // className={
                                    //     projects.filteredProject.length > 0 &&
                                    //         !projects.filteredProject.includes(article.project.title) ? "d-none" :
                                    //         searchValue.length > 0 && !article.title.toLowerCase().includes(searchValue.toLowerCase()) ? "d-none" : ""
                                    // }
                                    >
                                      <td>{format_date(item.cron_time)}</td>
                                      <td>{item.campaign_name}</td>
                                      <td>{item.sender_name}</td>
                                      <td>{item.subject}</td>
                                      <td>{item.intent}</td>
                                      <td>

                                        <div className='d-flex align-items-center justify-content-between'>
                                          <Dropdown className='automateDropDown'>
                                            <Dropdown.Toggle id="statusCategory0">
                                              {item.status === "Cancelled" ?
                                                <>
                                                  <svg style={{ width: 20, marginRight: '6px' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.99999 19.9998C7.32891 19.9998 4.81772 18.9597 2.92894 17.071C-3.34157 10.7868 1.12445 0.00874995 10.0001 0C18.876 0.00835933 23.3414 10.7892 17.071 17.0709C15.1823 18.9597 12.6711 19.9998 9.99999 19.9998ZM9.99999 1.56245C5.34756 1.56245 1.56254 5.34747 1.56254 9.9999C2.02601 21.1933 17.9756 21.1901 18.4374 9.99982C18.4374 5.34747 14.6524 1.56245 9.99999 1.56245ZM15.0837 15.0835C15.3887 14.7784 15.3887 14.2837 15.0837 13.9787L6.02122 4.91622C5.71611 4.61111 5.22142 4.61111 4.91635 4.91622C4.61127 5.22134 4.61127 5.71598 4.91635 6.02106L13.9788 15.0835C14.1313 15.2361 14.3313 15.3123 14.5313 15.3123C14.7312 15.3123 14.9311 15.2361 15.0837 15.0835Z" fill="#ED1C24" />
                                                  </svg>
                                                  {item.status}
                                                </> :
                                                item.status === "Scheduled" ?
                                                  <>
                                                    <span className='icon-clock'></span>
                                                    {item.status}
                                                  </> :
                                                  item.status === "Design Reviewed" ?
                                                    <>
                                                      <span className='icon-gogle'></span>
                                                      {item.status}
                                                    </> :
                                                    item.status === "Content Reviewed" ?
                                                      <>
                                                        <span className='icon-gogle'></span>
                                                        {item.status}
                                                      </> :
                                                      item.status === "Final Reviewed" ?
                                                        <>
                                                          <span className='icon-gogle'></span>
                                                          {item.status}
                                                        </> :
                                                        item.status === "Sent" ?
                                                          <>
                                                            <span className='icon-sent'></span>
                                                            {item.status}
                                                          </> :
                                                          item.status === "Generated" ?
                                                            <>
                                                              <span className='icon-magic'></span>
                                                              {item.status}
                                                            </> : ""
                                              }
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-sent'></span>
                                                Sent
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-magic'></span>
                                                Generated
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-gogle'></span>
                                                Design Reviewed
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-gogle'></span>
                                                Content Reviewed
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-gogle'></span>
                                                Final Reviewed
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-clock'></span>
                                                Scheduled
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <svg style={{ width: 15, marginRight: '6px' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M9.99999 19.9998C7.32891 19.9998 4.81772 18.9597 2.92894 17.071C-3.34157 10.7868 1.12445 0.00874995 10.0001 0C18.876 0.00835933 23.3414 10.7892 17.071 17.0709C15.1823 18.9597 12.6711 19.9998 9.99999 19.9998ZM9.99999 1.56245C5.34756 1.56245 1.56254 5.34747 1.56254 9.9999C2.02601 21.1933 17.9756 21.1901 18.4374 9.99982C18.4374 5.34747 14.6524 1.56245 9.99999 1.56245ZM15.0837 15.0835C15.3887 14.7784 15.3887 14.2837 15.0837 13.9787L6.02122 4.91622C5.71611 4.61111 5.22142 4.61111 4.91635 4.91622C4.61127 5.22134 4.61127 5.71598 4.91635 6.02106L13.9788 15.0835C14.1313 15.2361 14.3313 15.3123 14.5313 15.3123C14.7312 15.3123 14.9311 15.2361 15.0837 15.0835Z" fill="#ED1C24" />
                                                </svg>
                                                Cancelled
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                          <div className='icon-group d-flex gap-3'>
                                            <Link target="_blank" className='icon-article' style={{ color: '#552DEA' }} to={`/email-campaign?campaign_id=${item.id}&email-campaign=1`}></Link>

                                            <span className='icon-edit'
                                              onClick={() => {
                                                setEditDataTable(item);
                                                setEditEmailCampaignInstanceModalShow(true)

                                              }}
                                            ></span>


                                            <span className='icon-delete'
                                              onClick={() => {
                                                setIntanceId(item.id)
                                                setDeleteModalShow(true);
                                              }}
                                            ></span>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                })
                              }

                            </tbody>
                          </Table>
                          <div className="pagination-controls d-flex justify-content-center mt-3">
                            <button
                              onClick={() => handleContentPageChange(contentCurrentPage - 1)}
                              disabled={contentCurrentPage === 1}
                              className="btn btn-primary me-2"
                            >
                              Previous
                            </button>
                            <span>
                              Page {contentCurrentPage} of {contentTotalPages}
                            </span>
                            <button
                              onClick={() => handleContentPageChange(contentCurrentPage + 1)}
                              disabled={contentCurrentPage === contentTotalPages}
                              className="btn btn-primary ms-2"
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    }
                  </Tab>
                  <Tab className='' eventKey="design_reviewed" title={<><span className='icon-gogle mr-2'></span>Design Reviewed</>}>
                    {loaderForTab ?
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={dottedLoaderSmall} alt='dotted' className='img-fluid' style={{ width: '64px', height: '64px' }} />
                      </div> :
                      <div className='automateme-table-holder' >
                        <div className='automateme-table scroll' style={{ maxHeight: '1000px' }}>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th className='date'
                                  onClick={() => handleSort("cron_time", allDesignArticlesSortConfig, setAllDesignArticlesSortConfig, allDesignArticles, setAllDesignArticles)}
                                >
                                  <span className='icon icon-calendar me-2'></span>
                                  Posting on
                                  {filterIconDisplayInTableHead(allDesignArticlesSortConfig, "cron_time")}
                                </th>
                                <th
                                  onClick={() => handleSort("campaign_name", allDesignArticlesSortConfig, setAllDesignArticlesSortConfig, allDesignArticles, setAllDesignArticles)}
                                >
                                  Campaign
                                  {filterIconDisplayInTableHead(allDesignArticlesSortConfig, "campaign_name")}
                                </th>
                                <th
                                  onClick={() => handleSort("sender_name", allDesignArticlesSortConfig, setAllDesignArticlesSortConfig, allDesignArticles, setAllDesignArticles)}
                                >
                                  Sender Name
                                  {filterIconDisplayInTableHead(allDesignArticlesSortConfig, "sender_name")}
                                </th>
                                <th
                                  onClick={() => handleSort("subject", allDesignArticlesSortConfig, setAllDesignArticlesSortConfig, allDesignArticles, setAllDesignArticles)}
                                >
                                  Subject
                                  {filterIconDisplayInTableHead(allDesignArticlesSortConfig, "subject")}
                                </th>
                                <th
                                  onClick={() => handleSort("intent", allDesignArticlesSortConfig, setAllDesignArticlesSortConfig, allDesignArticles, setAllDesignArticles)}
                                >
                                  Intent
                                  {filterIconDisplayInTableHead(allDesignArticlesSortConfig, "intent")}
                                </th>
                                <th>
                                  <span className='icon icon-status me-2'></span>
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                designCurrentRows.map((item, index) => {
                                  return (
                                    <tr key={index}
                                    // className={
                                    //     projects.filteredProject.length > 0 &&
                                    //         !projects.filteredProject.includes(article.project.title) ? "d-none" :
                                    //         searchValue.length > 0 && !article.title.toLowerCase().includes(searchValue.toLowerCase()) ? "d-none" : ""
                                    // }
                                    >
                                      <td>{format_date(item.cron_time)}</td>
                                      <td>{item.campaign_name}</td>
                                      <td>{item.sender_name}</td>
                                      <td>{item.subject}</td>
                                      <td>{item.intent}</td>
                                      <td>

                                        <div className='d-flex align-items-center justify-content-between'>
                                          <Dropdown className='automateDropDown'>
                                            <Dropdown.Toggle id="statusCategory0">
                                              {item.status === "Cancelled" ?
                                                <>
                                                  <svg style={{ width: 20, marginRight: '6px' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.99999 19.9998C7.32891 19.9998 4.81772 18.9597 2.92894 17.071C-3.34157 10.7868 1.12445 0.00874995 10.0001 0C18.876 0.00835933 23.3414 10.7892 17.071 17.0709C15.1823 18.9597 12.6711 19.9998 9.99999 19.9998ZM9.99999 1.56245C5.34756 1.56245 1.56254 5.34747 1.56254 9.9999C2.02601 21.1933 17.9756 21.1901 18.4374 9.99982C18.4374 5.34747 14.6524 1.56245 9.99999 1.56245ZM15.0837 15.0835C15.3887 14.7784 15.3887 14.2837 15.0837 13.9787L6.02122 4.91622C5.71611 4.61111 5.22142 4.61111 4.91635 4.91622C4.61127 5.22134 4.61127 5.71598 4.91635 6.02106L13.9788 15.0835C14.1313 15.2361 14.3313 15.3123 14.5313 15.3123C14.7312 15.3123 14.9311 15.2361 15.0837 15.0835Z" fill="#ED1C24" />
                                                  </svg>
                                                  {item.status}
                                                </> :
                                                item.status === "Scheduled" ?
                                                  <>
                                                    <span className='icon-clock'></span>
                                                    {item.status}
                                                  </> :
                                                  item.status === "Design Reviewed" ?
                                                    <>
                                                      <span className='icon-gogle'></span>
                                                      {item.status}
                                                    </> :
                                                    item.status === "Content Reviewed" ?
                                                      <>
                                                        <span className='icon-gogle'></span>
                                                        {item.status}
                                                      </> :
                                                      item.status === "Final Reviewed" ?
                                                        <>
                                                          <span className='icon-gogle'></span>
                                                          {item.status}
                                                        </> :
                                                        item.status === "Sent" ?
                                                          <>
                                                            <span className='icon-sent'></span>
                                                            {item.status}
                                                          </> :
                                                          item.status === "Generated" ?
                                                            <>
                                                              <span className='icon-magic'></span>
                                                              {item.status}
                                                            </> : ""
                                              }
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-sent'></span>
                                                Sent
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-magic'></span>
                                                Generated
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-gogle'></span>
                                                Design Reviewed
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-gogle'></span>
                                                Content Reviewed
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-gogle'></span>
                                                Final Reviewed
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-clock'></span>
                                                Scheduled
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <svg style={{ width: 15, marginRight: '6px' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M9.99999 19.9998C7.32891 19.9998 4.81772 18.9597 2.92894 17.071C-3.34157 10.7868 1.12445 0.00874995 10.0001 0C18.876 0.00835933 23.3414 10.7892 17.071 17.0709C15.1823 18.9597 12.6711 19.9998 9.99999 19.9998ZM9.99999 1.56245C5.34756 1.56245 1.56254 5.34747 1.56254 9.9999C2.02601 21.1933 17.9756 21.1901 18.4374 9.99982C18.4374 5.34747 14.6524 1.56245 9.99999 1.56245ZM15.0837 15.0835C15.3887 14.7784 15.3887 14.2837 15.0837 13.9787L6.02122 4.91622C5.71611 4.61111 5.22142 4.61111 4.91635 4.91622C4.61127 5.22134 4.61127 5.71598 4.91635 6.02106L13.9788 15.0835C14.1313 15.2361 14.3313 15.3123 14.5313 15.3123C14.7312 15.3123 14.9311 15.2361 15.0837 15.0835Z" fill="#ED1C24" />
                                                </svg>
                                                Cancelled
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                          <div className='icon-group d-flex gap-3'>
                                            <Link target="_blank" className='icon-article' style={{ color: '#552DEA' }} to={`/email-campaign?campaign_id=${item.id}&email-campaign=1`}></Link>

                                            <span className='icon-edit'
                                              onClick={() => {
                                                setEditDataTable(item);
                                                setEditEmailCampaignInstanceModalShow(true)

                                              }}
                                            ></span>


                                            <span className='icon-delete'
                                              onClick={() => {
                                                setIntanceId(item.id)
                                                setDeleteModalShow(true);
                                              }}
                                            ></span>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                })
                              }

                            </tbody>
                          </Table>
                          <div className="pagination-controls d-flex justify-content-center mt-3">
                            <button
                              onClick={() => handleDesignPageChange(designCurrentPage - 1)}
                              disabled={designCurrentPage === 1}
                              className="btn btn-primary me-2"
                            >
                              Previous
                            </button>
                            <span>
                              Page {designCurrentPage} of {designTotalPages}
                            </span>
                            <button
                              onClick={() => handleDesignPageChange(designCurrentPage + 1)}
                              disabled={designCurrentPage === designTotalPages}
                              className="btn btn-primary ms-2"
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    }
                  </Tab>
                  <Tab className='' eventKey="final_reviewed" title={<><span className='icon-gogle mr-2'></span>Final Reviewed</>}>
                    {loaderForTab ?
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={dottedLoaderSmall} alt='dotted' className='img-fluid' style={{ width: '64px', height: '64px' }} />
                      </div> :
                      <div className='automateme-table-holder' >
                        <div className='automateme-table scroll' style={{ maxHeight: '1000px' }}>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th className='date'
                                  onClick={() => handleSort("cron_time", allFinalArticlesSortConfig, setAllFinalArticlesSortConfig, allFinalArticles, setAllFinalArticles)}
                                >
                                  <span className='icon icon-calendar me-2'></span>
                                  Posting on
                                  {filterIconDisplayInTableHead(allFinalArticlesSortConfig, "cron_time")}
                                </th>
                                <th
                                  onClick={() => handleSort("campaign_name", allFinalArticlesSortConfig, setAllFinalArticlesSortConfig, allFinalArticles, setAllFinalArticles)}
                                >
                                  Campaign
                                  {filterIconDisplayInTableHead(allFinalArticlesSortConfig, "campaign_name")}
                                </th>
                                <th
                                  onClick={() => handleSort("sender_name", allFinalArticlesSortConfig, setAllFinalArticlesSortConfig, allFinalArticles, setAllFinalArticles)}
                                >
                                  Sender Name
                                  {filterIconDisplayInTableHead(allFinalArticlesSortConfig, "sender_name")}
                                </th>
                                <th
                                  onClick={() => handleSort("subject", allFinalArticlesSortConfig, setAllFinalArticlesSortConfig, allFinalArticles, setAllFinalArticles)}
                                >
                                  Subject
                                  {filterIconDisplayInTableHead(allFinalArticlesSortConfig, "subject")}
                                </th>
                                <th
                                  onClick={() => handleSort("intent", allFinalArticlesSortConfig, setAllFinalArticlesSortConfig, allFinalArticles, setAllFinalArticles)}
                                >
                                  Intent
                                  {filterIconDisplayInTableHead(allFinalArticlesSortConfig, "intent")}
                                </th>
                                <th>
                                  <span className='icon icon-status me-2'></span>
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                finalCurrentRows.map((item, index) => {
                                  return (
                                    <tr key={index}
                                    // className={
                                    //     projects.filteredProject.length > 0 &&
                                    //         !projects.filteredProject.includes(article.project.title) ? "d-none" :
                                    //         searchValue.length > 0 && !article.title.toLowerCase().includes(searchValue.toLowerCase()) ? "d-none" : ""
                                    // }
                                    >
                                      <td>{format_date(item.cron_time)}</td>
                                      <td>{item.campaign_name}</td>
                                      <td>{item.sender_name}</td>
                                      <td>{item.subject}</td>
                                      <td>{item.intent}</td>
                                      <td>

                                        <div className='d-flex align-items-center justify-content-between'>
                                          <Dropdown className='automateDropDown'>
                                            <Dropdown.Toggle id="statusCategory0">
                                              {item.status === "Cancelled" ?
                                                <>
                                                  <svg style={{ width: 20, marginRight: '6px' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.99999 19.9998C7.32891 19.9998 4.81772 18.9597 2.92894 17.071C-3.34157 10.7868 1.12445 0.00874995 10.0001 0C18.876 0.00835933 23.3414 10.7892 17.071 17.0709C15.1823 18.9597 12.6711 19.9998 9.99999 19.9998ZM9.99999 1.56245C5.34756 1.56245 1.56254 5.34747 1.56254 9.9999C2.02601 21.1933 17.9756 21.1901 18.4374 9.99982C18.4374 5.34747 14.6524 1.56245 9.99999 1.56245ZM15.0837 15.0835C15.3887 14.7784 15.3887 14.2837 15.0837 13.9787L6.02122 4.91622C5.71611 4.61111 5.22142 4.61111 4.91635 4.91622C4.61127 5.22134 4.61127 5.71598 4.91635 6.02106L13.9788 15.0835C14.1313 15.2361 14.3313 15.3123 14.5313 15.3123C14.7312 15.3123 14.9311 15.2361 15.0837 15.0835Z" fill="#ED1C24" />
                                                  </svg>
                                                  {item.status}
                                                </> :
                                                item.status === "Scheduled" ?
                                                  <>
                                                    <span className='icon-clock'></span>
                                                    {item.status}
                                                  </> :
                                                  item.status === "Design Reviewed" ?
                                                    <>
                                                      <span className='icon-gogle'></span>
                                                      {item.status}
                                                    </> :
                                                    item.status === "Content Reviewed" ?
                                                      <>
                                                        <span className='icon-gogle'></span>
                                                        {item.status}
                                                      </> :
                                                      item.status === "Final Reviewed" ?
                                                        <>
                                                          <span className='icon-gogle'></span>
                                                          {item.status}
                                                        </> :
                                                        item.status === "Sent" ?
                                                          <>
                                                            <span className='icon-sent'></span>
                                                            {item.status}
                                                          </> :
                                                          item.status === "Generated" ?
                                                            <>
                                                              <span className='icon-magic'></span>
                                                              {item.status}
                                                            </> : ""
                                              }
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-sent'></span>
                                                Sent
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-magic'></span>
                                                Generated
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-gogle'></span>
                                                Design Reviewed
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-gogle'></span>
                                                Content Reviewed
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-gogle'></span>
                                                Final Reviewed
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-clock'></span>
                                                Scheduled
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <svg style={{ width: 15, marginRight: '6px' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M9.99999 19.9998C7.32891 19.9998 4.81772 18.9597 2.92894 17.071C-3.34157 10.7868 1.12445 0.00874995 10.0001 0C18.876 0.00835933 23.3414 10.7892 17.071 17.0709C15.1823 18.9597 12.6711 19.9998 9.99999 19.9998ZM9.99999 1.56245C5.34756 1.56245 1.56254 5.34747 1.56254 9.9999C2.02601 21.1933 17.9756 21.1901 18.4374 9.99982C18.4374 5.34747 14.6524 1.56245 9.99999 1.56245ZM15.0837 15.0835C15.3887 14.7784 15.3887 14.2837 15.0837 13.9787L6.02122 4.91622C5.71611 4.61111 5.22142 4.61111 4.91635 4.91622C4.61127 5.22134 4.61127 5.71598 4.91635 6.02106L13.9788 15.0835C14.1313 15.2361 14.3313 15.3123 14.5313 15.3123C14.7312 15.3123 14.9311 15.2361 15.0837 15.0835Z" fill="#ED1C24" />
                                                </svg>
                                                Cancelled
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                          <div className='icon-group d-flex gap-3'>
                                            <Link target="_blank" className='icon-article' style={{ color: '#552DEA' }} to={`/email-campaign?campaign_id=${item.id}&email-campaign=1`}></Link>

                                            <span className='icon-edit'
                                              onClick={() => {
                                                setEditDataTable(item);
                                                setEditEmailCampaignInstanceModalShow(true)

                                              }}
                                            ></span>


                                            <span className='icon-delete'
                                              onClick={() => {
                                                setIntanceId(item.id)
                                                setDeleteModalShow(true);
                                              }}
                                            ></span>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                })
                              }

                            </tbody>
                          </Table>
                          <div className="pagination-controls d-flex justify-content-center mt-3">
                            <button
                              onClick={() => handleFinalPageChange(finalCurrentPage - 1)}
                              disabled={finalCurrentPage === 1}
                              className="btn btn-primary me-2"
                            >
                              Previous
                            </button>
                            <span>
                              Page {finalCurrentPage} of {finalTotalPages}
                            </span>
                            <button
                              onClick={() => handleFinalPageChange(finalCurrentPage + 1)}
                              disabled={finalCurrentPage === finalTotalPages}
                              className="btn btn-primary ms-2"
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    }
                  </Tab>
                  <Tab className='' eventKey="sent" title={<><span className='icon-sent mr-2'></span>Sent</>}>
                    {loaderForTab ?
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={dottedLoaderSmall} alt='dotted' className='img-fluid' style={{ width: '64px', height: '64px' }} />
                      </div> :
                      <div className='automateme-table-holder' >
                        <div className='automateme-table scroll' style={{ maxHeight: '1000px' }}>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th className='date'
                                  onClick={() => handleSort("cron_time", allPublishedArticlesSortConfig, setAllPublishedArticlesSortConfig, allPublishedArticles, setAllPublishedArticles)}
                                >
                                  <span className='icon icon-calendar me-2'></span>
                                  Posting on
                                  {filterIconDisplayInTableHead(allPublishedArticlesSortConfig, "cron_time")}
                                </th>
                                <th
                                  onClick={() => handleSort("campaign_name", allPublishedArticlesSortConfig, setAllPublishedArticlesSortConfig, allPublishedArticles, setAllPublishedArticles)}
                                >
                                  Campaign
                                  {filterIconDisplayInTableHead(allPublishedArticlesSortConfig, "campaign_name")}
                                </th>
                                <th
                                  onClick={() => handleSort("sender_name", allPublishedArticlesSortConfig, setAllPublishedArticlesSortConfig, allPublishedArticles, setAllPublishedArticles)}
                                >
                                  Sender Name
                                  {filterIconDisplayInTableHead(allPublishedArticlesSortConfig, "sender_name")}
                                </th>
                                <th
                                  onClick={() => handleSort("subject", allPublishedArticlesSortConfig, setAllPublishedArticlesSortConfig, allPublishedArticles, setAllPublishedArticles)}
                                >
                                  Subject
                                  {filterIconDisplayInTableHead(allPublishedArticlesSortConfig, "subject")}
                                </th>
                                <th
                                  onClick={() => handleSort("intent", allPublishedArticlesSortConfig, setAllPublishedArticlesSortConfig, allPublishedArticles, setAllPublishedArticles)}
                                >
                                  Intent
                                  {filterIconDisplayInTableHead(allPublishedArticlesSortConfig, "intent")}
                                </th>
                                <th>
                                  <span className='icon icon-status me-2'></span>
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                publishedCurrentRows.map((item, index) => {
                                  return (
                                    <tr key={index}
                                    // className={
                                    //     projects.filteredProject.length > 0 &&
                                    //         !projects.filteredProject.includes(article.project.title) ? "d-none" :
                                    //         searchValue.length > 0 && !article.title.toLowerCase().includes(searchValue.toLowerCase()) ? "d-none" : ""
                                    // }
                                    >
                                      <td>{format_date(item.cron_time)}</td>
                                      <td>{item.campaign_name}</td>
                                      <td>{item.sender_name}</td>
                                      <td>{item.subject}</td>
                                      <td>{item.intent}</td>
                                      <td>

                                        <div className='d-flex gap-2 align-items-center justify-content-between'>
                                          <Dropdown className='automateDropDown'>
                                            <Dropdown.Toggle id="statusCategory0">
                                              {item.status === "Cancelled" ?
                                                <>
                                                  <svg style={{ width: 20, marginRight: '6px' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.99999 19.9998C7.32891 19.9998 4.81772 18.9597 2.92894 17.071C-3.34157 10.7868 1.12445 0.00874995 10.0001 0C18.876 0.00835933 23.3414 10.7892 17.071 17.0709C15.1823 18.9597 12.6711 19.9998 9.99999 19.9998ZM9.99999 1.56245C5.34756 1.56245 1.56254 5.34747 1.56254 9.9999C2.02601 21.1933 17.9756 21.1901 18.4374 9.99982C18.4374 5.34747 14.6524 1.56245 9.99999 1.56245ZM15.0837 15.0835C15.3887 14.7784 15.3887 14.2837 15.0837 13.9787L6.02122 4.91622C5.71611 4.61111 5.22142 4.61111 4.91635 4.91622C4.61127 5.22134 4.61127 5.71598 4.91635 6.02106L13.9788 15.0835C14.1313 15.2361 14.3313 15.3123 14.5313 15.3123C14.7312 15.3123 14.9311 15.2361 15.0837 15.0835Z" fill="#ED1C24" />
                                                  </svg>
                                                  {item.status}
                                                </> :
                                                item.status === "Scheduled" ?
                                                  <>
                                                    <span className='icon-clock'></span>
                                                    {item.status}
                                                  </> :
                                                  item.status === "Design Reviewed" ?
                                                    <>
                                                      <span className='icon-gogle'></span>
                                                      {item.status}
                                                    </> :
                                                    item.status === "Content Reviewed" ?
                                                      <>
                                                        <span className='icon-gogle'></span>
                                                        {item.status}
                                                      </> :
                                                      item.status === "Final Reviewed" ?
                                                        <>
                                                          <span className='icon-gogle'></span>
                                                          {item.status}
                                                        </> :
                                                        item.status === "Sent" ?
                                                          <>
                                                            <span className='icon-sent'></span>
                                                            {item.status}
                                                          </> :
                                                          item.status === "Generated" ?
                                                            <>
                                                              <span className='icon-magic'></span>
                                                              {item.status}
                                                            </> : ""
                                              }
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-sent'></span>
                                                Sent
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-magic'></span>
                                                Generated
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-gogle'></span>
                                                Design Reviewed
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-gogle'></span>
                                                Content Reviewed
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-gogle'></span>
                                                Final Reviewed
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-clock'></span>
                                                Scheduled
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <svg style={{ width: 15, marginRight: '6px' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M9.99999 19.9998C7.32891 19.9998 4.81772 18.9597 2.92894 17.071C-3.34157 10.7868 1.12445 0.00874995 10.0001 0C18.876 0.00835933 23.3414 10.7892 17.071 17.0709C15.1823 18.9597 12.6711 19.9998 9.99999 19.9998ZM9.99999 1.56245C5.34756 1.56245 1.56254 5.34747 1.56254 9.9999C2.02601 21.1933 17.9756 21.1901 18.4374 9.99982C18.4374 5.34747 14.6524 1.56245 9.99999 1.56245ZM15.0837 15.0835C15.3887 14.7784 15.3887 14.2837 15.0837 13.9787L6.02122 4.91622C5.71611 4.61111 5.22142 4.61111 4.91635 4.91622C4.61127 5.22134 4.61127 5.71598 4.91635 6.02106L13.9788 15.0835C14.1313 15.2361 14.3313 15.3123 14.5313 15.3123C14.7312 15.3123 14.9311 15.2361 15.0837 15.0835Z" fill="#ED1C24" />
                                                </svg>
                                                Cancelled
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                          <div className='d-flex gap-3'>
                                            <div>
                                              Total: {item.total} <br /> Sent: {item.sent} <br /> Failed: {item.failed} <br /> Opened: {item.opened}
                                              <Button
                                                disabled={dublicateButtonDisabled ? true : false}
                                                onClick={() => { setDublicateButtonDisabled(true); copySendHolder(item.id) }}
                                                variant='primary'
                                                type='submit'
                                                className="mt-3 btn btn-primary"
                                              ><span className="icon-lg icon-copy"></span>Copy this Email</Button>
                                            </div>
                                            {/* <Link target="_blank" className='icon-article' style={{ color: '#552DEA' }} to={`/email-campaign?campaign_id=${item.id}&email-campaign=1`}></Link>

                                            <span className='icon-edit'
                                              onClick={() => {
                                                setEditDataTable(item);
                                                setEditEmailCampaignInstanceModalShow(true)

                                              }}
                                            ></span>


                                            <span className='icon-delete'
                                              onClick={() => {
                                                setIntanceId(item.id)
                                                setDeleteModalShow(true);
                                              }}
                                            ></span> */}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                })
                              }

                            </tbody>
                          </Table>
                          <div className="pagination-controls d-flex justify-content-center mt-3">
                            <button
                              onClick={() => handlePublishedPageChange(publishedCurrentPage - 1)}
                              disabled={publishedCurrentPage === 1}
                              className="btn btn-primary me-2"
                            >
                              Previous
                            </button>
                            <span>
                              Page {publishedCurrentPage} of {publishedTotalPages}
                            </span>
                            <button
                              onClick={() => handlePublishedPageChange(publishedCurrentPage + 1)}
                              disabled={publishedCurrentPage === publishedTotalPages}
                              className="btn btn-primary ms-2"
                            >
                              Next
                            </button>
                          </div>

                        </div>
                      </div>
                    }
                  </Tab>
                  <Tab className='' eventKey="cancelled" title={<><svg className='mr-2' width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.99999 19.9998C7.32891 19.9998 4.81772 18.9597 2.92894 17.071C-3.34157 10.7868 1.12445 0.00874995 10.0001 0C18.876 0.00835933 23.3414 10.7892 17.071 17.0709C15.1823 18.9597 12.6711 19.9998 9.99999 19.9998ZM9.99999 1.56245C5.34756 1.56245 1.56254 5.34747 1.56254 9.9999C2.02601 21.1933 17.9756 21.1901 18.4374 9.99982C18.4374 5.34747 14.6524 1.56245 9.99999 1.56245ZM15.0837 15.0835C15.3887 14.7784 15.3887 14.2837 15.0837 13.9787L6.02122 4.91622C5.71611 4.61111 5.22142 4.61111 4.91635 4.91622C4.61127 5.22134 4.61127 5.71598 4.91635 6.02106L13.9788 15.0835C14.1313 15.2361 14.3313 15.3123 14.5313 15.3123C14.7312 15.3123 14.9311 15.2361 15.0837 15.0835Z" fill="#ED1C24" />
                  </svg>
                    Cancelled</>}>
                    {loaderForTab ?
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={dottedLoaderSmall} alt='dotted' className='img-fluid' style={{ width: '64px', height: '64px' }} />
                      </div> :
                      <div className='automateme-table-holder' >
                        <div className='automateme-table scroll' style={{ maxHeight: '1000px' }}>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th className='date'
                                  onClick={() => handleSort("cron_time", allCancelledArticlesSortConfig, setAllCancelledArticlesSortConfig, allCancelledArticles, setAllCancelledArticles)}
                                >
                                  <span className='icon icon-calendar me-2'></span>
                                  Posting on
                                  {filterIconDisplayInTableHead(allCancelledArticlesSortConfig, "cron_time")}
                                </th>
                                <th
                                  onClick={() => handleSort("campaign_name", allCancelledArticlesSortConfig, setAllCancelledArticlesSortConfig, allCancelledArticles, setAllCancelledArticles)}
                                >
                                  Campaign
                                  {filterIconDisplayInTableHead(allCancelledArticlesSortConfig, "campaign_name")}
                                </th>
                                <th
                                  onClick={() => handleSort("sender_name", allCancelledArticlesSortConfig, setAllCancelledArticlesSortConfig, allCancelledArticles, setAllCancelledArticles)}
                                >
                                  Sender Name
                                  {filterIconDisplayInTableHead(allCancelledArticlesSortConfig, "sender_name")}
                                </th>
                                <th
                                  onClick={() => handleSort("subject", allCancelledArticlesSortConfig, setAllCancelledArticlesSortConfig, allCancelledArticles, setAllCancelledArticles)}
                                >
                                  Subject
                                  {filterIconDisplayInTableHead(allCancelledArticlesSortConfig, "subject")}
                                </th>
                                <th
                                  onClick={() => handleSort("intent", allCancelledArticlesSortConfig, setAllCancelledArticlesSortConfig, allCancelledArticles, setAllCancelledArticles)}
                                >
                                  Intent
                                  {filterIconDisplayInTableHead(allCancelledArticlesSortConfig, "intent")}
                                </th>
                                <th>
                                  <span className='icon icon-status me-2'></span>
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                cancelledCurrentRows.map((item, index) => {
                                  return (
                                    <tr key={index}
                                    // className={
                                    //     projects.filteredProject.length > 0 &&
                                    //         !projects.filteredProject.includes(article.project.title) ? "d-none" :
                                    //         searchValue.length > 0 && !article.title.toLowerCase().includes(searchValue.toLowerCase()) ? "d-none" : ""
                                    // }
                                    >
                                      <td>{format_date(item.cron_time)}</td>
                                      <td>{item.campaign_name}</td>
                                      <td>{item.sender_name}</td>
                                      <td>{item.subject}</td>
                                      <td>{item.intent}</td>
                                      <td>

                                        <div className='d-flex align-items-center justify-content-between'>
                                          <Dropdown className='automateDropDown'>
                                            <Dropdown.Toggle id="statusCategory0">
                                              {item.status === "Cancelled" ?
                                                <>
                                                  <svg style={{ width: 20, marginRight: '6px' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.99999 19.9998C7.32891 19.9998 4.81772 18.9597 2.92894 17.071C-3.34157 10.7868 1.12445 0.00874995 10.0001 0C18.876 0.00835933 23.3414 10.7892 17.071 17.0709C15.1823 18.9597 12.6711 19.9998 9.99999 19.9998ZM9.99999 1.56245C5.34756 1.56245 1.56254 5.34747 1.56254 9.9999C2.02601 21.1933 17.9756 21.1901 18.4374 9.99982C18.4374 5.34747 14.6524 1.56245 9.99999 1.56245ZM15.0837 15.0835C15.3887 14.7784 15.3887 14.2837 15.0837 13.9787L6.02122 4.91622C5.71611 4.61111 5.22142 4.61111 4.91635 4.91622C4.61127 5.22134 4.61127 5.71598 4.91635 6.02106L13.9788 15.0835C14.1313 15.2361 14.3313 15.3123 14.5313 15.3123C14.7312 15.3123 14.9311 15.2361 15.0837 15.0835Z" fill="#ED1C24" />
                                                  </svg>
                                                  {item.status}
                                                </> :
                                                item.status === "Scheduled" ?
                                                  <>
                                                    <span className='icon-clock'></span>
                                                    {item.status}
                                                  </> :
                                                  item.status === "Design Reviewed" ?
                                                    <>
                                                      <span className='icon-gogle'></span>
                                                      {item.status}
                                                    </> :
                                                    item.status === "Content Reviewed" ?
                                                      <>
                                                        <span className='icon-gogle'></span>
                                                        {item.status}
                                                      </> :
                                                      item.status === "Final Reviewed" ?
                                                        <>
                                                          <span className='icon-gogle'></span>
                                                          {item.status}
                                                        </> :
                                                        item.status === "Sent" ?
                                                          <>
                                                            <span className='icon-sent'></span>
                                                            {item.status}
                                                          </> :
                                                          item.status === "Generated" ?
                                                            <>
                                                              <span className='icon-magic'></span>
                                                              {item.status}
                                                            </> : ""
                                              }
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-sent'></span>
                                                Sent
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-magic'></span>
                                                Generated
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-gogle'></span>
                                                Design Reviewed
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-gogle'></span>
                                                Content Reviewed
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-gogle'></span>
                                                Final Reviewed
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <span className='icon-clock'></span>
                                                Scheduled
                                              </Dropdown.Item>
                                              <Dropdown.Item data-attribute={'statusCategory0'} onClick={(e) => statusItemHandler(e, item.id)}>
                                                <svg style={{ width: 15, marginRight: '6px' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M9.99999 19.9998C7.32891 19.9998 4.81772 18.9597 2.92894 17.071C-3.34157 10.7868 1.12445 0.00874995 10.0001 0C18.876 0.00835933 23.3414 10.7892 17.071 17.0709C15.1823 18.9597 12.6711 19.9998 9.99999 19.9998ZM9.99999 1.56245C5.34756 1.56245 1.56254 5.34747 1.56254 9.9999C2.02601 21.1933 17.9756 21.1901 18.4374 9.99982C18.4374 5.34747 14.6524 1.56245 9.99999 1.56245ZM15.0837 15.0835C15.3887 14.7784 15.3887 14.2837 15.0837 13.9787L6.02122 4.91622C5.71611 4.61111 5.22142 4.61111 4.91635 4.91622C4.61127 5.22134 4.61127 5.71598 4.91635 6.02106L13.9788 15.0835C14.1313 15.2361 14.3313 15.3123 14.5313 15.3123C14.7312 15.3123 14.9311 15.2361 15.0837 15.0835Z" fill="#ED1C24" />
                                                </svg>
                                                Cancelled
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                          <div className='icon-group d-flex gap-3'>
                                            <Link target="_blank" className='icon-article' style={{ color: '#552DEA' }} to={`/email-campaign?campaign_id=${item.id}&email-campaign=1`}></Link>

                                            <span className='icon-edit'
                                              onClick={() => {
                                                setEditDataTable(item);
                                                setEditEmailCampaignInstanceModalShow(true)

                                              }}
                                            ></span>


                                            <span className='icon-delete'
                                              onClick={() => {
                                                setIntanceId(item.id)
                                                setDeleteModalShow(true);
                                              }}
                                            ></span>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                })
                              }

                            </tbody>
                          </Table>
                          <div className="pagination-controls d-flex justify-content-center mt-3">
                            <button
                              onClick={() => handleCancelledPageChange(cancelledCurrentPage - 1)}
                              disabled={cancelledCurrentPage === 1}
                              className="btn btn-primary me-2"
                            >
                              Previous
                            </button>
                            <span>
                              Page {cancelledCurrentPage} of {cancelledTotalPages}
                            </span>
                            <button
                              onClick={() => handleCancelledPageChange(cancelledCurrentPage + 1)}
                              disabled={cancelledCurrentPage === cancelledTotalPages}
                              className="btn btn-primary ms-2"
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    }
                  </Tab>
                </Tabs>
              </div>}
          </>
        }
      </main >
      <AnErrorOccured showError={tryLater} setShowError={setTryLater} />
      {createEmailCampaignInstanceModalShow ? <CreateEmailCampaignInstanceModal show={createEmailCampaignInstanceModalShow} setCreateEmailCampaignInstanceModalShow={setCreateEmailCampaignInstanceModalShow} getAllInstanceMethodFunc={getAllInstanceMethod} /> : ""
      }
      <MyTemplateModal show={myTemplateModalShow} setMyTemplateModalShow={setMyTemplateModalShow} />
      <DeleteModal title="Delete this instance?" para={"Are you sure you want to delete this instance?"} show={deleteModalShow} closeModal={instanceDeleteCloseModal} deleteHandler={deleteHandler} />
      <MessageModal title="Instance deleted" show={instanceDeleteMessageModal} />
      {editEmailCampaignInstanceModalShow ? <EditEmailCampaignInstanceModal setOnlyViewEditModal={setOnlyViewEditModal} onlyViewEditModal={onlyViewEditModal} data={editDataTable} show={editEmailCampaignInstanceModalShow} setEditEmailCampaignInstanceModalShow={setEditEmailCampaignInstanceModalShow} getAllInstanceMethodFunc={getAllInstanceMethod} setPageLoader={setPageLoader} /> : ""}
      <UpgradeUserPlanModal show={blanceExhaustedModal} exhaustedModalCloser={exhaustedModalCloser} />
    </>
  )
}
