import React, {useContext, useEffect, useMemo, useState} from 'react';

import { Stack, useTheme } from '@mui/material';
import getConfiguration from '../../components/EmailEditor/getConfiguration/index.tsx';
import { useInspectorDrawerOpen, useSamplesDrawerOpen, resetDocument, useDocument } from '../../components/EmailEditor/documents/editor/EditorContext.tsx';
import HeaderWithoutMenu from '../../components/HeaderWithoutMenu/HeaderWithoutMenu.jsx';
import InspectorDrawer, { INSPECTOR_DRAWER_WIDTH } from '../../components/EmailEditor/InspectorDrawer/index.tsx';
import SamplesDrawer, { SAMPLES_DRAWER_WIDTH } from '../../components/EmailEditor/SamplesDrawer/index.tsx';
import TemplatePanel from '../../components/EmailEditor/TemplatePanel/index.tsx';
import { Button } from 'react-bootstrap';
import {ThemeContext} from '../../projectContext/ThemeContext.js';
import { UpdateCustomEmailBuilderController } from '../../provider/EmailBuilderControlller.js';
import { GetCustomEmailBuilderController } from '../../provider/EmailBuilderControlller.js';
import { useLocation } from 'react-router-dom';
import { toggleSamplesDrawerOpen } from '../../components/EmailEditor/documents/editor/EditorContext.tsx';
import { toggleInspectorDrawerOpen } from '../../components/EmailEditor/documents/editor/EditorContext.tsx';

function useDrawerTransition(cssProperty: 'margin-left' | 'margin-right', open: boolean) {
    const { transitions } = useTheme();
    return transitions.create(cssProperty, {
        easing: !open ? transitions.easing.sharp : transitions.easing.easeOut,
        duration: !open ? transitions.duration.leavingScreen : transitions.duration.enteringScreen,
    });
}

export default function EmailTemplatePage() {

    const ThemeContextData = useContext(ThemeContext);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const document = useDocument();

    const inspectorDrawerOpen = useInspectorDrawerOpen();
    const samplesDrawerOpen = useSamplesDrawerOpen();
    const [jsonPanelData, setJsonPanelData] = useState(null);

    const marginLeftTransition = useDrawerTransition('margin-left', samplesDrawerOpen);
    const marginRightTransition = useDrawerTransition('margin-right', inspectorDrawerOpen);

    const saveHandler = async () =>{
        const result = await UpdateCustomEmailBuilderController(ThemeContextData, queryParams.get('template_id'), document)
    }
    const getTemplateFunc = async () =>{
        const result = await GetCustomEmailBuilderController(ThemeContextData, queryParams.get('template_id'));
        if(result.success){
            let obj = JSON.parse(result.data.template_data);
            console.log(obj);
            resetDocument(obj)
        }
    }

    useEffect(()=>{
        toggleSamplesDrawerOpen();
        toggleInspectorDrawerOpen();
        getTemplateFunc();
        
    }, [])



    return (
        <div className='email-template-page'>
            <HeaderWithoutMenu articleGenerator={true} />
            <div style={{ background: '#7463F7', position: 'relative', zIndex: '99' }} className='p-3 d-flex justify-content-end gap-3'>
                <Button onClick={saveHandler}  type="button" className='btn btn-light d-flex gap-2' style={{ minWidth: '80px', padding: '12px' }}> Save </Button>
            </div>
            <main className='justify-content-center'>
                <InspectorDrawer />
                <SamplesDrawer />
                <Stack
                    sx={{
                        marginRight: inspectorDrawerOpen ? `${INSPECTOR_DRAWER_WIDTH}px` : 0,
                        marginLeft: samplesDrawerOpen ? `${SAMPLES_DRAWER_WIDTH}px` : 0,
                        transition: [marginLeftTransition, marginRightTransition].join(', '),
                    }}
                >
                    <TemplatePanel />
                </Stack>
            </main>
        </div>
    );
}
