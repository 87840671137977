import React, { useState, useContext } from 'react';

import { ToggleButton, Box } from '@mui/material';
import { HeadingProps, HeadingPropsDefaults, HeadingPropsSchema } from '@usewaypoint/block-heading';
import Stack from '@mui/material/Stack';
import BaseSidebarPanel from './helpers/BaseSidebarPanel.tsx';
import RadioGroupInput from './helpers/inputs/RadioGroupInput.tsx';
import TextField from '@mui/material/TextField';
import TextInput from './helpers/inputs/TextInput.tsx';
import MultiStylePropertyPanel from './helpers/style-inputs/MultiStylePropertyPanel.tsx';
import { ThemeContext } from '../../../../../projectContext/ThemeContext.js';
import { getTokenSession } from '../../../../../provider/Auth.js';
import LoaderJSON from '../../../../../assets/images/loading.json';
import Lottie from 'lottie-react';
import { Button } from 'react-bootstrap';
import dottedLoaderSmall from '../../../../../assets/images/dottedLoaderSmall.gif';
import UpgradeUserPlanModal from '../../../../UpgradeUserPlanModal/UpgradeUserPlanModal.jsx';

type HeadingSidebarPanelProps = {
  data: HeadingProps;
  setData: (v: HeadingProps) => void;
};
export default function HeadingSidebarPanel({ data, setData }: HeadingSidebarPanelProps) {
  const [, setErrors] = useState<Zod.ZodError | null>(null);

  const ThemeContextData = useContext(ThemeContext);
  // const [generating, setGenerating] = useState(false);
  // const [aiHelp, setAiHelp] = useState(false);
  // const [fieldCounter, setFieldCounter] = useState(0);
  // const [promptValue, setPromptValue] = useState("");
  // const [generatedResult, setGeneratedResult] = useState("");
  // const [blanceExhaustedModal, setBlanceExhaustedModal] = useState(false);

  const updateData = (d: unknown) => {
    const res = HeadingPropsSchema.safeParse(d);
    if (res.success) {
      setData(res.data);
      setErrors(null);
    } else {
      setErrors(res.error);
    }
  };

  // const chatAPIHandler = () => {

  //   let newIndexObject = { role: "system", content: "You are a helpful assistant." };
  //   let messagesDataArr = [{ role: "user", content: "Regenerate: " + data.props?.text + " " + promptValue }];

  //   messagesDataArr = [newIndexObject].concat(messagesDataArr);

  //   setGenerating(true);

  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", "Bearer " + getTokenSession());
  //   myHeaders.append("accept", "application/json");
  //   myHeaders.append("Content-Type", "application/json");

  //   // var raw = JSON.stringify({
  //   //   GPT: "GPT-3.5-Ext",
  //   //   copy: 1,
  //   //   language: "English",
  //   //   productName: messagesDataArr,
  //   //   tone: "Appreciative",
  //   //   usecase: "GrammarImprove"
  //   // });

  //   var raw = JSON.stringify({
  //     usecase: "ChatCall",
  //     GPT: "GPT-4o",
  //     messages: messagesDataArr,
  //   });

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     redirect: "follow",
  //     body: raw,
  //   };

  //   fetch(
  //     ThemeContextData.apiURL + 'chats/chat-turbo',
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result.response.data) {
  //         console.log(result);
  //         // setGeneratedResult(result.response.data);
  //         let text = result.response.data[0];
  //         console.log(text);
  //         updateData({ ...data, props: { ...data.props, text } });
  //       }
  //       else if (result.response.message === "balance exhausted") {
  //         setBlanceExhaustedModal(true)
  //       }

  //       setGenerating(false);

  //     })
  //     .catch((error) => { });
  // }

  // const generatedResultHandler = (e) => {
  //   console.log(e);
  //   setGeneratedResult(e);
  // }

  // const exhaustedModalCloser = () => {
  //   setBlanceExhaustedModal(false);
  // }

  return (
    <>
      <BaseSidebarPanel title="Heading block">
        <TextInput
          label="Content"
          rows={3}
          defaultValue={data.props?.text ?? HeadingPropsDefaults.text}
          onChange={(text) => {
            updateData({ ...data, props: { ...data.props, text } });
          }}
        />
        {/* {generating ? <img src={dottedLoaderSmall} alt='loader' className='img-fluid' style={{ width: '32px', height: '32px', margin: '0 0 0 auto' }} /> :
          <Box style={{ textAlign: 'right', marginTop: 5, cursor: 'pointer' }} onClick={chatAPIHandler}>AI Help?</Box>} */}

        {/* {aiHelp ?
          <Box style={{ gap: 15, display: 'flex', flexDirection: 'column' }}>
              <TextField
                label="Enter Prompt"
                type="text"
                value={promptValue}
                onChange={(e) => setPromptValue(e.target.value)}
              />
              {generating ? <Button variant='dark' className='mx-auto' onClick={(e) => { e.preventDefault(); }}>
                <Lottie className='LoaderJSONSVG' animationData={LoaderJSON} />
                Generating...
              </Button> :
                <Button
                  variant='primary' className='mx-auto' onClick={chatAPIHandler}>
                  <span className='icon-lg icon-article'></span>
                  Generate Prompt
                </Button>
              }
          </Box>
          : ""} */}

        <RadioGroupInput
          label="Level"
          defaultValue={data.props?.level ?? HeadingPropsDefaults.level}
          onChange={(level) => {
            updateData({ ...data, props: { ...data.props, level } });
          }}
        >
          <ToggleButton value="h1">H1</ToggleButton>
          <ToggleButton value="h2">H2</ToggleButton>
          <ToggleButton value="h3">H3</ToggleButton>
        </RadioGroupInput>
        <MultiStylePropertyPanel
          names={['color', 'backgroundColor', 'fontFamily', 'fontWeight', 'textAlign', 'padding']}
          value={data.style}
          onChange={(style) => updateData({ ...data, style })}
        />
      </BaseSidebarPanel>
      {/* <UpgradeUserPlanModal show={blanceExhaustedModal} exhaustedModalCloser={exhaustedModalCloser} /> */}
    </>
  );
}
