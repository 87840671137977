import Form from 'react-bootstrap/Form';

export default function InputTagsField(props) {
    const tags = Array.isArray(props.tags) ? props.tags : [];

    return (
        <>
            <div className={props.keywordValue !== undefined && props.keywordValue.length > 0 || props.tags !== undefined && props.tags.length > 0 ? "tag-field-group mb-3" : props.fieldError !== undefined && props.fieldError ? "tag-field-group mb-3 error" : "tag-field-group mb-3"}>
                {
                    props.keywordValue !== undefined ?
                        props.tags.map(
                            (item, i) =>
                                <span className='badge-secondary'>
                                    {item.dot != undefined ? (<span className='dot'></span>) : ""}
                                    {item} {item.toLowerCase() !== "default" ? <span className='icon icon-cross' style={{ cursor: 'pointer' }} onClick={() => props.keywordRemoveHandler(i)}></span> : ""}
                                </span>
                        )
                        :
                        props.getRefCategory ? 
                        
                        props.tags.map(
                            (item, i) =>
                                <span className='badge-secondary'>
                                    {item.dot != undefined ? (<span className='dot'></span>) : ""}
                                    {item} {item !== "default" ? <span className='icon icon-cross' style={{ cursor: "pointer" }} onClick={() => props.categoryRemoveHandler(i)}></span> : ""}
                                </span>
                        ) :
                        props.tags.map(
                            (item, i) =>
                                <span className='badge-secondary'>
                                    {item.dot != undefined ? (<span className='dot'></span>) : ""}
                                    {item} {item !== "default" ? <span className='icon icon-cross' style={{ cursor: "pointer" }} onClick={() => props.userRemoveHandler(i)}></span> : ""}
                                </span>
                        )
                }

                <div className='tag-input-field'>
                    {props.keywordValue !== undefined ?
                        <Form.Control
                            type="text"
                            spellcheck="true"
                            value={props.keywordValue}
                            onChange={(e) => props.keywordValueHandler(e.target.value)}
                            onKeyDown={(e) => props.keywordEnter(e.target.value, e.code)}
                            onFocus={() => props.getRefkeyword !== undefined ? props.getRefkeyword(true) : ""}
                            placeholder={props.placeholder}
                        /> : props.getRefCategory ? 
                        <Form.Control
                            type="text"
                            spellcheck="true"
                            value={props.categoryValue}
                            onChange={(e) => props.categoryValueHandler(e.target.value)}
                            onKeyDown={(e) => props.categoryEnter(e.target.value, e.code)}
                            onFocus={() => props.getRefCategory !== undefined ? props.getRefCategory(true) : ""}
                            placeholder={props.placeholder}
                        /> : 
                        <Form.Control
                            type="text"
                            spellcheck="true"
                            value={props.userValue}
                            onChange={(e) => props.userValueHandler(e.target.value)}
                            onKeyDown={(e) => props.userEnter(e.target.value, e.code)}
                            onFocus={() => props.getRefUser !== undefined ? props.getRefUser(true) : ""}
                            placeholder={props.placeholder}
                        />}
                </div>
            </div>
        </>
    )
}