import { getTokenSession } from './Auth';

const updateArticleDetails = async (ThemeContextData, setTryLater, articleId, articleText, keywords) => {
    let data = [];

    keywords.forEach((item) => {
        let lowerCaseItem = item.toLowerCase();
        let wordCount = document.querySelector('.ql-editor')
            .textContent.toLowerCase()
            .split(lowerCaseItem).length - 1;
        data.push({ 'text': item, word: wordCount });
    });

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        article_id: articleId,
        article_text: articleText,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}article/update-article-generator`,
            requestOptions
        );
        const result = await response.json();

        if (response.ok) {
            return { success: true, data };
        } else {
            return { success: false, error: "Failed to update the article." };
        }
    } catch (error) {
        setTryLater(true)
        console.error("Error updating article:", error);
        return { success: false, error: "API request failed" };
    }
};

const getArticle = async (ThemeContextData, setTryLater, articleId) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({ article_id: articleId });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}article/get-aricle-generator`,
            requestOptions
        );
        const result = await response.json();

        if (response.ok) {
            const responseData = result.response;

            // Parse SEO data
            let seoDataArray = [];
            if (responseData.result[0]?.seo_score) {
                const seoData = JSON.parse(JSON.parse(responseData.result[0].seo_score));
                seoDataArray = [
                    ["Overall Score", seoData["Overall Score"], seoData["Overall Score Remarks"]],
                    ["Title", seoData["Title"], seoData["Title Remarks"]],
                    ["Headings", seoData["Headings"], seoData["Headings Remarks"]],
                    ["Keywords", seoData["Keywords"], seoData["Keywords Remarks"]],
                    ["Content Quality", seoData["Content Quality"], seoData["Content Quality Remarks"]],
                    ["Originality", seoData["Originality"], seoData["Originality Remarks"]],
                    ["User Engagement", seoData["User Engagement"], seoData["User Engagement Remarks"]],
                ];
            }

            // Parse sentiment analysis data
            let sentimentDataArray = [];
            if (responseData.result[0]?.sentiment_analysis) {
                const sentimentData = JSON.parse(JSON.parse(responseData.result[0].sentiment_analysis));
                sentimentDataArray = [
                    ["Anger", sentimentData["Anger"], sentimentData["Anger Remarks"]],
                    ["Clarity", sentimentData["Clarity"], sentimentData["Clarity Remarks"]],
                    ["Confidence", sentimentData["Confidence"], sentimentData["Confidence Remarks"]],
                    ["Excitement", sentimentData["Excitement"], sentimentData["Excitement Remarks"]],
                    ["Optimism", sentimentData["Optimism"], sentimentData["Optimism Remarks"]],
                ];
            }

            let keyword = [];
            if (responseData.result[0] && responseData.result[0]?.keywords) {
                responseData.result[0].keywords.forEach(item => keyword.push({ title: item.keyword, keyword: item.keyword, url: item.url }))
            }

            // Parse article text with headings
            let articleText = "";
            if (responseData.result[0]?.article) {
                if (responseData.result[0]?.article.includes('%3C') && responseData.result[0]?.article.includes('%3E')) {
                    articleText = unescape(responseData.result[0]?.article);
                } else {
                    articleText = responseData.result[0].article;
                }
                // if (unescapedArticle.includes('#')) {
                //     articleText = unescapedArticle
                //         .replace(/<br>/g, '\n')
                //         .split('\n')
                //         .map(line => {
                //             if (line.includes('#####')) return `<h5>${line.replace(/#####\s*/, '')}</h5>`;
                //             if (line.includes('####')) return `<h4>${line.replace(/####\s*/, '')}</h4>`;
                //             if (line.includes('###')) return `<h3>${line.replace(/###\s*/, '')}</h3>`;
                //             if (line.includes('##')) return `<h2>${line.replace(/##\s*/, '')}</h2>`;
                //             if (line.includes('#')) return `<h1>${line.replace(/#\s*/, '')}</h1>`;
                //             return line + '\n\n';
                //         })
                //         .join('');
                // } else {
                //     articleText = unescapedArticle;
                // }
            }

            // Prepare categories
            const categoryTitles = responseData.result[0]?.category.map(item => item.title) || [];
            const categoryIds = responseData.result[0]?.category.map(item => item.id) || [];
            const seedKeywordObject = responseData.result[0]?.keywords.filter(item => item.type === "seed"); 
            return {
                success: true,
                articleText,
                seoDataArray,
                sentimentDataArray,
                seedKeyword: seedKeywordObject[0]?.keyword,
                published_url: responseData.result[0]?.published_url,
                keywordCount: responseData.keyword.length,
                wordsLength: responseData.result[0]?.words_length,
                featuredImage: responseData.result[0]?.featured_image,
                time: responseData.result[0]?.cron_time,
                domain: responseData.result[0]?.domain,
                gptVersion: responseData.result[0]?.gpt.includes('gpt-4') ? 'GPT-4' : 'GPT-3.5',
                voiceUrls: responseData.voice?.map(voice => voice.voice_url) || [],
                keywords: keyword,
                publish_id: responseData.result[0].publish_id,
                categoryTitles,
                categoryIds,
            };
        } else {
            return { success: false, error: "Failed to retrieve article data." };
        }
    } catch (error) {
        setTryLater(true)
        console.error("Error fetching article:", error);
        return { success: false, error: "API request failed" };
    }
};

const permalinkUpdateController = async (ThemeContextData, articleId, url_slug) => {

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        article_id: articleId,
        slug: url_slug,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}article/updateWordPressSlug`,
            requestOptions
        );
        const result = await response.json();

        if (response.ok) {
            return { success: true, permalink: result.data.new_permalink };
        } else {
            return { success: false };
        }
    } catch (error) {
        console.error("Error updating article:", error);
        return { success: false, error: "API request failed" };
    }
};

const ArticleKeywordsRankingController = async (ThemeContextData, articleId) => {

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        article_id: articleId,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const result = await fetch(
            `${ThemeContextData.apiURL}article/keyword-ranking`,
            requestOptions
        );
        const response = await result.json();

        if (response.data.ok && response.data.message) {
            return { success: true, message: "Publish your article first!" };
        } else if (response.ok && response.data.new_data.keyword && response.data.old_data) {
            let new_obj = response.data.new_data.keyword;
            let old_obj = JSON.parse(response.data.old_data).keyword;

            if (new_obj.length === 0 && old_obj.length === 0) {
                return { success: true, message: "No Keywords Ranking!" };
            } else {

                // Map through all unique keywords
                const allKeywords = [...new Set([...new_obj.map(item => item.keyword), ...old_obj.map(item => item.keyword)])];


                // Map through all unique keywords
                const arrayData = allKeywords.map(keyword => {
                    const newItem = new_obj.find(item => item.keyword === keyword);
                    const oldItem = old_obj.find(item => item.keyword === keyword);

                    return {
                        keyword,
                        "old_rank": oldItem ? oldItem.rank : "not present",
                        "new_rank": newItem ? newItem.rank : "not present",
                        "position": newItem.position
                    };
                });

                return { success: true, data: arrayData };
            }
        } else {
            return { success: false };
        }
    } catch (error) {
        console.error("Error updating article:", error);
        return { success: false, error: "API request failed" };
    }
};
const ArticleHumanizeController = async (ThemeContextData, setTryLater, content, articleId, gpt) => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");


    var raw = JSON.stringify({
        content: content,
        article_id: articleId,
        gpt: gpt,
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw,
    };
    try {
        const result = await fetch(
            `${ThemeContextData.apiURL}article/article-humanize`,
            requestOptions
        );

        const response = await result.json();
        if (response.data.ok) {
            return { success: true, text: response.data.data }
        } else if (response.data.message === "balance exhausted" || response.data.message === "Balance exhausted") {
            return { success: false, message: "balance exhausted" }
        } else {
            return { success: false }
        }

    }
    catch (error) {
        setTryLater(true)
    }


};

export {
    updateArticleDetails,
    getArticle,
    permalinkUpdateController,
    ArticleKeywordsRankingController,
    ArticleHumanizeController
};