import { useState, useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import Folder from './../../assets/images/folder-image.png';
import { ThemeContext } from './../../projectContext/ThemeContext';
import CloseButton from 'react-bootstrap/CloseButton';
import { permalinkUpdateController } from '../../provider/ArticleWriterController';

export default function PermalinkModal(props) {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [articleId, setArticleId] = useState(queryParams.get('article_id'));
    const [slug, setSlug] = useState("");

    const ThemeContextData = useContext(ThemeContext);

    useEffect(() => {
        // let slugVar =  new URL(props.permalinkURL);
        let url = new URL(props.permalinkURL);
        let slugString = url.pathname;
        setSlug(slugString);
    }, [])

    const updateHandler = async () => {
        try {
            const result = await permalinkUpdateController(ThemeContextData, articleId, slug);
            if (result.success) {
                props.setPublishedURL(result.permalink);
                props.setPermalinkModalShow(false);
            }
        } catch (error) {

        }
    }

    return (
        <>
            <Modal show={props.show} centered>
                <Modal.Header>
                    <Modal.Title className='fw-semibold flex-grow-1 pb-1'>
                        <img src={Folder} className='img-responsove' alt='folder-image' />
                        Edit Permalinks
                        <CloseButton onClick={() => props.setPermalinkModalShow(false)} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <Form.Group className="mb-3" controlId="project-name">
                            <Form.Label>URL</Form.Label>
                            {/* <Form.Control type="text" placeholder="My Project"  value={projectTitle} onChange={(e) => setProjectTitle(e.target.value)} /> */}
                            <Form.Control type="text" placeholder="URL" value={slug} onChange={(e) => setSlug(e.target.value)} />
                        </Form.Group>
                        <Form.Group>
                            <Button variant='primary' className='px-3'
                                onClick={updateHandler}
                            >
                                <span className='icon-lg icon-file'></span>
                                Update Project
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}