import { getTokenSession } from './Auth';

const CreateSendingMethodController = async (ThemeContextData, data) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        sender: data.sender,
        email: data.email,
        campaign_name: data.campaign_name,
        language: data.language,
        projectId: data.projectId,
        gpt: data.gpt,
        intent: data.intent,
        category_ids: data.category_ids,
        subject: data.subject,
        outline: data.outline,
        list: data.list,
        sending_smtp: data.sending_smtp,
        date: data.date,
        time: data.time,
        audience_id: data.audience_id,
        brandkit_id: data.brandkit_id,
        template_id: data.template_id
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}automate-email/create-campaign`,
            requestOptions
        );

        const result = await response.json();
        
        if (result.response.ok) {
            return { success: true };
        } else {
            return { success: false };
        }
    } catch (error) {
        return { success: false, error: "API request failed" };
    }
}

const UpdateEmailInstanceMethodController = async (ThemeContextData, data) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        campaign_id: data.campaign_id,
        sender: data.sender,
        email: data.email,
        campaign_name: data.campaign_name,
        language: data.language,
        projectId: data.projectId,
        gpt: data.gpt,
        intent: data.intent,
        category_ids: data.category_ids,
        subject: data.subject,
        outline: data.outline,
        list: data.list,
        sending_smtp: data.sending_smtp,
        date: data.date,
        time: data.time,
        audience_id: data.audience_id,
        brandkit_id: data.brandkit_id,
        template_id: data.template_id
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}automate-email/update-campaign`,
            requestOptions
        );

        const result = await response.json();
        
        if (result.response.ok) {
            return { success: true };
        } else {
            return { success: false };
        }
    } catch (error) {
        return { success: false, error: "API request failed" };
    }
}

const GetAllInstanceMethodController = async (ThemeContextData) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}automate-email/my-campaigns`,
            requestOptions
        );

        const result = await response.json();

        if (result.response.ok) {
            return { success: true, data: result.response.campaigns };
        } else {
            return { success: false };
        }
    } catch (error) {
        return { success: false, error: "API request failed" };
    }

}

const SenderListAutomateController = async (ThemeContextData) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-lists/get-user-lists`,
            requestOptions
        );

        const result = await response.json();

        if (result.response.ok) {
            return { success: true, data: result.response.lists };
        } else {
            return { success: false };
        }
    } catch (error) {
        return { success: false, error: "API request failed" };
    }

}

const DeleteInstanceController = async (ThemeContextData, id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        campaign_id: id,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}automate-email/delete-campaign`,
            requestOptions
        );
        const result = await response.json();

        if (response.ok) {
            return { success: true, data: result };
        } else {
            return { success: false, error: "Failed to delete article" };
        }
    } catch (error) {
        console.error("Error deleting article:", error);
        return { success: false, error: "API request failed" };
    }
};

const UpdateStatusAutomateController = async (ThemeContextData, id, status) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        campaign_id: id,
        status: status,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}automate-email/update-campaign-status`,
            requestOptions
        );
        const result = await response.json();

        if (response.ok) {
            return { success: true, data: result };
        } else {
            return { success: false, error: "Failed to update status" };
        }
    } catch (error) {
        console.error("Error updating status:", error);
        return { success: false, error: "API request failed" };
    }
};

const GetAllStatusInstanceController = async (ThemeContextData) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}automate-email/my-campaigns-status`,
            requestOptions
        );

        const result = await response.json();

        if (result.response.ok) {
            return { success: true, data: result.response.data };
        } else {
            return { success: false };
        }
    } catch (error) {
        return { success: false, error: "API request failed" };
    }

}

const GenerateArticleHeadingController = async (state, selectedLangOption, selectedIntentOption, keywordData, AIModalOption, ThemeContextData) => {

    try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getTokenSession());
        myHeaders.append("accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            language: selectedLangOption.text,
            tone: selectedIntentOption.text,
            title: state.emailSubject,
            keyword: keywordData,
            GPT: AIModalOption.text,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
            body: raw,
        };

        const response = await fetch(
            `${ThemeContextData.apiURL}article/article-heading-generate`,
            requestOptions
        );

        const result = await response.json();

        if (result.response.ok) {
            return { success: true, 'data': result.response.data[0] };
        } else if (result.response.message === "balance exhausted") {
            return { success: false, exhausted: true };
        } else {
            return { success: false, error: "Failed to generate article heading" };
        }
    } catch (error) {
        console.error("Error generating article heading:", error);
        return { success: false, error: "API request failed" };
    }

};
export {
    CreateSendingMethodController,
    UpdateEmailInstanceMethodController,
    GetAllInstanceMethodController,
    SenderListAutomateController,
    DeleteInstanceController,
    UpdateStatusAutomateController,
    GetAllStatusInstanceController,
    GenerateArticleHeadingController
}