var _data = null;
var _tempData = null;

var _isLoggedInStat = false;

const addTokenSession = (data) => {
  return localStorage.setItem("REACT_TOKEN_AUTH", JSON.stringify(data));
}

const getTokenSession = () => {
  if (localStorage.getItem("REACT_TOKEN_AUTH") === null || localStorage.getItem("REACT_TOKEN_AUTH") === '') {
    return null
  }
  else {
    _data = JSON.parse(localStorage.getItem("REACT_TOKEN_AUTH"));
    // debugger;
    if (_data.hasOwnProperty('data')) {
      return _data.data.accessToken;
    } else {
      return _data.accessToken;
    }
  }
}

const login = (data) => {
  _data = JSON.parse(JSON.stringify(data));
  _isLoggedInStat = true;
  localStorage.removeItem("REACT_TOKEN_AUTH");
  localStorage.setItem("REACT_TOKEN_AUTH", JSON.stringify(_data));
  return;
};

const savePassword = (data) => {
  localStorage.removeItem("REACT_TEMP_PASSWORD");
  localStorage.setItem("REACT_TEMP_PASSWORD", data);
  return;
};

const getPassword = () => {
  return localStorage.getItem("REACT_TEMP_PASSWORD");
};

const getUserType = () => {
  _data = JSON.parse(localStorage.getItem("REACT_TOKEN_AUTH"));
  // return _data.data.login_type;
  if (_data.hasOwnProperty('data') && _data.data.hasOwnProperty('login_type')) {
    return _data.data.login_type;
  } else {
    return _data.login_type;
  }
};

const getAutomateStatus = () =>{
  _data = JSON.parse(localStorage.getItem("REACT_TOKEN_AUTH"));
  if (_data.data.hasOwnProperty('automate') && _data.data.automate !== undefined) {
    return _data.data.automate;
  } else {
    return null;
  }
}

const subUser = () => {
  _data = JSON.parse(localStorage.getItem("REACT_TOKEN_AUTH"));
  if (_data.data.hasOwnProperty('admin_id') && _data.data.admin_id !== undefined) {
    return _data.data.admin_id;
  } else {
    return null;
  }
}

const permissions = () => {
  _data = JSON.parse(localStorage.getItem("REACT_TOKEN_AUTH"));
  return _data.data.permissions;
}

const quickLogin = () => {
  _isLoggedInStat = true;
  return;
};
const logout = () => {
  localStorage.removeItem("REACT_TOKEN_AUTH");
  localStorage.removeItem('REACT_TEMP_PASSWORD');
  localStorage.removeItem('article-guided');
  window.location.href = "/login";
  // localStorage.removeItem("visit");
  // _isLoggedInStat = false;
  // _data = null;
};

const getExpirationDate = (jwtToken) => {
  if (!jwtToken) {
    return null;
  }

  const jwt = JSON.parse(atob(jwtToken.split(".")[1]));

  // multiply by 1000 to convert seconds into milliseconds
  return (jwt && jwt.exp && jwt.exp * 1000) || null;
};
const isExpired = (exp) => {
  if (!exp) {
    return false;
  }
  return Date.now() > exp;
};

const isLoggedIn = () => {
  if (_isLoggedInStat === false) {
    if (localStorage.getItem("REACT_TOKEN_AUTH") != null) {
      _data = JSON.parse(localStorage.getItem("REACT_TOKEN_AUTH"));
      _isLoggedInStat = true;
    }
  }
  return _isLoggedInStat;
};

const getToken = () => {
  // if (isExpired(getExpirationDate(_data.token))) {
  // logout();
  window.location.href = "/login";
  // }
  return _data.token;
};
const getName = () => {
  if (isExpired(getExpirationDate(_data.token))) {
    // logout();
    window.location.href = "/login";
  }
  return _data.first_name + " " + _data.last_name;
};
const getFirstName = () => {
  if (isExpired(getExpirationDate(_data.token))) {
    // logout();
    window.location.href = "/login";
  }

  _data = JSON.parse(localStorage.getItem("REACT_TOKEN_AUTH"));
  if (_data.hasOwnProperty('data') && _data.data.hasOwnProperty('f_name')) {
    return _data.data.f_name;
  } else {
    return _data.f_name;
  }

};

const getLastName = () => {
  if (isExpired(getExpirationDate(_data.token))) {
    // logout();
    window.location.href = "/login";
  }
  _data = JSON.parse(localStorage.getItem("REACT_TOKEN_AUTH"));

  if (_data.hasOwnProperty('data') && _data.data.hasOwnProperty('l_name')) {
    return _data.data.l_name;
  } else {
    return _data.l_name;
  }
};
const getemail = () => {
  if (isExpired(getExpirationDate(_data.token))) {
    // logout();
    window.location.href = "/login";
  }

  _data = JSON.parse(localStorage.getItem("REACT_TOKEN_AUTH"));
  // debugger;
  if (_data.hasOwnProperty('data') && _data.data.hasOwnProperty('email')) {
    return _data.data.email;
  } else {
    return _data.email;
  }
};
const getPicture = () => {
  // if (isExpired(getExpirationDate(_data.token))) {
  //   logout();
  //   window.location.href = "/login";
  // }
  _data = JSON.parse(localStorage.getItem("REACT_TOKEN_AUTH"));
  if (_data.hasOwnProperty('data') && _data.data.hasOwnProperty('pic')) {
    return _data.data.pic;
  } else {
    return _data.pic;
  }
};

const saveTempData = (data) => {
  _tempData = data;
  localStorage.removeItem("REACT_TOKEN_AUTH_TEMP");
  localStorage.setItem("REACT_TOKEN_AUTH_TEMP", JSON.stringify(_tempData));
};

const savePicture = (pic) => {
  _data.pic = pic;
  localStorage.removeItem("REACT_TOKEN_AUTH");
  localStorage.setItem("REACT_TOKEN_AUTH", JSON.stringify(_data));
};

const getTempToken = () => {
  if (_tempData === null)
    if (localStorage.getItem("REACT_TOKEN_AUTH_TEMP") != null) {
      _tempData = JSON.parse(localStorage.getItem("REACT_TOKEN_AUTH_TEMP"));
    }
  return _tempData.token;
};
const getTempData = () => {
  if (_tempData === null)
    if (localStorage.getItem("REACT_TOKEN_AUTH_TEMP") != null) {
      _tempData = JSON.parse(localStorage.getItem("REACT_TOKEN_AUTH_TEMP"));
    }
  return _tempData;
};

const getTimeZone = () => {
  _data = JSON.parse(localStorage.getItem("REACT_TOKEN_AUTH"));
  if (_data.hasOwnProperty('data') && _data.data.hasOwnProperty('timezone')) {
    return _data.data.timezone;
  }
};

const removeTempData = () => {
  localStorage.removeItem("REACT_TOKEN_AUTH_TEMP");
  _tempData = null;
};

export {
  // login,
  addTokenSession,
  getTokenSession,
  logout,
  isLoggedIn,
  getToken,
  getName,
  getLastName,
  getFirstName,
  getemail,
  getPicture,
  savePicture,
  saveTempData,
  getTempToken,
  getTempData,
  removeTempData,
  quickLogin,
  savePassword,
  getPassword,
  getUserType,
  subUser,
  permissions,
  getTimeZone,
  getAutomateStatus
};