import React, { useReducer, useState, useEffect } from 'react'
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import { CloseButton } from 'react-bootstrap';
import {createList, editList} from "../../provider/ReciptListController";

const initialState = {
    list_name: "",
    description: ""
}

const formReducer = (state, action) => {
    switch (action.type) {
        case "Handle Input Text":
            return {
                ...state,
                [action.field]: action.value,
            };
        case "Set Initial Data":
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default function EditNewListReciptModal(props) {

    const [state, dispatch] = useReducer(formReducer, initialState);

    // UseEffect to update state when editData changes
    useEffect(() => {
        if (props.editData) {
            dispatch({
                type: 'Set Initial Data',
                payload: {
                    list_name: props.editData.list_name || "",
                    description: props.editData.list_desc || ""
                }
            });
        }
    }, [props.editData]);

    const handleTextChange = e => {
        dispatch({
            type: 'Handle Input Text',
            field: e.target.name,
            value: e.target.value
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent page reload
        console.log("List Name:", state.list_name);
        console.log("Description:", state.description);
        try {
            const result = await editList(props.themeContext, state, props.editData.id);
            console.log(result);
            props.setEditNewListReciptModalShow(false);
            await props.getListData();
        } catch (error) {
            console.log(error);
        }

    };

    return (
        <div>
            <Modal className='credit-report-modal' show={props.show} centered>
                <Modal.Header>
                    <Modal.Title className='fw-semibold flex-column flex-grow-1 pb-1 gap-2'>
                        <span className="icon card-square-icon icon-lg icon-add-circle mb-1"></span>
                        Edit List
                        <CloseButton onClick={() => {
                            props.setEditNewListReciptModalShow(false);
                        }} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>List Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="list_name"
                                        placeholder="List Name"
                                        value={state.list_name} // Controlled by state
                                        onChange={handleTextChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="description"
                                        placeholder="Description"
                                        value={state.description} // Controlled by state
                                        onChange={handleTextChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className='modal-footer pt-0 px-0 border-top-0 justify-content-between'>
                            <Button
                                disabled={state.list_name.length === 0 || state.description.length === 0}
                                className='px-3 mx-auto'
                                // onClick={() => props.setEditNewListReciptModalShow(false)}
                                type='submit'
                            >
                                <span className='icon icon-save'></span>
                                Save
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
}
