import React, { useState, useReducer, useContext, useEffect, useRef, useMemo } from 'react';
import { Container, Row, Col, Table, Dropdown, DropdownButton, Modal, Button, Form, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import dottedLoaderSmall from './../../assets/images/dottedLoaderSmall.gif';
import { ThemeContext } from "../../projectContext/ThemeContext";

export default function SuggestedKeywordModal(props) {

    const ThemeContextData = useContext(ThemeContext);
    // const [getVoiceValue, setGetVoiceValue] = useState("");

    // const getVoice = (e) => {
    //     setGetVoiceValue(e);
    // }

    // const chooseVideoHandler = () => {
    //     props.voiceTypeFunc(getVoiceValue);
    //     props.voiceModalHandler();
    // }

    // console.log(props.suggestKeywordAPIResponse);
    const handleCheckboxChange = (e) => {
        if (document.getElementById(e.target.id).checked) {
            // props.keywordAdded({title: e.target.id, keyword: e.target.id, url: ""});
            props.keywordAdded(e.target.id);
        }
        else {
            props.keywordRemove(e.target.id);
        }
    }


    return (
        <>
            <Modal className='customModal suggestKeywordModal' show={props.show} centered >
                <Modal.Header className='flex-wrap justify-content-center'>
                    <div>
                        <Modal.Title className='justify-content-center mb-2'>
                            Suggested Keywords
                        </Modal.Title>
                        <p className='mb-0'>Choose any or all suggested keywords for your article.</p>
                    </div>
                </Modal.Header>
                <Modal.Body className='p-0'>
                    <div className='pb-4'>
                        {props.loader ?
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img src={dottedLoaderSmall} alt='dotted' className='img-fluid' style={{ width: '64px', height: '64px' }} />
                            </div> :
                            props.suggestKeywordModalResponse ? <div className='py-5 d-flex flex-column align-items-center'>
                                <span className='mb-4'>
                                    <svg width="118" height="118" viewBox="0 0 118 118" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_816_835)">
                                            <path d="M114.69 101.651C116.803 103.763 116.803 107.186 114.69 109.298C113.634 110.354 112.251 110.882 110.868 110.882C109.484 110.882 108.1 110.354 107.044 109.298L85.1697 87.4269L85.167 87.4242C86.6209 86.3916 88.0073 85.2213 89.3109 83.9177C90.6136 82.6177 91.7813 81.2313 92.8139 79.78H92.8166L114.69 101.651Z" fill="#FFC843" />
                                            <path d="M92.9238 87.5592C93.9798 86.5032 95.3635 85.9748 96.7472 85.9748C98.1309 85.9748 99.5146 86.5032 100.571 87.5592L100.621 87.5827L92.8166 79.78H92.8139C91.7822 81.2313 90.6137 82.6177 89.311 83.9177C88.0074 85.2213 86.6219 86.3916 85.168 87.4242L85.1698 87.4269L92.6798 94.9351C90.8217 92.8105 90.8991 89.584 92.9238 87.5592Z" fill="#FFA816" />
                                            <path d="M1.72852 7.25977H111.489V55.6375H1.72852V7.25977Z" fill="white" />
                                            <path d="M43.4473 55.6374H105.769C104.281 50.6203 101.56 45.892 97.5984 41.93C84.9001 29.2317 64.3164 29.2317 51.6181 41.93C47.656 45.892 44.9354 50.6203 43.4473 55.6374Z" fill="#E1EAF8" />
                                            <path d="M93.1047 61.0526C93.1047 76.0259 80.9655 88.1651 65.9913 88.1651C51.0171 88.1651 38.8779 76.0259 38.8779 61.0526C38.8779 46.0784 51.0171 33.9392 65.9913 33.9392C80.9655 33.9392 93.1047 46.0784 93.1047 61.0526Z" fill="#987CFF" />
                                            <path d="M40.0117 68.8281C43.3544 80.0122 53.7201 88.165 65.9917 88.165C78.2632 88.165 88.6289 80.0122 91.9716 68.8281H40.0117Z" fill="white" />
                                            <path d="M98.4012 55.6375C99.7615 63.9317 97.8997 72.6535 92.8141 79.78C91.7815 81.2313 90.6138 82.6177 89.3112 83.9176C88.0076 85.2212 86.6212 86.3916 85.1672 87.4242C72.4698 96.4827 54.7255 95.3123 43.3308 83.9176C35.6327 76.2195 32.6024 65.6206 34.2408 55.6375C35.3014 49.1556 38.3317 42.9401 43.3308 37.9409C56.0291 25.2427 76.6129 25.2427 89.3112 37.9409C94.3103 42.9401 97.3397 49.1556 98.4012 55.6375ZM88.3668 68.8328C91.3215 60.5746 89.493 50.9867 82.8787 44.3734C73.7338 35.2275 58.9082 35.2275 49.7633 44.3734C43.1517 50.9849 41.3205 60.5665 44.2725 68.8247C45.4015 71.9873 47.2326 74.9546 49.7633 77.4861C58.9082 86.6311 73.7338 86.6311 82.8787 77.4861C85.4094 74.9546 87.2378 71.9927 88.3668 68.8328Z" fill="#FFC843" />
                                            <path d="M60.4908 58.8568C60.8284 59.1944 61.2704 59.3628 61.7133 59.3628C62.1554 59.3628 62.5983 59.1944 62.9359 58.8568L66.311 55.4808L69.687 58.8568C70.0246 59.1935 70.4667 59.3628 70.9087 59.3628C71.3516 59.3628 71.7936 59.1944 72.1312 58.8568C72.8064 58.1816 72.8064 57.0869 72.1312 56.4117L68.7561 53.0366L72.1312 49.6606C72.8064 48.9863 72.8064 47.8916 72.1312 47.2164C71.456 46.5412 70.3622 46.5412 69.687 47.2164L66.311 50.5924L62.9359 47.2164C62.2607 46.5412 61.166 46.5412 60.4908 47.2164C59.8165 47.8916 59.8165 48.9863 60.4908 49.6606L63.8668 53.0366L60.4908 56.4117C59.8156 57.0869 59.8156 58.1816 60.4908 58.8568Z" fill="white" />
                                            <path d="M115.913 100.429L95.0539 79.5693C98.6379 74.0696 100.562 67.6471 100.562 60.9284C100.562 51.7826 97.0003 43.1841 90.5328 36.7175C84.0652 30.2499 75.4677 26.6885 66.321 26.6885C57.1751 26.6885 48.5767 30.2499 42.1091 36.7175C35.6425 43.1841 32.0811 51.7826 32.0811 60.9284C32.0811 70.0751 35.6425 78.6727 42.1091 85.1402C48.7846 91.8148 57.5532 95.153 66.321 95.153C72.8173 95.153 79.3118 93.3191 84.9565 89.6559L105.821 110.521C107.212 111.911 109.04 112.606 110.867 112.606C112.695 112.606 114.522 111.911 115.913 110.521C118.696 107.738 118.696 103.211 115.913 100.429ZM44.5543 82.696C38.7403 76.882 35.5381 69.1514 35.5381 60.9293C35.5381 52.7062 38.7403 44.9765 44.5543 39.1617C50.3691 33.3478 58.0988 30.1455 66.321 30.1455C74.5431 30.1455 82.2737 33.3478 88.0885 39.1617C93.9025 44.9765 97.1047 52.7071 97.1047 60.9293C97.1047 69.1514 93.9025 76.882 88.0885 82.696C76.0862 94.6983 56.5566 94.6983 44.5543 82.696ZM113.469 108.076C112.035 109.51 109.7 109.51 108.266 108.076L87.7915 87.6015C88.7385 86.839 89.6541 86.0189 90.5337 85.1402C91.4087 84.2651 92.228 83.3496 92.9959 82.4007L113.469 102.874C114.903 104.308 114.903 106.641 113.469 108.076Z" fill="#434C54" />
                                            <path d="M27.3303 53.909H3.45703V8.98828H109.76V53.909H105.366C104.411 53.909 103.637 54.6823 103.637 55.6375C103.637 56.5918 104.411 57.366 105.366 57.366H111.488C112.443 57.366 113.217 56.5918 113.217 55.6375V7.25977C113.217 6.30548 112.443 5.53125 111.488 5.53125H1.72852C0.773331 5.53125 0 6.30548 0 7.25977V55.6375C0 56.5918 0.773331 57.366 1.72852 57.366H27.3303C28.2855 57.366 29.0589 56.5918 29.0589 55.6375C29.0589 54.6823 28.2855 53.909 27.3303 53.909Z" fill="#434C54" />
                                            <path d="M15.5137 17.4958C14.5594 17.4958 13.7852 18.2692 13.7852 19.2244C13.7852 20.1786 14.5594 20.9529 15.5137 20.9529H97.7037C98.658 20.9529 99.4322 20.1786 99.4322 19.2244C99.4322 18.2692 98.658 17.4958 97.7037 17.4958H15.5137Z" fill="#A9B1B8" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_816_835">
                                                <rect width="118" height="118" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                                <p className='h4 fw-normal'>No Relevant Keywords found.</p>
                            </div> :
                                <div className='table-responsive scroll position-relative'>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th className='keyword'>Keyword</th>
                                                <th className='text-center intent'>Intent</th>
                                                <th className='text-center volume'>
                                                    <div className='d-flex align-items-center gap-1 justify-content-center'>
                                                        Volume
                                                        <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.625 7.25002C3.625 6.58268 4.166 6.04169 4.83333 6.04169H24.1667C24.834 6.04169 25.375 6.58268 25.375 7.25002C25.375 7.91736 24.834 8.45835 24.1667 8.45835H4.83333C4.166 8.45835 3.625 7.91736 3.625 7.25002ZM3.625 14.5C3.625 13.8327 4.166 13.2917 4.83333 13.2917H16.9167C17.584 13.2917 18.125 13.8327 18.125 14.5C18.125 15.1674 17.584 15.7084 16.9167 15.7084H4.83333C4.166 15.7084 3.625 15.1674 3.625 14.5ZM3.625 21.75C3.625 21.0827 4.166 20.5417 4.83333 20.5417H10.875C11.5423 20.5417 12.0833 21.0827 12.0833 21.75C12.0833 22.4174 11.5423 22.9584 10.875 22.9584H4.83333C4.166 22.9584 3.625 22.4174 3.625 21.75Z" fill="#552DEA" />
                                                        </svg>
                                                    </div>
                                                </th>
                                                <th className='trent text-center'>Trend</th>
                                                <th className='kd text-center'>KD %</th>
                                                <th className='updated'>Updated</th>
                                            </tr>
                                            <tr>
                                                <td className='bg-white'  colSpan={'6'}>
                                                    <div className='d-flex flex-wrap gap-2'>
                                                        {props.keywordData.map(item => <Badge className='global-badge'>{item.title} <span className='icon icon-cross' style={{ cursor: "pointer" }} onClick={() => props.keywordRemove(item)}></span></Badge>)}
                                                    </div>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props && props.suggestKeywordAPIResponse.length > 0 && props.suggestKeywordAPIResponse.map(item =>
                                                <tr>
                                                    <td>
                                                        <Form.Check
                                                            type={'checkbox'}
                                                            id={`${item.keyword}`}
                                                            label={item.keyword}
                                                            // checked={props.keywordData.includes(item.keyword) ? true : false}
                                                            checked={props.keywordData.some(data => data.keyword === item.keyword)}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                    </td>
                                                    <td className='text-center'>
                                                        <Badge className='fw-medium text-capitalize' data-status={item.main_intent}>{item.main_intent}</Badge>
                                                    </td>
                                                    <td className='text-center'> {item.search_volume} </td>
                                                    <td className='text-center'>
                                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path opacity="0.2" d="M25.375 14C19.6875 26.1242 16.8438 20.0626 14 14H25.375ZM2.625 14H14C11.1562 7.93731 8.3125 1.87575 2.625 14Z" fill="#552DEA" />
                                                            <path d="M26.1673 14.3719C23.761 19.4906 21.6391 21.875 19.4735 21.875C16.7282 21.875 15.0187 18.2306 13.2085 14.3719C11.7801 11.3269 10.1613 7.875 8.53163 7.875C6.73788 7.875 4.56023 11.9448 3.42164 14.3719C3.32033 14.5775 3.14238 14.7352 2.92604 14.811C2.70971 14.8868 2.47227 14.8746 2.26478 14.7772C2.05729 14.6798 1.89632 14.5048 1.81649 14.2899C1.73666 14.075 1.74434 13.8374 1.83789 13.6281C4.23429 8.50937 6.36163 6.125 8.53163 6.125C11.2769 6.125 12.9865 9.76937 14.7966 13.6281C16.2207 16.6731 17.8394 20.125 19.4691 20.125C21.2629 20.125 23.4405 16.0552 24.5791 13.6281C24.6804 13.4225 24.8584 13.2648 25.0747 13.189C25.2911 13.1132 25.5285 13.1254 25.736 13.2228C25.9435 13.3202 26.1044 13.4952 26.1843 13.7101C26.2641 13.925 26.2564 14.1626 26.1629 14.3719H26.1673Z" fill="#552DEA" />
                                                        </svg>
                                                    </td>
                                                    <td className='text-center'>{item.keyword_difficulty > 0 ? item.keyword_difficulty : "0"}
                                                        <svg className='ms-2' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="7" cy="7" r="7" fill="#FFA600" />
                                                        </svg>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex gap-1 align-items-center'>
                                                            {new Date(item.last_updated_time).getDate() + " / " + new Date(item.last_updated_time).getMonth() + " / " + new Date(item.last_updated_time).getFullYear()}
                                                            <svg className='ms-1' width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path opacity="0.2" d="M16.0313 10C16.0313 11.2918 15.6482 12.5545 14.9305 13.6286C14.2129 14.7026 13.1928 15.5398 11.9994 16.0341C10.806 16.5284 9.49276 16.6578 8.22582 16.4058C6.95888 16.1537 5.79512 15.5317 4.88171 14.6183C3.9683 13.7049 3.34626 12.5411 3.09425 11.2742C2.84224 10.0072 2.97158 8.69403 3.46591 7.5006C3.96025 6.30717 4.79738 5.28713 5.87143 4.56946C6.94549 3.8518 8.20824 3.46875 9.5 3.46875C11.2322 3.46875 12.8934 4.15686 14.1183 5.38171C15.3431 6.60656 16.0313 8.26781 16.0313 10Z" fill="#552DEA" />
                                                                <path d="M16.625 4.06245V7.62495C16.625 7.78242 16.5624 7.93344 16.4511 8.04479C16.3397 8.15614 16.1887 8.2187 16.0312 8.2187H12.4688C12.3113 8.2187 12.1603 8.15614 12.0489 8.04479C11.9376 7.93344 11.875 7.78242 11.875 7.62495C11.875 7.46748 11.9376 7.31645 12.0489 7.2051C12.1603 7.09375 12.3113 7.0312 12.4688 7.0312H14.5981L13.5123 5.94538C12.4088 4.83685 10.9105 4.21144 9.34637 4.20643H9.31297C7.76193 4.2028 6.27179 4.80989 5.16488 5.89639C5.05149 6.00224 4.90118 6.05952 4.7461 6.05596C4.59102 6.0524 4.44349 5.9883 4.33507 5.87736C4.22665 5.76642 4.16596 5.61746 4.16597 5.46234C4.16597 5.30722 4.22669 5.15826 4.33512 5.04733C5.67664 3.73618 7.48111 3.00697 9.35693 3.01794C11.2327 3.02892 13.0286 3.7792 14.3546 5.10596L15.4375 6.19178V4.06245C15.4375 3.90498 15.5001 3.75395 15.6114 3.6426C15.7228 3.53125 15.8738 3.4687 16.0312 3.4687C16.1887 3.4687 16.3397 3.53125 16.4511 3.6426C16.5624 3.75395 16.625 3.90498 16.625 4.06245ZM13.8351 14.1035C12.7173 15.1954 11.214 15.8027 9.65136 15.7935C8.08873 15.7843 6.5927 15.1595 5.48773 14.0545L4.40191 12.9687H6.53125C6.68872 12.9687 6.83975 12.9061 6.95109 12.7948C7.06244 12.6834 7.125 12.5324 7.125 12.3749C7.125 12.2175 7.06244 12.0665 6.95109 11.9551C6.83975 11.8438 6.68872 11.7812 6.53125 11.7812H2.96875C2.81128 11.7812 2.66026 11.8438 2.54891 11.9551C2.43756 12.0665 2.375 12.2175 2.375 12.3749V15.9374C2.375 16.0949 2.43756 16.2459 2.54891 16.3573C2.66026 16.4686 2.81128 16.5312 2.96875 16.5312C3.12622 16.5312 3.27724 16.4686 3.38859 16.3573C3.49994 16.2459 3.5625 16.0949 3.5625 15.9374V13.8081L4.64832 14.8939C5.97254 16.2248 7.771 16.9755 9.64844 16.981H9.68777C11.5492 16.9858 13.3377 16.257 14.6656 14.9526C14.7741 14.8416 14.8348 14.6927 14.8348 14.5376C14.8348 14.3824 14.7741 14.2335 14.6657 14.1225C14.5573 14.0116 14.4097 13.9475 14.2546 13.9439C14.0996 13.9404 13.9493 13.9977 13.8359 14.1035H13.8351Z" fill="#552DEA" />
                                                            </svg>

                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                            {/* <tr>
                                    <td>
                                        <Form.Check
                                            type={'checkbox'}
                                            id={`AI-content-generator`}
                                            label={'AI content generator'}
                                        />
                                    </td>
                                    <td className='text-center'>
                                        <Badge className='fw-medium' data-status="Transactional">Transactional</Badge>
                                    </td>
                                    <td className='text-center'>
                                        60,500
                                    </td>
                                    <td className='text-center'>

                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.2" d="M25.375 14C19.6875 26.1242 16.8438 20.0626 14 14H25.375ZM2.625 14H14C11.1562 7.93731 8.3125 1.87575 2.625 14Z" fill="#552DEA" />
                                            <path d="M26.1673 14.3719C23.761 19.4906 21.6391 21.875 19.4735 21.875C16.7282 21.875 15.0187 18.2306 13.2085 14.3719C11.7801 11.3269 10.1613 7.875 8.53163 7.875C6.73788 7.875 4.56023 11.9448 3.42164 14.3719C3.32033 14.5775 3.14238 14.7352 2.92604 14.811C2.70971 14.8868 2.47227 14.8746 2.26478 14.7772C2.05729 14.6798 1.89632 14.5048 1.81649 14.2899C1.73666 14.075 1.74434 13.8374 1.83789 13.6281C4.23429 8.50937 6.36163 6.125 8.53163 6.125C11.2769 6.125 12.9865 9.76937 14.7966 13.6281C16.2207 16.6731 17.8394 20.125 19.4691 20.125C21.2629 20.125 23.4405 16.0552 24.5791 13.6281C24.6804 13.4225 24.8584 13.2648 25.0747 13.189C25.2911 13.1132 25.5285 13.1254 25.736 13.2228C25.9435 13.3202 26.1044 13.4952 26.1843 13.7101C26.2641 13.925 26.2564 14.1626 26.1629 14.3719H26.1673Z" fill="#552DEA" />
                                        </svg>

                                    </td>
                                    <td className='text-center'>
                                        68
                                    </td>
                                    <td>
                                        <div className='d-flex gap-1 align-items-center'>
                                            Last Week

                                            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.2" d="M16.0313 10C16.0313 11.2918 15.6482 12.5545 14.9305 13.6286C14.2129 14.7026 13.1928 15.5398 11.9994 16.0341C10.806 16.5284 9.49276 16.6578 8.22582 16.4058C6.95888 16.1537 5.79512 15.5317 4.88171 14.6183C3.9683 13.7049 3.34626 12.5411 3.09425 11.2742C2.84224 10.0072 2.97158 8.69403 3.46591 7.5006C3.96025 6.30717 4.79738 5.28713 5.87143 4.56946C6.94549 3.8518 8.20824 3.46875 9.5 3.46875C11.2322 3.46875 12.8934 4.15686 14.1183 5.38171C15.3431 6.60656 16.0313 8.26781 16.0313 10Z" fill="#552DEA" />
                                                <path d="M16.625 4.06245V7.62495C16.625 7.78242 16.5624 7.93344 16.4511 8.04479C16.3397 8.15614 16.1887 8.2187 16.0312 8.2187H12.4688C12.3113 8.2187 12.1603 8.15614 12.0489 8.04479C11.9376 7.93344 11.875 7.78242 11.875 7.62495C11.875 7.46748 11.9376 7.31645 12.0489 7.2051C12.1603 7.09375 12.3113 7.0312 12.4688 7.0312H14.5981L13.5123 5.94538C12.4088 4.83685 10.9105 4.21144 9.34637 4.20643H9.31297C7.76193 4.2028 6.27179 4.80989 5.16488 5.89639C5.05149 6.00224 4.90118 6.05952 4.7461 6.05596C4.59102 6.0524 4.44349 5.9883 4.33507 5.87736C4.22665 5.76642 4.16596 5.61746 4.16597 5.46234C4.16597 5.30722 4.22669 5.15826 4.33512 5.04733C5.67664 3.73618 7.48111 3.00697 9.35693 3.01794C11.2327 3.02892 13.0286 3.7792 14.3546 5.10596L15.4375 6.19178V4.06245C15.4375 3.90498 15.5001 3.75395 15.6114 3.6426C15.7228 3.53125 15.8738 3.4687 16.0312 3.4687C16.1887 3.4687 16.3397 3.53125 16.4511 3.6426C16.5624 3.75395 16.625 3.90498 16.625 4.06245ZM13.8351 14.1035C12.7173 15.1954 11.214 15.8027 9.65136 15.7935C8.08873 15.7843 6.5927 15.1595 5.48773 14.0545L4.40191 12.9687H6.53125C6.68872 12.9687 6.83975 12.9061 6.95109 12.7948C7.06244 12.6834 7.125 12.5324 7.125 12.3749C7.125 12.2175 7.06244 12.0665 6.95109 11.9551C6.83975 11.8438 6.68872 11.7812 6.53125 11.7812H2.96875C2.81128 11.7812 2.66026 11.8438 2.54891 11.9551C2.43756 12.0665 2.375 12.2175 2.375 12.3749V15.9374C2.375 16.0949 2.43756 16.2459 2.54891 16.3573C2.66026 16.4686 2.81128 16.5312 2.96875 16.5312C3.12622 16.5312 3.27724 16.4686 3.38859 16.3573C3.49994 16.2459 3.5625 16.0949 3.5625 15.9374V13.8081L4.64832 14.8939C5.97254 16.2248 7.771 16.9755 9.64844 16.981H9.68777C11.5492 16.9858 13.3377 16.257 14.6656 14.9526C14.7741 14.8416 14.8348 14.6927 14.8348 14.5376C14.8348 14.3824 14.7741 14.2335 14.6657 14.1225C14.5573 14.0116 14.4097 13.9475 14.2546 13.9439C14.0996 13.9404 13.9493 13.9977 13.8359 14.1035H13.8351Z" fill="#552DEA" />
                                            </svg>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Form.Check
                                            type={'checkbox'}
                                            id={`AI-writing-assistant`}
                                            label={'AI writing assistant'}
                                        />
                                    </td>
                                    <td className='text-center'>
                                        <Badge className='fw-medium' data-status="Commercial">Commercial</Badge>
                                    </td>
                                    <td className='text-center'>
                                        60,500
                                    </td>
                                    <td className='text-center'>

                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.2" d="M25.375 14C19.6875 26.1242 16.8438 20.0626 14 14H25.375ZM2.625 14H14C11.1562 7.93731 8.3125 1.87575 2.625 14Z" fill="#552DEA" />
                                            <path d="M26.1673 14.3719C23.761 19.4906 21.6391 21.875 19.4735 21.875C16.7282 21.875 15.0187 18.2306 13.2085 14.3719C11.7801 11.3269 10.1613 7.875 8.53163 7.875C6.73788 7.875 4.56023 11.9448 3.42164 14.3719C3.32033 14.5775 3.14238 14.7352 2.92604 14.811C2.70971 14.8868 2.47227 14.8746 2.26478 14.7772C2.05729 14.6798 1.89632 14.5048 1.81649 14.2899C1.73666 14.075 1.74434 13.8374 1.83789 13.6281C4.23429 8.50937 6.36163 6.125 8.53163 6.125C11.2769 6.125 12.9865 9.76937 14.7966 13.6281C16.2207 16.6731 17.8394 20.125 19.4691 20.125C21.2629 20.125 23.4405 16.0552 24.5791 13.6281C24.6804 13.4225 24.8584 13.2648 25.0747 13.189C25.2911 13.1132 25.5285 13.1254 25.736 13.2228C25.9435 13.3202 26.1044 13.4952 26.1843 13.7101C26.2641 13.925 26.2564 14.1626 26.1629 14.3719H26.1673Z" fill="#552DEA" />
                                        </svg>

                                    </td>
                                    <td className='text-center'>
                                        68
                                    </td>
                                    <td>
                                        <div className='d-flex gap-1 align-items-center'>
                                            Last Week

                                            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.2" d="M16.0313 10C16.0313 11.2918 15.6482 12.5545 14.9305 13.6286C14.2129 14.7026 13.1928 15.5398 11.9994 16.0341C10.806 16.5284 9.49276 16.6578 8.22582 16.4058C6.95888 16.1537 5.79512 15.5317 4.88171 14.6183C3.9683 13.7049 3.34626 12.5411 3.09425 11.2742C2.84224 10.0072 2.97158 8.69403 3.46591 7.5006C3.96025 6.30717 4.79738 5.28713 5.87143 4.56946C6.94549 3.8518 8.20824 3.46875 9.5 3.46875C11.2322 3.46875 12.8934 4.15686 14.1183 5.38171C15.3431 6.60656 16.0313 8.26781 16.0313 10Z" fill="#552DEA" />
                                                <path d="M16.625 4.06245V7.62495C16.625 7.78242 16.5624 7.93344 16.4511 8.04479C16.3397 8.15614 16.1887 8.2187 16.0312 8.2187H12.4688C12.3113 8.2187 12.1603 8.15614 12.0489 8.04479C11.9376 7.93344 11.875 7.78242 11.875 7.62495C11.875 7.46748 11.9376 7.31645 12.0489 7.2051C12.1603 7.09375 12.3113 7.0312 12.4688 7.0312H14.5981L13.5123 5.94538C12.4088 4.83685 10.9105 4.21144 9.34637 4.20643H9.31297C7.76193 4.2028 6.27179 4.80989 5.16488 5.89639C5.05149 6.00224 4.90118 6.05952 4.7461 6.05596C4.59102 6.0524 4.44349 5.9883 4.33507 5.87736C4.22665 5.76642 4.16596 5.61746 4.16597 5.46234C4.16597 5.30722 4.22669 5.15826 4.33512 5.04733C5.67664 3.73618 7.48111 3.00697 9.35693 3.01794C11.2327 3.02892 13.0286 3.7792 14.3546 5.10596L15.4375 6.19178V4.06245C15.4375 3.90498 15.5001 3.75395 15.6114 3.6426C15.7228 3.53125 15.8738 3.4687 16.0312 3.4687C16.1887 3.4687 16.3397 3.53125 16.4511 3.6426C16.5624 3.75395 16.625 3.90498 16.625 4.06245ZM13.8351 14.1035C12.7173 15.1954 11.214 15.8027 9.65136 15.7935C8.08873 15.7843 6.5927 15.1595 5.48773 14.0545L4.40191 12.9687H6.53125C6.68872 12.9687 6.83975 12.9061 6.95109 12.7948C7.06244 12.6834 7.125 12.5324 7.125 12.3749C7.125 12.2175 7.06244 12.0665 6.95109 11.9551C6.83975 11.8438 6.68872 11.7812 6.53125 11.7812H2.96875C2.81128 11.7812 2.66026 11.8438 2.54891 11.9551C2.43756 12.0665 2.375 12.2175 2.375 12.3749V15.9374C2.375 16.0949 2.43756 16.2459 2.54891 16.3573C2.66026 16.4686 2.81128 16.5312 2.96875 16.5312C3.12622 16.5312 3.27724 16.4686 3.38859 16.3573C3.49994 16.2459 3.5625 16.0949 3.5625 15.9374V13.8081L4.64832 14.8939C5.97254 16.2248 7.771 16.9755 9.64844 16.981H9.68777C11.5492 16.9858 13.3377 16.257 14.6656 14.9526C14.7741 14.8416 14.8348 14.6927 14.8348 14.5376C14.8348 14.3824 14.7741 14.2335 14.6657 14.1225C14.5573 14.0116 14.4097 13.9475 14.2546 13.9439C14.0996 13.9404 13.9493 13.9977 13.8359 14.1035H13.8351Z" fill="#552DEA" />
                                            </svg>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Form.Check
                                            type={'checkbox'}
                                            id={`AI-content-generator`}
                                            label={'AI content generator'}
                                        />
                                    </td>
                                    <td className='text-center'>
                                        <Badge className='fw-medium' data-status="Navigational">Navigational</Badge>
                                    </td>
                                    <td className='text-center'>
                                        60,500
                                    </td>
                                    <td className='text-center'>

                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.2" d="M25.375 14C19.6875 26.1242 16.8438 20.0626 14 14H25.375ZM2.625 14H14C11.1562 7.93731 8.3125 1.87575 2.625 14Z" fill="#552DEA" />
                                            <path d="M26.1673 14.3719C23.761 19.4906 21.6391 21.875 19.4735 21.875C16.7282 21.875 15.0187 18.2306 13.2085 14.3719C11.7801 11.3269 10.1613 7.875 8.53163 7.875C6.73788 7.875 4.56023 11.9448 3.42164 14.3719C3.32033 14.5775 3.14238 14.7352 2.92604 14.811C2.70971 14.8868 2.47227 14.8746 2.26478 14.7772C2.05729 14.6798 1.89632 14.5048 1.81649 14.2899C1.73666 14.075 1.74434 13.8374 1.83789 13.6281C4.23429 8.50937 6.36163 6.125 8.53163 6.125C11.2769 6.125 12.9865 9.76937 14.7966 13.6281C16.2207 16.6731 17.8394 20.125 19.4691 20.125C21.2629 20.125 23.4405 16.0552 24.5791 13.6281C24.6804 13.4225 24.8584 13.2648 25.0747 13.189C25.2911 13.1132 25.5285 13.1254 25.736 13.2228C25.9435 13.3202 26.1044 13.4952 26.1843 13.7101C26.2641 13.925 26.2564 14.1626 26.1629 14.3719H26.1673Z" fill="#552DEA" />
                                        </svg>

                                    </td>
                                    <td className='text-center'>
                                        68
                                    </td>
                                    <td>
                                        <div className='d-flex gap-1 align-items-center'>
                                            Last Week

                                            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.2" d="M16.0313 10C16.0313 11.2918 15.6482 12.5545 14.9305 13.6286C14.2129 14.7026 13.1928 15.5398 11.9994 16.0341C10.806 16.5284 9.49276 16.6578 8.22582 16.4058C6.95888 16.1537 5.79512 15.5317 4.88171 14.6183C3.9683 13.7049 3.34626 12.5411 3.09425 11.2742C2.84224 10.0072 2.97158 8.69403 3.46591 7.5006C3.96025 6.30717 4.79738 5.28713 5.87143 4.56946C6.94549 3.8518 8.20824 3.46875 9.5 3.46875C11.2322 3.46875 12.8934 4.15686 14.1183 5.38171C15.3431 6.60656 16.0313 8.26781 16.0313 10Z" fill="#552DEA" />
                                                <path d="M16.625 4.06245V7.62495C16.625 7.78242 16.5624 7.93344 16.4511 8.04479C16.3397 8.15614 16.1887 8.2187 16.0312 8.2187H12.4688C12.3113 8.2187 12.1603 8.15614 12.0489 8.04479C11.9376 7.93344 11.875 7.78242 11.875 7.62495C11.875 7.46748 11.9376 7.31645 12.0489 7.2051C12.1603 7.09375 12.3113 7.0312 12.4688 7.0312H14.5981L13.5123 5.94538C12.4088 4.83685 10.9105 4.21144 9.34637 4.20643H9.31297C7.76193 4.2028 6.27179 4.80989 5.16488 5.89639C5.05149 6.00224 4.90118 6.05952 4.7461 6.05596C4.59102 6.0524 4.44349 5.9883 4.33507 5.87736C4.22665 5.76642 4.16596 5.61746 4.16597 5.46234C4.16597 5.30722 4.22669 5.15826 4.33512 5.04733C5.67664 3.73618 7.48111 3.00697 9.35693 3.01794C11.2327 3.02892 13.0286 3.7792 14.3546 5.10596L15.4375 6.19178V4.06245C15.4375 3.90498 15.5001 3.75395 15.6114 3.6426C15.7228 3.53125 15.8738 3.4687 16.0312 3.4687C16.1887 3.4687 16.3397 3.53125 16.4511 3.6426C16.5624 3.75395 16.625 3.90498 16.625 4.06245ZM13.8351 14.1035C12.7173 15.1954 11.214 15.8027 9.65136 15.7935C8.08873 15.7843 6.5927 15.1595 5.48773 14.0545L4.40191 12.9687H6.53125C6.68872 12.9687 6.83975 12.9061 6.95109 12.7948C7.06244 12.6834 7.125 12.5324 7.125 12.3749C7.125 12.2175 7.06244 12.0665 6.95109 11.9551C6.83975 11.8438 6.68872 11.7812 6.53125 11.7812H2.96875C2.81128 11.7812 2.66026 11.8438 2.54891 11.9551C2.43756 12.0665 2.375 12.2175 2.375 12.3749V15.9374C2.375 16.0949 2.43756 16.2459 2.54891 16.3573C2.66026 16.4686 2.81128 16.5312 2.96875 16.5312C3.12622 16.5312 3.27724 16.4686 3.38859 16.3573C3.49994 16.2459 3.5625 16.0949 3.5625 15.9374V13.8081L4.64832 14.8939C5.97254 16.2248 7.771 16.9755 9.64844 16.981H9.68777C11.5492 16.9858 13.3377 16.257 14.6656 14.9526C14.7741 14.8416 14.8348 14.6927 14.8348 14.5376C14.8348 14.3824 14.7741 14.2335 14.6657 14.1225C14.5573 14.0116 14.4097 13.9475 14.2546 13.9439C14.0996 13.9404 13.9493 13.9977 13.8359 14.1035H13.8351Z" fill="#552DEA" />
                                            </svg>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Form.Check
                                            type={'checkbox'}
                                            id={`AI-writing-assistant`}
                                            label={'AI writing assistant'}
                                        />
                                    </td>
                                    <td className='text-center'>
                                        <Badge className='fw-medium' data-status="Transactional">Transactional</Badge>
                                    </td>
                                    <td className='text-center'>
                                        60,500
                                    </td>
                                    <td className='text-center'>

                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.2" d="M25.375 14C19.6875 26.1242 16.8438 20.0626 14 14H25.375ZM2.625 14H14C11.1562 7.93731 8.3125 1.87575 2.625 14Z" fill="#552DEA" />
                                            <path d="M26.1673 14.3719C23.761 19.4906 21.6391 21.875 19.4735 21.875C16.7282 21.875 15.0187 18.2306 13.2085 14.3719C11.7801 11.3269 10.1613 7.875 8.53163 7.875C6.73788 7.875 4.56023 11.9448 3.42164 14.3719C3.32033 14.5775 3.14238 14.7352 2.92604 14.811C2.70971 14.8868 2.47227 14.8746 2.26478 14.7772C2.05729 14.6798 1.89632 14.5048 1.81649 14.2899C1.73666 14.075 1.74434 13.8374 1.83789 13.6281C4.23429 8.50937 6.36163 6.125 8.53163 6.125C11.2769 6.125 12.9865 9.76937 14.7966 13.6281C16.2207 16.6731 17.8394 20.125 19.4691 20.125C21.2629 20.125 23.4405 16.0552 24.5791 13.6281C24.6804 13.4225 24.8584 13.2648 25.0747 13.189C25.2911 13.1132 25.5285 13.1254 25.736 13.2228C25.9435 13.3202 26.1044 13.4952 26.1843 13.7101C26.2641 13.925 26.2564 14.1626 26.1629 14.3719H26.1673Z" fill="#552DEA" />
                                        </svg>

                                    </td>
                                    <td className='text-center'>
                                        68
                                    </td>
                                    <td>
                                        <div className='d-flex gap-1 align-items-center'>
                                            Last Week

                                            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.2" d="M16.0313 10C16.0313 11.2918 15.6482 12.5545 14.9305 13.6286C14.2129 14.7026 13.1928 15.5398 11.9994 16.0341C10.806 16.5284 9.49276 16.6578 8.22582 16.4058C6.95888 16.1537 5.79512 15.5317 4.88171 14.6183C3.9683 13.7049 3.34626 12.5411 3.09425 11.2742C2.84224 10.0072 2.97158 8.69403 3.46591 7.5006C3.96025 6.30717 4.79738 5.28713 5.87143 4.56946C6.94549 3.8518 8.20824 3.46875 9.5 3.46875C11.2322 3.46875 12.8934 4.15686 14.1183 5.38171C15.3431 6.60656 16.0313 8.26781 16.0313 10Z" fill="#552DEA" />
                                                <path d="M16.625 4.06245V7.62495C16.625 7.78242 16.5624 7.93344 16.4511 8.04479C16.3397 8.15614 16.1887 8.2187 16.0312 8.2187H12.4688C12.3113 8.2187 12.1603 8.15614 12.0489 8.04479C11.9376 7.93344 11.875 7.78242 11.875 7.62495C11.875 7.46748 11.9376 7.31645 12.0489 7.2051C12.1603 7.09375 12.3113 7.0312 12.4688 7.0312H14.5981L13.5123 5.94538C12.4088 4.83685 10.9105 4.21144 9.34637 4.20643H9.31297C7.76193 4.2028 6.27179 4.80989 5.16488 5.89639C5.05149 6.00224 4.90118 6.05952 4.7461 6.05596C4.59102 6.0524 4.44349 5.9883 4.33507 5.87736C4.22665 5.76642 4.16596 5.61746 4.16597 5.46234C4.16597 5.30722 4.22669 5.15826 4.33512 5.04733C5.67664 3.73618 7.48111 3.00697 9.35693 3.01794C11.2327 3.02892 13.0286 3.7792 14.3546 5.10596L15.4375 6.19178V4.06245C15.4375 3.90498 15.5001 3.75395 15.6114 3.6426C15.7228 3.53125 15.8738 3.4687 16.0312 3.4687C16.1887 3.4687 16.3397 3.53125 16.4511 3.6426C16.5624 3.75395 16.625 3.90498 16.625 4.06245ZM13.8351 14.1035C12.7173 15.1954 11.214 15.8027 9.65136 15.7935C8.08873 15.7843 6.5927 15.1595 5.48773 14.0545L4.40191 12.9687H6.53125C6.68872 12.9687 6.83975 12.9061 6.95109 12.7948C7.06244 12.6834 7.125 12.5324 7.125 12.3749C7.125 12.2175 7.06244 12.0665 6.95109 11.9551C6.83975 11.8438 6.68872 11.7812 6.53125 11.7812H2.96875C2.81128 11.7812 2.66026 11.8438 2.54891 11.9551C2.43756 12.0665 2.375 12.2175 2.375 12.3749V15.9374C2.375 16.0949 2.43756 16.2459 2.54891 16.3573C2.66026 16.4686 2.81128 16.5312 2.96875 16.5312C3.12622 16.5312 3.27724 16.4686 3.38859 16.3573C3.49994 16.2459 3.5625 16.0949 3.5625 15.9374V13.8081L4.64832 14.8939C5.97254 16.2248 7.771 16.9755 9.64844 16.981H9.68777C11.5492 16.9858 13.3377 16.257 14.6656 14.9526C14.7741 14.8416 14.8348 14.6927 14.8348 14.5376C14.8348 14.3824 14.7741 14.2335 14.6657 14.1225C14.5573 14.0116 14.4097 13.9475 14.2546 13.9439C14.0996 13.9404 13.9493 13.9977 13.8359 14.1035H13.8351Z" fill="#552DEA" />
                                            </svg>
                                        </div>
                                    </td>
                                </tr> */}
                                        </tbody>
                                    </Table>
                                </div>
                        }
                        <div className='text-center'>
                            <Button type='button' variant='primary' className='d-inline-flex gap-2 px-4'
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.suggestedKeywordModalClose();
                                }}
                            > <span className='icon icon-save'></span>
                                Save</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}