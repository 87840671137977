import React, { useEffect, useReducer, useState } from 'react'
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import { CloseButton } from 'react-bootstrap';
import AddPlus from '../../assets/images/add-plus.png';
import AnErrorOccured from '../AnErrorOccured/AnErrorOccured';
import { EditListData } from '../../provider/ReciptListController';

const initialState = {
    first_name: "",
    last_name: "",
    email: "",
    user_name: "",
}

const formReducer = (state, action) => {
    switch (action.type) {
        case "Handle Input Text":
            const { buttonDisabled, ...inputFields } = state;

            // update the field
            const form = {
                ...inputFields,
                [action.field]: action.value,
            };

            let disabled;

            return {
                ...form,
                buttonDisabled: disabled
            };
        case "Set Initial Data":
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}

export default function EditEmailForReciptModal(props) {

    useEffect(() => {
        if (props.editReciptModal) {
            dispatch({
                type: "Set Initial Data",
                payload: {
                    first_name: props.editReciptModal.first_name || "",
                    last_name: props.editReciptModal.last_name || "",
                    email: props.editReciptModal.email || "",
                    user_name: props.editReciptModal.first_name + " " + props.editReciptModal.last_name || "",
                },
            });
        }
    }, [props.editReciptModal]);

    const [state, dispatch] = useReducer(formReducer, initialState);
    const [disabledButton, setDisabledButton] = useState(false);
    const [tryLater, setTryLater] = useState(false);

    const handleTextChange = e => {
        dispatch({
            type: 'Handle Input Text',
            field: e.target.name,
            value: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent page reload
        setDisabledButton(true);
        try {
            const result = await EditListData(props.themeContext, setTryLater, state, props.editReciptModal.id);

            if (result.success) {
                props.setEditEmailReciptModalShow(false)
                setDisabledButton(false);
                await props.getListData();
            }
        } catch (error) {
            setDisabledButton(false);
        }

    };

    return (
        <div>
            <Modal className='credit-report-modal' show={props.show} centered>
                <Modal.Header>
                    <Modal.Title className='fw-semibold flex-column flex-grow-1 pb-1 gap-2'>
                        <span className="icon card-square-icon icon-lg icon-automate mb-1"></span>
                        Edit Select Menu
                        <CloseButton onClick={() => {
                            props.setEditEmailReciptModalShow(false);
                        }} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="text" name="first_name" placeholder="First Name" value={state.first_name} onChange={handleTextChange} />
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type="text" name="last_name" placeholder="Last Name" value={state.last_name} onChange={handleTextChange} />
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control type="text" name="email" value={state.email} placeholder="Email" onChange={handleTextChange} />
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>User Name</Form.Label>
                                    <Form.Control type="text" name="user_name" value={state.user_name} placeholder="User Name" onChange={handleTextChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className='modal-footer pt-0 px-0 border-top-0 justify-content-between'>
                            {disabledButton ?
                                <Button disabled={true}
                                    className='px-3 mx-auto'
                                    type='submit'>
                                    <span className='icon icon-save'></span>
                                    Save & Close
                                </Button> :
                                <Button
                                    disabled={!(state.first_name.length > 0 &&
                                        state.last_name.length > 0 &&
                                        state.email.length > 0 && state.email.includes("@") &&
                                        state.user_name.length > 0)}
                                    className='px-3 mx-auto'
                                    type='submit'
                                >
                                    <span className='icon icon-save'></span>
                                    Save & Close
                                </Button>
                            }
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            <AnErrorOccured showError={tryLater} setShowError={setTryLater} />
        </div>
    )
}
