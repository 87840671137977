import { useRef, useContext, useState, useEffect } from 'react';
import { getTokenSession } from '../../provider/Auth';
import { ThemeContext } from '../../projectContext/ThemeContext';
import dottedLoaderSmall from './../../assets/images/dottedLoaderSmall.gif';
import { Dropdown, DropdownButton, Container, InputGroup, Form, Row, Col, Button, Table, Stack, Tabs, Tab } from 'react-bootstrap';

export default function GPTToggleWithNumber(props) {

    const ThemeContextData = useContext(ThemeContext);

    const GPTDataDisplay = [{
        "GPT-4o": ["GPT-4o", `<svg className='mb-2' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_3183_404)">
                                            <path d="M6.78587 0C6.9823 0.0119119 7.08349 0.13103 7.16683 0.297796C7.69064 1.34604 8.25016 2.38237 8.72635 3.45444C9.01802 4.1215 9.44659 4.54437 10.1133 4.83621C11.1966 5.31864 12.2442 5.88446 13.3037 6.41453C13.4644 6.49196 13.5775 6.59321 13.5716 6.7838C13.5656 6.96248 13.4585 7.06373 13.3037 7.1352C13.2561 7.15902 13.2144 7.18285 13.1668 7.20667C11.9049 7.838 10.643 8.46337 9.38706 9.10066C9.27992 9.15426 9.17278 9.26147 9.11921 9.36867C8.45849 10.6671 7.80968 11.9714 7.16087 13.2758C7.08945 13.4247 7.00611 13.5497 6.82159 13.5616C6.6073 13.5795 6.50611 13.4485 6.41683 13.2758C5.76802 11.9714 5.11921 10.673 4.45849 9.37463C4.40492 9.26742 4.29778 9.16021 4.19064 9.10661C2.90492 8.45741 1.61921 7.81418 0.327541 7.17094C0.154922 7.08755 -0.00579205 6.99821 0.000160331 6.77189C0.00611271 6.55152 0.160875 6.47409 0.327541 6.39071C1.61326 5.74747 2.90492 5.10423 4.19064 4.45503C4.29778 4.40143 4.40492 4.29422 4.45849 4.18702C5.1073 2.90054 5.75016 1.6081 6.39302 0.315664C6.47635 0.148898 6.57754 0.0119119 6.78587 0Z" fill="#98A2B3" />
                                            <path d="M20.0002 13.9488C19.9942 14.1155 19.8811 14.2168 19.7323 14.2883C18.899 14.6992 18.0716 15.1161 17.2442 15.539C17.1311 15.5986 17.018 15.7117 16.9644 15.8249C16.5478 16.6349 16.143 17.4568 15.7323 18.2728C15.6549 18.4336 15.5597 18.5706 15.3573 18.5706C15.1549 18.5706 15.0597 18.4336 14.9823 18.2728C14.5716 17.4449 14.1668 16.623 13.7442 15.8011C13.6906 15.6939 13.5835 15.5867 13.4704 15.5331C12.649 15.1102 11.8275 14.6992 11.0002 14.2942C10.8394 14.2108 10.7085 14.1215 10.7085 13.913C10.7144 13.7225 10.8394 13.6331 10.9942 13.5557C11.8097 13.1507 12.6311 12.7457 13.4406 12.3228C13.5656 12.2573 13.6847 12.1322 13.7502 12.0071C14.1609 11.215 14.5537 10.4169 14.9466 9.61882C15.0359 9.44014 15.1371 9.28529 15.3514 9.29124C15.5597 9.2972 15.6549 9.4461 15.7383 9.61286C16.1371 10.4169 16.5359 11.2269 16.9466 12.025C17.0061 12.1441 17.1252 12.2573 17.2502 12.3228C18.0656 12.7457 18.893 13.1566 19.7144 13.5616C19.8871 13.645 20.0002 13.7463 20.0002 13.9488Z" fill="#98A2B3" />
                                            <path d="M3.21468 13.4902C3.36349 13.6391 3.5004 13.7225 3.57182 13.8475C3.84563 14.3597 4.08968 14.8839 4.35754 15.3961C4.40516 15.4914 4.5004 15.5867 4.59563 15.6343C5.10754 15.9023 5.6254 16.1584 6.14325 16.4145C6.29801 16.492 6.42301 16.5873 6.42301 16.7778C6.42301 16.9684 6.30397 17.0697 6.14921 17.1471C5.6373 17.3973 5.1254 17.6534 4.61944 17.9154C4.5123 17.975 4.40516 18.0762 4.35159 18.1834C4.08373 18.6897 3.83373 19.2019 3.57778 19.7141C3.5004 19.869 3.40516 19.994 3.21468 19.994C3.0242 19.994 2.92897 19.8749 2.85159 19.7201C2.60159 19.2079 2.34563 18.6957 2.08373 18.1894C2.0242 18.0822 1.92301 17.975 1.81587 17.9154C1.30992 17.6474 0.798014 17.3973 0.28611 17.1471C0.131348 17.0697 0.00634766 16.9803 0.00634766 16.7838C0.00634766 16.5932 0.125395 16.4979 0.280157 16.4205C0.798014 16.1644 1.31587 15.9083 1.83373 15.6403C1.92897 15.5926 2.0242 15.4973 2.07182 15.402C2.33968 14.8898 2.58373 14.3657 2.86349 13.8535C2.92301 13.7284 3.06587 13.6391 3.21468 13.4902Z" fill="#98A2B3" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_3183_404">
                                                <rect width="20" height="20" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>`],
        "GPT-4o mini": ["GPT-4o mini", `
<svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.82811 19.9951C5.41147 19.8242 5.32293 19.5069 5.32293 19.1115C5.33335 16.9781 5.32814 14.8398 5.33335 12.7064C5.33335 12.5355 5.29689 12.4916 5.1094 12.4916C3.65114 12.5014 2.19289 12.4965 0.73463 12.4965C0.344025 12.4965 0.0732061 12.3012 0.0107094 11.979C-0.0257471 11.8081 0.0367497 11.6617 0.109663 11.5103C1.06274 9.49897 2.01581 7.48273 2.9741 5.47138C3.76051 3.80664 4.55214 2.14679 5.33855 0.48205C5.54167 0.0475585 5.90623 -0.108663 6.30205 0.0768501C6.57807 0.20378 6.66661 0.433231 6.66661 0.706619C6.66661 1.50237 6.66661 2.29325 6.66661 3.089C6.66661 4.48035 6.67182 5.87658 6.6614 7.26793C6.6614 7.44856 6.69265 7.50714 6.90618 7.50714C8.35923 7.49738 9.80707 7.50226 11.2601 7.50226C11.6559 7.50226 11.9163 7.69266 11.9892 8.01975C12.0257 8.19061 11.9632 8.34195 11.8955 8.48841C11.0101 10.3533 10.13 12.2182 9.2446 14.0831C8.4061 15.8504 7.5624 17.6225 6.72911 19.3898C6.60411 19.6534 6.4687 19.8877 6.17184 20C6.04685 19.9951 5.93748 19.9951 5.82811 19.9951Z" fill="#98A2B3"/>
</svg>
`],
        "GEMINI-FLASH": ["GEMINI-FLASH", `
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 8.016C13.9242 8.14339 11.9666 9.02545 10.496 10.496C9.02545 11.9666 8.14339 13.9242 8.016 16H7.984C7.85682 13.9241 6.97483 11.9664 5.5042 10.4958C4.03358 9.02518 2.07588 8.14318 0 8.016L0 7.984C2.07588 7.85682 4.03358 6.97483 5.5042 5.5042C6.97483 4.03358 7.85682 2.07588 7.984 0L8.016 0C8.14339 2.07581 9.02545 4.03339 10.496 5.50397C11.9666 6.97455 13.9242 7.85661 16 7.984V8.016Z" fill="#98A2B3"/>
</svg>
`],
        "GEMINI-PRO": ["GEMINI-PRO", `
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 8.016C13.9242 8.14339 11.9666 9.02545 10.496 10.496C9.02545 11.9666 8.14339 13.9242 8.016 16H7.984C7.85682 13.9241 6.97483 11.9664 5.5042 10.4958C4.03358 9.02518 2.07588 8.14318 0 8.016L0 7.984C2.07588 7.85682 4.03358 6.97483 5.5042 5.5042C6.97483 4.03358 7.85682 2.07588 7.984 0L8.016 0C8.14339 2.07581 9.02545 4.03339 10.496 5.50397C11.9666 6.97455 13.9242 7.85661 16 7.984V8.016Z" fill="#98A2B3"/>
</svg>
`],
    }];

    return (
        <>
            <div className='gptToggle-card'>
                {/* only GPT Version visible No toggle Available */}
                {props.chatVersion ?
                    <div className='gptToggle-card chat d-flex'>
                        <div className='gptToggle-card-top'>
                            {props.getGPTVersion === "GPT-3.5" ?
                                <>
                                    <div dangerouslySetInnerHTML={{ __html: GPTDataDisplay[0]['GPT-4o mini'][1] }} />
                                    <span className='GPT-version mt-2 fw-medium'>{GPTDataDisplay[0]['GPT-4o mini'][0]}</span>
                                </> : props.getGPTVersion === "GPT-4" ? <>
                                    <div dangerouslySetInnerHTML={{ __html: GPTDataDisplay[0]['GPT-4o'][1] }} />
                                    <span className='GPT-version mt-2 fw-medium'>{GPTDataDisplay[0]['GPT-4o'][0]}</span>
                                </> : props.getGPTVersion === "GEMINI-FLASH" ? <>
                                    <div dangerouslySetInnerHTML={{ __html: GPTDataDisplay[0]['GEMINI-FLASH'][1] }} />
                                    <span className='GPT-version mt-2 fw-medium'>{GPTDataDisplay[0]['GEMINI-FLASH'][0]}</span>
                                </> : props.getGPTVersion === "GEMINI-PRO" ? <>
                                    <div dangerouslySetInnerHTML={{ __html: GPTDataDisplay[0]['GEMINI-PRO'][1] }} />
                                    <span className='GPT-version mt-2 fw-medium'>{GPTDataDisplay[0]['GEMINI-PRO'][0]}</span>
                                </> : ""}
                        </div>
                        <div className='gptToggle-card-bottom'>
                            <div className='d-flex justify-content-between'>
                                <span className='fw-medium'>Words Left</span>
                                {props.credits === "" ? <img src={dottedLoaderSmall} className='img-fluid smallDottedLoader' alt='' /> : <span className='fw-medium'>{props.credits.articles_total}</span>}
                            </div>
                            <div className="progress-bar position-relative flex-grow-1">
                                <span className="progress-active-bar fw-medium"
                                    style={{ width: props.credits.articles_total * 100 / props.credits.month_articles + "%" }}
                                ></span>
                            </div>
                        </div>
                    </div> :
                    // only Toggle No Credits
                    props.onlyToggle ?
                        <Dropdown className='dropDown'>
                            <Dropdown.Toggle id="statusCategory0">
                                {props.getGPTVersion === "GPT-3.5" ?
                                    <>
                                        <div dangerouslySetInnerHTML={{ __html: GPTDataDisplay[0]['GPT-4o mini'][1] }} />
                                        {GPTDataDisplay[0]['GPT-4o mini'][0]}
                                    </> : props.getGPTVersion === "GPT-4" ? <>
                                        <div dangerouslySetInnerHTML={{ __html: GPTDataDisplay[0]['GPT-4o'][1] }} />
                                        {GPTDataDisplay[0]['GPT-4o'][0]}
                                    </> : props.getGPTVersion === "GEMINI-FLASH" ? <>
                                        <div dangerouslySetInnerHTML={{ __html: GPTDataDisplay[0]['GEMINI-FLASH'][1] }} />
                                        {GPTDataDisplay[0]['GEMINI-FLASH'][0]}
                                    </> : props.getGPTVersion === "GEMINI-PRO" ? <>
                                        <div dangerouslySetInnerHTML={{ __html: GPTDataDisplay[0]['GEMINI-PRO'][1] }} />
                                        {GPTDataDisplay[0]['GEMINI-PRO'][0]}
                                    </> : ""}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={(e) => props.getGPTVersionFromChild("GPT-3.5")}>
                                    <div dangerouslySetInnerHTML={{ __html: GPTDataDisplay[0]['GPT-4o mini'][1] }} />
                                    {GPTDataDisplay[0]['GPT-4o mini'][0]}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={(e) => props.getGPTVersionFromChild("GPT-4")}>
                                    <div dangerouslySetInnerHTML={{ __html: GPTDataDisplay[0]['GPT-4o'][1] }} />
                                    {GPTDataDisplay[0]['GPT-4o'][0]}
                                </Dropdown.Item>
                                <Dropdown.Item disabled={props.disabled ? true : false} onClick={(e) => props.getGPTVersionFromChild("GEMINI-FLASH")}>
                                    <div dangerouslySetInnerHTML={{ __html: GPTDataDisplay[0]['GEMINI-FLASH'][1] }} />
                                    {GPTDataDisplay[0]['GEMINI-FLASH'][0]}
                                </Dropdown.Item>
                                <Dropdown.Item disabled={props.disabled ? true : false} onClick={(e) => props.getGPTVersionFromChild("GEMINI-PRO")}>
                                    <div dangerouslySetInnerHTML={{ __html: GPTDataDisplay[0]['GEMINI-PRO'][1] }} />
                                    {GPTDataDisplay[0]['GEMINI-PRO'][0]}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> :
                        props.voiceVersion ?
                            // Voiceme Toggle
                            <>
                                <div className='gptToggle-card-top old-elements toggle-only d-flex'>
                                    <Button className={props.getTTSVersion === "TTS-1" ? 'flex-grow-1 flex-shrink-0 gap-2 active' : 'flex-grow-1 flex-shrink-0 gap-2'} onClick={() => { props.getTTSVersionFromChild("TTS-1") }}>
                                        <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_3183_413)">
                                                <path d="M5.46315 19.9951C5.0726 19.8242 4.98961 19.5069 4.98961 19.1115C4.99937 16.9781 4.99449 14.8398 4.99937 12.7064C4.99937 12.5355 4.9652 12.4916 4.78945 12.4916C3.42251 12.5014 2.05557 12.4965 0.688627 12.4965C0.322482 12.4965 0.0686219 12.3012 0.0100387 11.979C-0.0241348 11.8081 0.0344485 11.6617 0.102795 11.5103C0.996188 9.49897 1.88958 7.48274 2.78786 5.47138C3.52503 3.80664 4.26708 2.14679 5.00425 0.48205C5.19465 0.0475585 5.53638 -0.108663 5.90741 0.0768501C6.16615 0.20378 6.24914 0.433231 6.24914 0.706619C6.24914 1.50237 6.24914 2.29325 6.24914 3.089C6.24914 4.48035 6.25402 5.87658 6.24426 7.26793C6.24426 7.44856 6.27355 7.50714 6.47371 7.50714C7.83577 7.49738 9.19295 7.50226 10.555 7.50226C10.926 7.50226 11.1701 7.69266 11.2385 8.01975C11.2726 8.19061 11.2141 8.34195 11.1506 8.48841C10.3207 10.3533 9.49562 12.2182 8.6657 14.0831C7.87971 15.8504 7.08883 17.6225 6.30773 19.3898C6.19056 19.6534 6.06363 19.8877 5.78536 20C5.66819 19.9951 5.56567 19.9951 5.46315 19.9951Z" fill="#FDC114" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_3183_413">
                                                    <rect width="12" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        TTS-1</Button>
                                    <Button className={props.getTTSVersion === "TTS-1-HD" ? 'flex-grow-1 flex-shrink-0 gap-2 active' : 'flex-grow-1 flex-shrink-0 gap-2'} onClick={() => { props.getTTSVersionFromChild("TTS-1-HD") }}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_3183_404)">
                                                <path d="M6.78587 0C6.9823 0.0119119 7.08349 0.13103 7.16683 0.297796C7.69064 1.34604 8.25016 2.38237 8.72635 3.45444C9.01802 4.1215 9.44659 4.54437 10.1133 4.83621C11.1966 5.31864 12.2442 5.88446 13.3037 6.41453C13.4644 6.49196 13.5775 6.59321 13.5716 6.7838C13.5656 6.96248 13.4585 7.06373 13.3037 7.1352C13.2561 7.15902 13.2144 7.18285 13.1668 7.20667C11.9049 7.838 10.643 8.46337 9.38706 9.10066C9.27992 9.15426 9.17278 9.26147 9.11921 9.36867C8.45849 10.6671 7.80968 11.9714 7.16087 13.2758C7.08945 13.4247 7.00611 13.5497 6.82159 13.5616C6.6073 13.5795 6.50611 13.4485 6.41683 13.2758C5.76802 11.9714 5.11921 10.673 4.45849 9.37463C4.40492 9.26742 4.29778 9.16021 4.19064 9.10661C2.90492 8.45741 1.61921 7.81418 0.327541 7.17094C0.154922 7.08755 -0.00579205 6.99821 0.000160331 6.77189C0.00611271 6.55152 0.160875 6.47409 0.327541 6.39071C1.61326 5.74747 2.90492 5.10423 4.19064 4.45503C4.29778 4.40143 4.40492 4.29422 4.45849 4.18702C5.1073 2.90054 5.75016 1.6081 6.39302 0.315664C6.47635 0.148898 6.57754 0.0119119 6.78587 0Z" fill="#98A2B3" />
                                                <path d="M20.0002 13.9488C19.9942 14.1155 19.8811 14.2168 19.7323 14.2883C18.899 14.6992 18.0716 15.1161 17.2442 15.539C17.1311 15.5986 17.018 15.7117 16.9644 15.8249C16.5478 16.6349 16.143 17.4568 15.7323 18.2728C15.6549 18.4336 15.5597 18.5706 15.3573 18.5706C15.1549 18.5706 15.0597 18.4336 14.9823 18.2728C14.5716 17.4449 14.1668 16.623 13.7442 15.8011C13.6906 15.6939 13.5835 15.5867 13.4704 15.5331C12.649 15.1102 11.8275 14.6992 11.0002 14.2942C10.8394 14.2108 10.7085 14.1215 10.7085 13.913C10.7144 13.7225 10.8394 13.6331 10.9942 13.5557C11.8097 13.1507 12.6311 12.7457 13.4406 12.3228C13.5656 12.2573 13.6847 12.1322 13.7502 12.0071C14.1609 11.215 14.5537 10.4169 14.9466 9.61882C15.0359 9.44014 15.1371 9.28529 15.3514 9.29124C15.5597 9.2972 15.6549 9.4461 15.7383 9.61286C16.1371 10.4169 16.5359 11.2269 16.9466 12.025C17.0061 12.1441 17.1252 12.2573 17.2502 12.3228C18.0656 12.7457 18.893 13.1566 19.7144 13.5616C19.8871 13.645 20.0002 13.7463 20.0002 13.9488Z" fill="#98A2B3" />
                                                <path d="M3.21468 13.4902C3.36349 13.6391 3.5004 13.7225 3.57182 13.8475C3.84563 14.3597 4.08968 14.8839 4.35754 15.3961C4.40516 15.4914 4.5004 15.5867 4.59563 15.6343C5.10754 15.9023 5.6254 16.1584 6.14325 16.4145C6.29801 16.492 6.42301 16.5873 6.42301 16.7778C6.42301 16.9684 6.30397 17.0697 6.14921 17.1471C5.6373 17.3973 5.1254 17.6534 4.61944 17.9154C4.5123 17.975 4.40516 18.0762 4.35159 18.1834C4.08373 18.6897 3.83373 19.2019 3.57778 19.7141C3.5004 19.869 3.40516 19.994 3.21468 19.994C3.0242 19.994 2.92897 19.8749 2.85159 19.7201C2.60159 19.2079 2.34563 18.6957 2.08373 18.1894C2.0242 18.0822 1.92301 17.975 1.81587 17.9154C1.30992 17.6474 0.798014 17.3973 0.28611 17.1471C0.131348 17.0697 0.00634766 16.9803 0.00634766 16.7838C0.00634766 16.5932 0.125395 16.4979 0.280157 16.4205C0.798014 16.1644 1.31587 15.9083 1.83373 15.6403C1.92897 15.5926 2.0242 15.4973 2.07182 15.402C2.33968 14.8898 2.58373 14.3657 2.86349 13.8535C2.92301 13.7284 3.06587 13.6391 3.21468 13.4902Z" fill="#98A2B3" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_3183_404">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        TTS-1 HD</Button>
                                </div>
                                <div className='gptToggle-card-bottom d-flex align-items-center gap-2'>
                                    <span>{props.credits.seconds_total} seconds left</span>
                                    <div className="progress-bar position-relative flex-grow-1">
                                        <span className="progress-active-bar"
                                            style={{ width: props.credits.seconds_total * 100 / props.credits.month_seconds + "%" }}
                                        ></span>
                                    </div>
                                </div>
                            </> :
                            props.dalle ? <>
                                <div className='gptToggle-card-top old-elements d-flex'>
                                    <Button className={
                                        props.getGPTVersion === "dall-e-2" ? 'flex-grow-1 flex-shrink-0 gap-2 active'
                                            : 'flex-grow-1 flex-shrink-0 gap-2'
                                    }
                                        onClick={() => {
                                            props.getGPTVersionFromChild("dall-e-2")
                                        }}>
                                        <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_3183_413)">
                                                <path d="M5.46315 19.9951C5.0726 19.8242 4.98961 19.5069 4.98961 19.1115C4.99937 16.9781 4.99449 14.8398 4.99937 12.7064C4.99937 12.5355 4.9652 12.4916 4.78945 12.4916C3.42251 12.5014 2.05557 12.4965 0.688627 12.4965C0.322482 12.4965 0.0686219 12.3012 0.0100387 11.979C-0.0241348 11.8081 0.0344485 11.6617 0.102795 11.5103C0.996188 9.49897 1.88958 7.48274 2.78786 5.47138C3.52503 3.80664 4.26708 2.14679 5.00425 0.48205C5.19465 0.0475585 5.53638 -0.108663 5.90741 0.0768501C6.16615 0.20378 6.24914 0.433231 6.24914 0.706619C6.24914 1.50237 6.24914 2.29325 6.24914 3.089C6.24914 4.48035 6.25402 5.87658 6.24426 7.26793C6.24426 7.44856 6.27355 7.50714 6.47371 7.50714C7.83577 7.49738 9.19295 7.50226 10.555 7.50226C10.926 7.50226 11.1701 7.69266 11.2385 8.01975C11.2726 8.19061 11.2141 8.34195 11.1506 8.48841C10.3207 10.3533 9.49562 12.2182 8.6657 14.0831C7.87971 15.8504 7.08883 17.6225 6.30773 19.3898C6.19056 19.6534 6.06363 19.8877 5.78536 20C5.66819 19.9951 5.56567 19.9951 5.46315 19.9951Z" fill="#FDC114" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_3183_413">
                                                    <rect width="12" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        DALL-E 2

                                    </Button>
                                    <Button className={
                                        props.getGPTVersion === "dall-e-3" ? 'flex-grow-1 flex-shrink-0 gap-2 active'
                                                : 'flex-grow-1 flex-shrink-0 gap-2'}
                                        onClick={() => {
                                            props.getGPTVersionFromChild("dall-e-3")
                                                
                                        }}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_3183_404)">
                                                <path d="M6.78587 0C6.9823 0.0119119 7.08349 0.13103 7.16683 0.297796C7.69064 1.34604 8.25016 2.38237 8.72635 3.45444C9.01802 4.1215 9.44659 4.54437 10.1133 4.83621C11.1966 5.31864 12.2442 5.88446 13.3037 6.41453C13.4644 6.49196 13.5775 6.59321 13.5716 6.7838C13.5656 6.96248 13.4585 7.06373 13.3037 7.1352C13.2561 7.15902 13.2144 7.18285 13.1668 7.20667C11.9049 7.838 10.643 8.46337 9.38706 9.10066C9.27992 9.15426 9.17278 9.26147 9.11921 9.36867C8.45849 10.6671 7.80968 11.9714 7.16087 13.2758C7.08945 13.4247 7.00611 13.5497 6.82159 13.5616C6.6073 13.5795 6.50611 13.4485 6.41683 13.2758C5.76802 11.9714 5.11921 10.673 4.45849 9.37463C4.40492 9.26742 4.29778 9.16021 4.19064 9.10661C2.90492 8.45741 1.61921 7.81418 0.327541 7.17094C0.154922 7.08755 -0.00579205 6.99821 0.000160331 6.77189C0.00611271 6.55152 0.160875 6.47409 0.327541 6.39071C1.61326 5.74747 2.90492 5.10423 4.19064 4.45503C4.29778 4.40143 4.40492 4.29422 4.45849 4.18702C5.1073 2.90054 5.75016 1.6081 6.39302 0.315664C6.47635 0.148898 6.57754 0.0119119 6.78587 0Z" fill="#98A2B3" />
                                                <path d="M20.0002 13.9488C19.9942 14.1155 19.8811 14.2168 19.7323 14.2883C18.899 14.6992 18.0716 15.1161 17.2442 15.539C17.1311 15.5986 17.018 15.7117 16.9644 15.8249C16.5478 16.6349 16.143 17.4568 15.7323 18.2728C15.6549 18.4336 15.5597 18.5706 15.3573 18.5706C15.1549 18.5706 15.0597 18.4336 14.9823 18.2728C14.5716 17.4449 14.1668 16.623 13.7442 15.8011C13.6906 15.6939 13.5835 15.5867 13.4704 15.5331C12.649 15.1102 11.8275 14.6992 11.0002 14.2942C10.8394 14.2108 10.7085 14.1215 10.7085 13.913C10.7144 13.7225 10.8394 13.6331 10.9942 13.5557C11.8097 13.1507 12.6311 12.7457 13.4406 12.3228C13.5656 12.2573 13.6847 12.1322 13.7502 12.0071C14.1609 11.215 14.5537 10.4169 14.9466 9.61882C15.0359 9.44014 15.1371 9.28529 15.3514 9.29124C15.5597 9.2972 15.6549 9.4461 15.7383 9.61286C16.1371 10.4169 16.5359 11.2269 16.9466 12.025C17.0061 12.1441 17.1252 12.2573 17.2502 12.3228C18.0656 12.7457 18.893 13.1566 19.7144 13.5616C19.8871 13.645 20.0002 13.7463 20.0002 13.9488Z" fill="#98A2B3" />
                                                <path d="M3.21468 13.4902C3.36349 13.6391 3.5004 13.7225 3.57182 13.8475C3.84563 14.3597 4.08968 14.8839 4.35754 15.3961C4.40516 15.4914 4.5004 15.5867 4.59563 15.6343C5.10754 15.9023 5.6254 16.1584 6.14325 16.4145C6.29801 16.492 6.42301 16.5873 6.42301 16.7778C6.42301 16.9684 6.30397 17.0697 6.14921 17.1471C5.6373 17.3973 5.1254 17.6534 4.61944 17.9154C4.5123 17.975 4.40516 18.0762 4.35159 18.1834C4.08373 18.6897 3.83373 19.2019 3.57778 19.7141C3.5004 19.869 3.40516 19.994 3.21468 19.994C3.0242 19.994 2.92897 19.8749 2.85159 19.7201C2.60159 19.2079 2.34563 18.6957 2.08373 18.1894C2.0242 18.0822 1.92301 17.975 1.81587 17.9154C1.30992 17.6474 0.798014 17.3973 0.28611 17.1471C0.131348 17.0697 0.00634766 16.9803 0.00634766 16.7838C0.00634766 16.5932 0.125395 16.4979 0.280157 16.4205C0.798014 16.1644 1.31587 15.9083 1.83373 15.6403C1.92897 15.5926 2.0242 15.4973 2.07182 15.402C2.33968 14.8898 2.58373 14.3657 2.86349 13.8535C2.92301 13.7284 3.06587 13.6391 3.21468 13.4902Z" fill="#98A2B3" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_3183_404">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        DALL-E 3</Button>
                                </div>
                                <div className='gptToggle-card-bottom d-flex align-items-center gap-2'>
                                    <span>{props?.dalle ? props.credits.images_total : props.credits.articles_total} Images Left</span>
                                    <div className="progress-bar position-relative flex-grow-1">
                                        <span className="progress-active-bar"
                                            style={
                                                { width: props.credits.images_total * 100 / props.credits.month_images + "%" }
                                            }
                                        ></span>
                                    </div>
                                </div>
                            </> :
                                // GPT Toggle and watch credits
                                <>
                                    <div className='gptToggle-card-top d-flex'>
                                        <Dropdown className='dropDown'>
                                            <Dropdown.Toggle id="statusCategory0">
                                                {props.getGPTVersion === "GPT-3.5" ?
                                                    <>
                                                        <div dangerouslySetInnerHTML={{ __html: GPTDataDisplay[0]['GPT-4o mini'][1] }} />
                                                        {GPTDataDisplay[0]['GPT-4o mini'][0]}
                                                    </> : props.getGPTVersion === "GPT-4" ? <>
                                                        <div dangerouslySetInnerHTML={{ __html: GPTDataDisplay[0]['GPT-4o'][1] }} />
                                                        {GPTDataDisplay[0]['GPT-4o'][0]}
                                                    </> : props.getGPTVersion === "GEMINI-FLASH" ? <>
                                                        <div dangerouslySetInnerHTML={{ __html: GPTDataDisplay[0]['GEMINI-FLASH'][1] }} />
                                                        {GPTDataDisplay[0]['GEMINI-FLASH'][0]}
                                                    </> : props.getGPTVersion === "GEMINI-PRO" ? <>
                                                        <div dangerouslySetInnerHTML={{ __html: GPTDataDisplay[0]['GEMINI-PRO'][1] }} />
                                                        {GPTDataDisplay[0]['GEMINI-PRO'][0]}
                                                    </> : ""}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={(e) => props.getGPTVersionFromChild("GPT-3.5")}>
                                                    <div dangerouslySetInnerHTML={{ __html: GPTDataDisplay[0]['GPT-4o mini'][1] }} />
                                                    {GPTDataDisplay[0]['GPT-4o mini'][0]}
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={(e) => props.getGPTVersionFromChild("GPT-4")}>
                                                    <div dangerouslySetInnerHTML={{ __html: GPTDataDisplay[0]['GPT-4o'][1] }} />
                                                    {GPTDataDisplay[0]['GPT-4o'][0]}
                                                </Dropdown.Item>
                                                <Dropdown.Item disabled={props.disabled ? true : false} onClick={(e) => props.getGPTVersionFromChild("GEMINI-FLASH")}>
                                                    <div dangerouslySetInnerHTML={{ __html: GPTDataDisplay[0]['GEMINI-FLASH'][1] }} />
                                                    {GPTDataDisplay[0]['GEMINI-FLASH'][0]}
                                                </Dropdown.Item>
                                                <Dropdown.Item disabled={props.disabled ? true : false} onClick={(e) => props.getGPTVersionFromChild("GEMINI-PRO")}>
                                                    <div dangerouslySetInnerHTML={{ __html: GPTDataDisplay[0]['GEMINI-PRO'][1] }} />
                                                    {GPTDataDisplay[0]['GEMINI-PRO'][0]}
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className='gptToggle-card-bottom d-flex align-items-center gap-2'>
                                        <span>{props?.dalle ? props.credits.images_total : props.credits.articles_total} {props?.dalle ? "Images" : "Words"} Left</span>
                                        <div className="progress-bar position-relative flex-grow-1">
                                            <span className="progress-active-bar"
                                                style={props?.dalle ?
                                                    { width: props.credits.images_total * 100 / props.credits.month_images + "%" } :
                                                    { width: props.credits.articles_total * 100 / props.credits.month_articles + "%" }
                                                }
                                            ></span>
                                        </div>
                                    </div>
                                </>
                }
            </div>
        </>
    )
}