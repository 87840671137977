import React, { useState, useContext, useEffect } from 'react';
import { Table, Modal, Button, Form, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import dottedLoaderSmall from './../../assets/images/dottedLoaderSmall.gif';
import { ThemeContext } from "../../projectContext/ThemeContext";
import { CloseButton } from 'react-bootstrap';


export default function SeedKeywordExpandModal(props) {

    const ThemeContextData = useContext(ThemeContext);
    const [errorLimit, setErrorLimit] = useState(false);

    useEffect(() => {
        setErrorLimit(false);
    }, [props.show])

    const handleCheckboxChange = (e) => {
        document.getElementById('Uncheck-all').checked = false;

        if (e.checked) {
            props.setCheckList(prevList => [...prevList, { url: e.url, title: e.title }]);
        } else {
            props.setCheckList(prevList => prevList.filter(item => item.url !== e.url));
        }
    }

    return (
        <>
            <Modal className='customModal seedKeywordModal' show={props.show} centered >
                <Modal.Header className='flex-wrap justify-content-center'>
                    <div>
                        <Modal.Title className='justify-content-center'>
                            SERP Results
                            <CloseButton onClick={() => props.expandKeywordModalClose()} />
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body className='p-0'>
                    <div className='px-3 pt-3 pb-4'>
                        {props.loader ?
                            <div className='py-5 d-flex justify-content-center'>
                                <img src={dottedLoaderSmall} alt='dotted' className='img-fluid' style={{ width: '64px', height: '64px' }} />
                            </div> :
                            props.seedKeywordModalResponse ?
                                <>
                                    <div className='header-check-group'>
                                        <Form.Check
                                            type={'checkbox'}
                                            id={`Uncheck-all`}
                                            label={'Uncheck All'}
                                            className={"d-flex align-items-center mb-2"}
                                            onChange={() => {
                                                // document.getElementById('Check-All').checked = false;
                                                props.setCheckList([])
                                            }}
                                        />
                                        <p>Select up to 5 top SERP results to use as a benchmark</p>
                                    </div>
                                    <div className='table-responsive scroll'>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th className='Select'>Select</th>
                                                    <th className='google-rank text-center'>Google Rank</th>
                                                    <th className='content-score text-center'>Content Score</th>
                                                    <th className='word-length text-center'>Title (Words)</th>
                                                    <th className='title'>Page Title</th>
                                                    <th className='url'>URL</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props.seedKeywordAPIResponse.map(item =>
                                                    <tr>
                                                        <td className='Select'>
                                                            <Form.Check
                                                                type={'checkbox'}
                                                                onChange={(e) => handleCheckboxChange({ title: item.title, url: item.url, checked: e.target.checked })}
                                                                checked={props.checkList.some(checkItem => checkItem.url === item.url)}
                                                                id={item.url}
                                                            />
                                                        </td>
                                                        <td className='google-rank text-center'>{item.rank_absolute}</td>
                                                        <td className='content-score text-center'>
                                                            <Badge variant="primary">96</Badge>
                                                        </td>
                                                        <td className='word-length text-center'>{item.title && item.title.length ? item.title.length : ""}</td>
                                                        <td className='title'>{item.title}</td>
                                                        <td className='url'><Link target="_blank" to={item.url}>{item.url}</Link></td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>

                                    <div className='text-center position-relative'>
                                        {props.checkList.length > 5 ? <div className='limit-error'>! Max selection limit exceeded </div> : ""}
                                        <Button type='button' variant='primary' className='d-inline-flex gap-2 px-4'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                props.expandKeywordModalClose();
                                            }}
                                            disabled={props.checkList.length > 5 ? true : false}
                                        >
                                            <span className='icon icon-save'></span>
                                            Save</Button>
                                    </div>
                                </> :
                                <div className='py-5 d-flex flex-column align-items-center'>
                                    <span className='mb-4'>
                                        <svg width="83" height="83" viewBox="0 0 83 83" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_816_816)">
                                                <path d="M79.1094 2.59375H33.7188V1.29688C33.7188 0.952922 33.5821 0.623057 33.3389 0.379846C33.0957 0.136635 32.7658 0 32.4219 0L19.4531 0C19.1092 0 18.7793 0.136635 18.5361 0.379846C18.2929 0.623057 18.1562 0.952922 18.1562 1.29688V2.59375H3.89062C3.54667 2.59375 3.21681 2.73038 2.9736 2.9736C2.73038 3.21681 2.59375 3.54667 2.59375 3.89062V60.9531C2.59375 61.2971 2.73038 61.6269 2.9736 61.8702C3.21681 62.1134 3.54667 62.25 3.89062 62.25H79.1094C79.4533 62.25 79.7832 62.1134 80.0264 61.8702C80.2696 61.6269 80.4062 61.2971 80.4062 60.9531V3.89062C80.4062 3.54667 80.2696 3.21681 80.0264 2.9736C79.7832 2.73038 79.4533 2.59375 79.1094 2.59375Z" fill="#E6ECFF" />
                                                <path d="M33.7188 2.59375H49.2812V12.9688H33.7188V2.59375Z" fill="#4294FF" />
                                                <path d="M79.1094 2.59375H64.8438V12.9688H80.4062V3.89062C80.4062 3.54667 80.2696 3.21681 80.0264 2.9736C79.7832 2.73038 79.4533 2.59375 79.1094 2.59375Z" fill="#4294FF" />
                                                <path d="M49.2812 2.59375H64.8438V12.9688H49.2812V2.59375Z" fill="#376CFB" />
                                                <path d="M18.1562 2.59375V12.9688H2.59375V3.89062C2.59375 3.54667 2.73038 3.21681 2.9736 2.9736C3.21681 2.73038 3.54667 2.59375 3.89062 2.59375H18.1562Z" fill="#4294FF" />
                                                <path d="M12.9688 9.07812H7.78125C7.4373 9.07812 7.10743 8.94149 6.86422 8.69828C6.62101 8.45507 6.48438 8.1252 6.48438 7.78125C6.48438 7.4373 6.62101 7.10743 6.86422 6.86422C7.10743 6.62101 7.4373 6.48438 7.78125 6.48438H12.9688C13.3127 6.48438 13.6426 6.62101 13.8858 6.86422C14.129 7.10743 14.2656 7.4373 14.2656 7.78125C14.2656 8.1252 14.129 8.45507 13.8858 8.69828C13.6426 8.94149 13.3127 9.07812 12.9688 9.07812Z" fill="#376CFB" />
                                                <path d="M28.5312 7.78125H23.3438C22.9998 7.78125 22.6699 7.64462 22.4267 7.4014C22.1835 7.15819 22.0469 6.82833 22.0469 6.48438C22.0469 6.14042 22.1835 5.81056 22.4267 5.56735C22.6699 5.32413 22.9998 5.1875 23.3438 5.1875H28.5312C28.8752 5.1875 29.2051 5.32413 29.4483 5.56735C29.6915 5.81056 29.8281 6.14042 29.8281 6.48438C29.8281 6.82833 29.6915 7.15819 29.4483 7.4014C29.2051 7.64462 28.8752 7.78125 28.5312 7.78125Z" fill="#4294FF" />
                                                <path d="M44.0938 9.07812H38.9062C38.5623 9.07812 38.2324 8.94149 37.9892 8.69828C37.746 8.45507 37.6094 8.1252 37.6094 7.78125C37.6094 7.4373 37.746 7.10743 37.9892 6.86422C38.2324 6.62101 38.5623 6.48438 38.9062 6.48438H44.0938C44.4377 6.48438 44.7676 6.62101 45.0108 6.86422C45.254 7.10743 45.3906 7.4373 45.3906 7.78125C45.3906 8.1252 45.254 8.45507 45.0108 8.69828C44.7676 8.94149 44.4377 9.07812 44.0938 9.07812Z" fill="#376CFB" />
                                                <path d="M59.6562 9.07812H54.4688C54.1248 9.07812 53.7949 8.94149 53.5517 8.69828C53.3085 8.45507 53.1719 8.1252 53.1719 7.78125C53.1719 7.4373 53.3085 7.10743 53.5517 6.86422C53.7949 6.62101 54.1248 6.48438 54.4688 6.48438H59.6562C60.0002 6.48438 60.3301 6.62101 60.5733 6.86422C60.8165 7.10743 60.9531 7.4373 60.9531 7.78125C60.9531 8.1252 60.8165 8.45507 60.5733 8.69828C60.3301 8.94149 60.0002 9.07812 59.6562 9.07812Z" fill="#2946ED" />
                                                <path d="M75.2188 9.07812H70.0312C69.6873 9.07812 69.3574 8.94149 69.1142 8.69828C68.871 8.45507 68.7344 8.1252 68.7344 7.78125C68.7344 7.4373 68.871 7.10743 69.1142 6.86422C69.3574 6.62101 69.6873 6.48438 70.0312 6.48438H75.2188C75.5627 6.48438 75.8926 6.62101 76.1358 6.86422C76.379 7.10743 76.5156 7.4373 76.5156 7.78125C76.5156 8.1252 76.379 8.45507 76.1358 8.69828C75.8926 8.94149 75.5627 9.07812 75.2188 9.07812Z" fill="#376CFB" />
                                                <path d="M49.9923 55.6542C49.8125 55.5364 49.6063 55.4652 49.3921 55.4468C49.178 55.4285 48.9626 55.4637 48.7654 55.5492C46.473 56.5423 44.0012 57.0548 41.5029 57.0548C39.0046 57.0548 36.5329 56.5423 34.2404 55.5492C34.043 55.463 33.8273 55.4274 33.6126 55.4455C33.398 55.4636 33.1913 55.5349 33.0111 55.6529C32.8309 55.7709 32.683 55.932 32.5806 56.1215C32.4782 56.311 32.4247 56.523 32.4248 56.7384V73.922C32.4248 76.3297 33.3812 78.6387 35.0837 80.3412C36.7862 82.0437 39.0953 83.0001 41.5029 83.0001C43.9106 83.0001 46.2197 82.0437 47.9221 80.3412C49.6246 78.6387 50.5811 76.3297 50.5811 73.922V56.7384C50.5807 56.523 50.5266 56.3111 50.4238 56.1218C50.3211 55.9325 50.1727 55.7718 49.9923 55.6542Z" fill="#376CFB" />
                                                <path d="M41.5 59.6562C52.9599 59.6562 62.25 50.3662 62.25 38.9062C62.25 27.4463 52.9599 18.1562 41.5 18.1562C30.0401 18.1562 20.75 27.4463 20.75 38.9062C20.75 50.3662 30.0401 59.6562 41.5 59.6562Z" fill="#4294FF" />
                                                <path d="M41.5 54.4688C50.0949 54.4688 57.0625 47.5012 57.0625 38.9062C57.0625 30.3113 50.0949 23.3438 41.5 23.3438C32.9051 23.3438 25.9375 30.3113 25.9375 38.9062C25.9375 47.5012 32.9051 54.4688 41.5 54.4688Z" fill="#376CFB" />
                                                <path d="M37.4002 43.6001C37.667 43.8669 38.0164 44 38.3664 44C38.7158 44 39.0658 43.8669 39.3326 43.6001L42 40.9319L44.6681 43.6001C44.9349 43.8662 45.2842 44 45.6336 44C45.9836 44 46.333 43.8669 46.5998 43.6001C47.1334 43.0665 47.1334 42.2013 46.5998 41.6677L43.9324 39.0002L46.5998 36.332C47.1334 35.7991 47.1334 34.9339 46.5998 34.4002C46.0662 33.8666 45.2017 33.8666 44.6681 34.4002L42 37.0684L39.3326 34.4002C38.799 33.8666 37.9338 33.8666 37.4002 34.4002C36.8673 34.9339 36.8673 35.7991 37.4002 36.332L40.0683 39.0002L37.4002 41.6677C36.8666 42.2013 36.8666 43.0665 37.4002 43.6001Z" fill="#E6ECFF" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_816_816">
                                                    <rect width="83" height="83" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                    <p className='h4 fw-normal'>No SERP results found.</p>
                                </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}