import { getTokenSession } from './Auth';

const createList = async (ThemeContextData, data) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("Content-Type", "application/json");
    const requestData = JSON.stringify({
        list_name: data.list_name,
        list_desc: data.description,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        // redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-lists/save-list`,
            requestOptions
        );

        const result = await response.json();
        if (result.response.ok) {
            return { success: true };
        } else {
            return { success: false, };
        }
    } catch (error) {
        console.error("Error creating list:", error);
        return { success: false, error: "API request failed" };
    }
}

const editList = async (ThemeContextData, data, list_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("Content-Type", "application/json");
    const requestData = JSON.stringify({
        list_name: data.list_name,
        list_desc: data.description,
        list_id: list_id
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        // redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-lists/update-list`,
            requestOptions
        );

        const result = await response.json();
        if (result.response.ok) {
            return { success: true };
        } else {
            return { success: false, };
        }
    } catch (error) {
        console.error("Error creating list:", error);
        return { success: false, error: "API request failed" };
    }
}

const DeleteReciptListController = async (ThemeContextData, list_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("Content-Type", "application/json");
    const requestData = JSON.stringify({
        list_id: list_id
    });
    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-lists/delete-list`,
            requestOptions
        );

        const result = await response.json();
        if (result.response.ok) {
            return { success: true };
        } else {
            return { success: false, };
        }
    } catch (error) {
        console.error("Error creating list:", error);
        return { success: false, error: "API request failed" };
    }
}

const DeleteEmailReciptListController = async (ThemeContextData, array_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        list_id: array_id
    });
    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-lists/delete-list-data`,
            requestOptions
        );

        const result = await response.json();
        console.log(result);
        if (result.response.ok) {
            return { success: true };
        } else {
            return { success: false, };
        }
    } catch (error) {
        console.error("Error creating list:", error);
        return { success: false, error: "API request failed" };
    }
}

const addListData = async (ThemeContextData, setTryLater, data, list_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("Content-Type", "application/json");
    const requestData = JSON.stringify({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        user_name: data.user_name,
        list_id: list_id
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        // redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-lists/save-list-item`,
            requestOptions
        );

        const result = await response.json();
        if (result.response.ok) {
            return { success: true };
        } else {
            return { success: false, };
        }
    } catch (error) {
        setTryLater(true);
        console.error("Error creating list:", error);
        return { success: false, error: "API request failed" };
    }
}

const EditListData = async (ThemeContextData, setTryLater, data, list_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("Content-Type", "application/json");
    
    const requestData = JSON.stringify({
        list_firstname: data.first_name,
        list_lastname: data.last_name,
        list_email: data.email,
        list_data_id: list_id
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-lists/update-list-data`,
            requestOptions
        );

        const result = await response.json();
        if (result.response.ok) {
            return { success: true };
        } else {
            return { success: false, };
        }
    } catch (error) {
        setTryLater(true);
        console.error("Error creating list:", error);
        return { success: false, error: "API request failed" };
    }
}

const getAllRecipientLists = async (ThemeContextData) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-lists/get-user-lists`,
            requestOptions
        );

        const result = await response.json();
        
        if (result.response.ok) {
            return { success: true , data: result.response};
        } else {
            return { success: false};
        }
    } catch (error) {
        return { success: false, error: "API request failed" };
    }
}

const FileUploadReciptController = async (ThemeContextData, list_id, file, setFileUploadAlert) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());

    const formData = new FormData();
    formData.append('file', file);
    formData.append('list_id', list_id);

    
    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData,
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-lists/csv-upload`,
            requestOptions
        );

        const result = await response.json();
        if (result.response.ok) {
            return { success: true };
        } else {
            return { success: false, };
        }
    } catch (error) {
        setFileUploadAlert(true)
        return { success: false, error: "API request failed" };
    }
}

export{
    getAllRecipientLists,
    createList,
    editList,
    DeleteReciptListController,
    DeleteEmailReciptListController,
    addListData,
    EditListData, 
    FileUploadReciptController
}