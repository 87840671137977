import Form from 'react-bootstrap/Form';

export default function InputTagFieldWithEditModal(props) {
    const tags = Array.isArray(props.tags) ? props.tags : [];

    return (
        <>
            <div className={
                props.eventValue !== undefined && props.eventValue.length > 0 ||
                    props.tags !== undefined && props.tags.length > 0 ? "tag-field-group mb-3" : props.fieldError !== undefined && props.fieldError ? "tag-field-group mb-3 error" : "tag-field-group mb-3"}>
                {
                    props.eventValue !== undefined ?
                        props.tags.map(
                            (item, i) =>
                                <span className='badge-secondary'>
                                    {props.edit === false ? "" : <span className='icon icon-pencil' onClick={() => {
                                        props.setKeywordSettingModal(true);
                                        props.setLastEditKeywordValue(item.keyword);
                                        props.setEditKeywordValue(item.keyword);
                                        props.setEditURLValue(item.url);
                                    }}></span>}

                                    {item.keyword}
                                    <span className='icon icon-cross' style={{ cursor: 'pointer' }} onClick={() => props.valueRemoveHandler(i)}></span>
                                </span>
                        )
                        : ""
                }

                <div className='tag-input-field'>
                    <Form.Control
                        type="text"
                        spellcheck="true"
                        value={props.eventValue}
                        onChange={(e) => props.eventValueHandler(e.target.value)}
                        onKeyDown={(e) => props.enterEvent(e.target.value, e.code)}
                        onFocus={() => props.getRefkeyword !== undefined ? props.getRefkeyword(true) : ""}
                        placeholder={props.placeholder}
                    />
                </div>
            </div>
        </>
    )
}