import React, { useState, useContext } from 'react';

import { TextProps, TextPropsSchema } from '@usewaypoint/block-text';
import { Box } from '@mui/material';
import BaseSidebarPanel from './helpers/BaseSidebarPanel.tsx';
import BooleanInput from './helpers/inputs/BooleanInput.tsx';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import TextInput from './helpers/inputs/TextInput.tsx';
import MultiStylePropertyPanel from './helpers/style-inputs/MultiStylePropertyPanel.tsx';
import { ThemeContext } from '../../../../../projectContext/ThemeContext.js';
import { getTokenSession } from '../../../../../provider/Auth.js';
import LoaderJSON from '../../../../../assets/images/loading.json';
import Lottie from 'lottie-react';
import { Button } from 'react-bootstrap';
import UpgradeUserPlanModal from '../../../../UpgradeUserPlanModal/UpgradeUserPlanModal.jsx';
import dottedLoaderSmall from '../../../../../assets/images/dottedLoaderSmall.gif';

type TextSidebarPanelProps = {
  data: TextProps;
  setData: (v: TextProps) => void;
};
export default function TextSidebarPanel({ data, setData }: TextSidebarPanelProps) {

  const ThemeContextData = useContext(ThemeContext);
  const [generating, setGenerating] = useState(false);
  const [aiHelp, setAiHelp] = useState(false);
  const [fieldCounter, setFieldCounter] = useState(0);
  const [promptValue, setPromptValue] = useState("");
  const [generatedResult, setGeneratedResult] = useState("");
  const [blanceExhaustedModal, setBlanceExhaustedModal] = useState(false);

  const [, setErrors] = useState<Zod.ZodError | null>(null);

  const updateData = (d: unknown) => {
    const res = TextPropsSchema.safeParse(d);
    if (res.success) {
      setData(res.data);
      setErrors(null);
    } else {
      setErrors(res.error);
    }
  };


  // const chatAPIHandler = () => {

  //   let newIndexObject = { role: "system", content: "You are a helpful assistant." };
  //   let messagesDataArr = [{ role: "user", content: data.props?.text + " " + promptValue }];

  //   messagesDataArr = [newIndexObject].concat(messagesDataArr);

  //   setGenerating(true);

  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", "Bearer " + getTokenSession());
  //   myHeaders.append("accept", "application/json");
  //   myHeaders.append("Content-Type", "application/json");


  //   var raw = JSON.stringify({
  //     GPT: "GPT-3.5-Ext",
  //     copy: 1,
  //     language: "English",
  //     productName: messagesDataArr,
  //     tone: "Appreciative",
  //     usecase: "GrammarImprove"
  //   });


  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     redirect: "follow",
  //     body: raw,
  //   };


  //   fetch(
  //     ThemeContextData.apiURL + 'users/article-generate',
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result.response.data) {
  //         // setGeneratedResult(result.response.data);
  //         let text = result.response.data[0];
  //         console.log(text);
  //         updateData({ ...data, props: { ...data.props, text } });
  //       }
  //       else if (result.response.message === "balance exhausted") {
  //         setBlanceExhaustedModal(true)
  //       }

  //       setGenerating(false);
  //     })
  //     .catch((error) => { });
  // }

  // const exhaustedModalCloser = () => {
  //   setBlanceExhaustedModal(false);
  // }

  return (
    <>
      <BaseSidebarPanel title="Text block">
        <TextInput
          label="Content"
          rows={5}
          defaultValue={data.props?.text ?? ''}
          onChange={(text) => updateData({ ...data, props: { ...data.props, text } })}
        />
        {/* {generating ? <img src={dottedLoaderSmall} alt='loader' className='img-fluid' style={{ width: '32px', height: '32px', margin: '0 0 0 auto' }} /> :
          <Box style={{ textAlign: 'right', marginTop: 5, cursor: 'pointer' }} onClick={chatAPIHandler}>AI Help?</Box>}

        {aiHelp ?
          <Box style={{ gap: 15, display: 'flex', flexDirection: 'column' }}>
            <TextField
              label="Enter Prompt"
              type="text"
              value={promptValue}
              onChange={(e) => setPromptValue(e.target.value)}
            />
            {generating ? <Button variant='dark' className='mx-auto' onClick={(e) => { e.preventDefault(); }}>
              <Lottie className='LoaderJSONSVG' animationData={LoaderJSON} />
              Generating...
            </Button> :
              <Button
                variant='primary' className='mx-auto' onClick={chatAPIHandler}>
                <span className='icon-lg icon-article'></span>
                Generate Prompt
              </Button>
            }

          </Box>
          : ""} */}
        <BooleanInput
          label="Markdown"
          defaultValue={data.props?.markdown ?? false}
          onChange={(markdown) => updateData({ ...data, props: { ...data.props, markdown } })}
        />

        <MultiStylePropertyPanel
          names={['color', 'backgroundColor', 'fontFamily', 'fontSize', 'fontWeight', 'textAlign', 'padding']}
          value={data.style}
          onChange={(style) => updateData({ ...data, style })}
        />
      </BaseSidebarPanel>
      {/* <UpgradeUserPlanModal show={blanceExhaustedModal} exhaustedModalCloser={exhaustedModalCloser} /> */}
    </>
  );
}
