import { getTokenSession } from './Auth';
import { marked } from 'marked';
import {getCreditsFunction} from "./CreditsController";

const getCategoryKeywordFunction = async (
    ThemeContextData,
    setTryLater,
    projectId,
    setGetCategoryArray,
    setGetKeywordArray
) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        project_id: projectId,
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw
    };

    try {
        const response = await fetch(ThemeContextData.apiURL + 'categories/get-project-category-keywords', requestOptions);
        const result = await response.json();

        if (result.response.ok) {
            setGetCategoryArray(result.response.categories); // Set categories in state
            let keywordArr = []
            result.response.keyword_complete.map(item => keywordArr.push({title: item.keyword, keyword:item.keyword, url: item.url === null ? "" : item.url.includes('http') ? item.url : 'https://' + item.url}))
            setGetKeywordArray(keywordArr); // Set keywords in state
        }

    } catch (error) {
        setTryLater(true)
        console.error('Error fetching project categories and keywords:', error);
    }
};

const keywordSuggestionAPIFunction = async (
    ThemeContextData,
    setTryLater,
    suggestedKeywordModalFormValue,
    locationOption,
    selectedLangOption,
    keywordSearchEngineOption,
    // setKeywordGenerateCount,
    // setSuggestKeywordModalResponse,
    // setSuggestKeywordAPIResponse,
    // setLoader,
    // setSuggestKeywordCount,
) => {

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        keyword: suggestedKeywordModalFormValue,
        location_code: locationOption.code,
        language: selectedLangOption.text,
        search_engine: keywordSearchEngineOption.text.toLowerCase()
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw,
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'article/keyword-suggestion',
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {
            return {success: true, data: result.response.extracted_data}
        }
        else if (result.response.message) {
            return {success: false, exhausted: true}
        } else {
            return {success: false}
        }
    } catch (error) {
        setTryLater(true)
        // setSuggestKeywordModalResponse(true);
        // setLoader(prevState => ({
        //     ...prevState,
        //     suggestKeywordLoader: false
        // }));
        // setSuggestKeywordCount(0);
    }
};

const titleSuggestionAPIFunction = async (
    ThemeContextData,
    setTryLater,
    seedKeywordData,
    selectedLangOption,
    selectedIntentOption,
    getGPTVersion,
    state,
    setSuggestTitleModalResponse,
    setSuggestTitleList
) => {
    setSuggestTitleModalResponse(true);

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    
    const raw = JSON.stringify({
        keyword: seedKeywordData[0],
        language: selectedLangOption.text,
        intent: selectedIntentOption.text,
        gpt: getGPTVersion,
        word_length: state.wordCount
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw,
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'article/suggest-title',
            requestOptions
        );
        const result = await response.json();
        if (result.data && result.data.ok) {
            return { success: true, 'data': result.data.data };
        } else if (!result.data.ok && result.data.message) {
            return { success: false, exhausted: true };
        } else {
            return { success: false };
        }
    } catch (error) {
        setTryLater(true);
        console.error("Error deleting article:", error);
        return { success: false, error: "API request failed" };
    }

    // try {
    //     

    //     if (result.ok) {
    //         setSuggestTitleModalResponse(false);
    //         let array = result.data.data[0].split("\n");
    //         array = array.filter(element => element.trim() !== "");
    //         setSuggestTitleList(array);
    //     } else {
    //         setSuggestTitleModalResponse(false);
    //     }
    // } catch (error) {
    //     console.error('Error:', error);
    //     setSuggestTitleModalResponse(false);
    // }
};

const seedKeywordAPIFunction = async (
    ThemeContextData,
    e,
    locationOption,
    selectedLangOption,
    keywordSearchEngineOption,
    setLoader,
    setSuggestKeywordCount,
    setSeedKeywordModalResponse,
    setSeedKeywordAPIResponse,
    setSeedKeywordTitleList,
    setBlanceExhaustedModal
) => {
    if (e) {
        setLoader(prevState => ({
            ...prevState,
            seedLoader: true
        }));

        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getTokenSession());
        myHeaders.append("accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            keyword: e,
            location_code: locationOption.code,
            language: selectedLangOption.text,
            search_engine: keywordSearchEngineOption.text.toLowerCase()
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
            body: raw,
        };

        try {
            const response = await fetch(
                ThemeContextData.apiURL + 'article/serp',
                requestOptions
            );
            const result = await response.json();

            if(result.data.message){
                setBlanceExhaustedModal(true);
            } else if (result.data.ok) {
                setLoader(prevState => ({
                    ...prevState,
                    seedLoader: false
                }));
                setSuggestKeywordCount(1);
                setSeedKeywordModalResponse(true);
                setSeedKeywordAPIResponse(result.data.res);
                setSeedKeywordTitleList(prevList => [
                    ...prevList,
                    ...result.data.res.map(item => item.url)
                ]);
            } else {
                setSuggestKeywordCount(1);
                setSeedKeywordModalResponse(false);
                setLoader(prevState => ({
                    ...prevState,
                    seedLoader: false
                }));
            }
        } catch (error) {
            setSuggestKeywordCount(1);
            setSeedKeywordModalResponse(false);
            setLoader(prevState => ({
                ...prevState,
                seedLoader: false
            }));
        }
    }
};

const generateOutlineAPIFunction = async (
    ThemeContextData,
    setTryLater,
    language,
    tone,
    title,
    keyword,
    getGPTVersion,
    setSuggestionHeadingDisabled,
    pageIsLoading,
    // getCreditsFunction,
    exhaustedModalCloserFromOutline,
    setTextContent
) => {
    setSuggestionHeadingDisabled(true);
    pageIsLoading(true);

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        language,
        tone,
        title,
        keyword,
        GPT: getGPTVersion,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw,
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'article/article-heading-generate',
            requestOptions
        );
        const result = await response.json();

        pageIsLoading(false);

        if (result.response.ok) {
            await getCreditsFunction(ThemeContextData);
            setSuggestionHeadingDisabled(false);
            // document.getElementsByClassName('CURD-field')[0].childNodes[0].textContent = marked(result.response.data[0]);
            let contentString = marked(result.response.data[0]);
            contentString = contentString.replace(/<li>\s*<br>\s*<\/li>/g, '');


            document.getElementsByClassName('CURD-field')[0].childNodes[0].innerHTML = contentString;

            // let res = result.response.data[0].replaceAll('\n\n', '\n');
            // setTextContent(marked(result.response.data[0]));
        } else if (result.response.message === "balance exhausted") {
            setSuggestionHeadingDisabled(false);
            exhaustedModalCloserFromOutline(false);
        }
    } catch (error) {
        setTryLater(true);
        pageIsLoading(false);
        setSuggestionHeadingDisabled(false);
        console.error('Error generating outline:', error);
    }
};


const saveArticleAPIFunction = async (
    ThemeContextData,
    selectedLangOption,
    state,
    props,
    selectedIntentOption,
    categoryData,
    seedKeywordData,
    keywordData,
    locationOption,
    keywordSearchEngineOption,
    keywordDensityOption,
    checkList,
    getGPTVersion,
    setLoaderActive,
    setPageDisabled,
    setBtnDisabled,
    setTextErrorShow,
    setBlanceExhaustedModal,
    getNewProjectId,
    getAudienceValue,
    getBrandKitValue

) => {
    setLoaderActive(true);
    setPageDisabled(true);
    setBtnDisabled(true);
    
    const arrayString = document.getElementsByClassName('CURD-field')[0].childNodes[0].textContent;
    const arrayHeading = [arrayString];

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        language: selectedLangOption.text,
        word_length: state.wordCount,
        project_id: props.getProjectId === undefined ? getNewProjectId : props.getProjectId,
        intent: selectedIntentOption.text,
        category: categoryData,
        title: state.articleTitle,
        article_heading: arrayHeading,
        keyword: seedKeywordData,
        short_tail: keywordData,
        location: locationOption.code,
        search_engine: keywordSearchEngineOption.text.toLowerCase(),
        keyword_density: keywordDensityOption.text,
        serp: checkList,
        GPT: getGPTVersion,
        audience_id: getAudienceValue && getAudienceValue.id ? getAudienceValue.id : null,
        brandkit_id: getBrandKitValue && getBrandKitValue.id ? getBrandKitValue.id : null,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw,
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'article/create-aricle',
            requestOptions
        );
        const result = await response.json();

        if (result && result.response && result.response.ok) {
            window.location.href = `/article-writer?article_id=${result.response.articleId}&project_id=${props.getProjectId === undefined ? getNewProjectId : props.getProjectId}`;
            setTextErrorShow(false);
        } else if (result && result.response && !result.response.ok && result.response.message === "balance exhausted") {
            setBlanceExhaustedModal(true);
        } 
        else{
            setPageDisabled(false);
            setBtnDisabled(false);
        }
    } catch (error) {
        console.error('Error saving article:', error);
    } finally {
        setLoaderActive(false);
        setBtnDisabled(false);
    }
};

export {
    getCategoryKeywordFunction,
    keywordSuggestionAPIFunction,
    titleSuggestionAPIFunction,
    seedKeywordAPIFunction,
    generateOutlineAPIFunction,
    saveArticleAPIFunction
}
