import { getTokenSession } from './Auth';

const addSendingMethod = async (ThemeContextData, data, secure) => {

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("Content-Type", "application/json");
    const requestData = JSON.stringify({
        host: data.host,
        port: data.port,
        email: data.email,
        password: data.password,
        secure: secure.text === "True" ? true : false
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        // redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-sender/add-smtp-sender`,
            requestOptions
        );

        const result = await response.json();
        if (result.response.ok) {
            return { success: true };
        } else {
            return { success: false, };
        }
    } catch (error) {
        console.error("Error fetching SEO score:", error);
        return { success: false, error: "API request failed" };
    }
};

const getAllSendingMethod = async (ThemeContextData) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-sender/get-smtp-sender`,
            requestOptions
        );

        const result = await response.json();

        if (result.response.ok) {
            return { success: true , data: result.response.smtps};
        } else {
            return { success: false};
        }
    } catch (error) {
        return { success: false, error: "API request failed" };
    }
}

const deleteSendingMethodController = async (ThemeContextData, id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        smtp_id: id,
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-sender/delete-smtp-sender`,
            requestOptions
        );

        const result = await response.json();

        if (result.response.ok) {
            return { success: true};
        } else {
            return { success: false};
        }
    } catch (error) {
        return { success: false, error: "API request failed" };
    }
}

const updateSendingMethodController = async (ThemeContextData, data, id, secure) => {

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("Content-Type", "application/json");
    const requestData = JSON.stringify({
        smtp_id: id,
        host: data.host,
        port: data.port,
        email: data.email,
        password: data.password,
        secure: secure.text === "True" ? true : false
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        // redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-sender/edit-smpt-sender`,
            requestOptions
        );

        const result = await response.json();
        if (result.response.ok) {
            return { success: true };
        } else {
            return { success: false, };
        }
    } catch (error) {
        console.error("Error fetching SEO score:", error);
        return { success: false, error: "API request failed" };
    }
};

const addBrandKitController = async (ThemeContextData, data) => {

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    

    const formData = new FormData();
    formData.append('image', data.image);
    formData.append('favicon', data.favicon);
    formData.append('kit_name', data.kit_name);
    formData.append('primary', data.primary);
    formData.append('secondary', data.secondary);
    formData.append('font_name', data.font_name);
    formData.append('background_color', data.background_color);

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData,
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}brand-kit/create-brand-kit`,
            requestOptions
        );

        const result = await response.json();
        if (result.data.ok) {
            return { success: true };
        } else {
            return { success: false, };
        }
    } catch (error) {
        console.error("Error fetching SEO score:", error);
        return { success: false, error: "API request failed" };
    }
};

const deleteBrandKitController = async (ThemeContextData, id) => {

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        kit_id: id,
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}brand-kit/delete-brand-kit`,
            requestOptions
        );

        const result = await response.json();
        if (result.data.ok) {
            return { success: true };
        } else {
            return { success: false, };
        }
    } catch (error) {
        console.error("Error fetching SEO score:", error);
        return { success: false, error: "API request failed" };
    }
};

const getAllBrankKitController = async (ThemeContextData) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");


    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}brand-kit/get-all-brand-kit`,
            requestOptions
        );
        const result = await response.json();

        if (result.data.ok) {
            return { success: true , data: result.data.kits};
        } else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        console.error("Error creating chat:", error);
        return { success: false, error: "API request failed" };
    }
};

const updateBrandKitController = async (ThemeContextData, data) => {

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());    

    const formData = new FormData();
    
    {data.favicon === null ? 
        formData.append('favicon_url', data.favicon_url):
        formData.append('favicon', data.favicon)
        
    }
    {data.image === null ? 
        formData.append('image_url', data.image_url) : 
        formData.append('image', data.image)        
    }
    
    formData.append('kit_id', data.kit_id);
    formData.append('kit_name', data.kit_name);
    formData.append('primary', data.primary);
    formData.append('secondary', data.secondary);
    formData.append('font_name', data.font_name);
    formData.append('background_color', data.background_color);
    

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData,
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}brand-kit/edit-brand-kit`,
            requestOptions
        );

        const result = await response.json();
        if (result.data.ok) {
            return { success: true };
        } else {
            return { success: false, };
        }
    } catch (error) {
        console.error("Error fetching SEO score:", error);
        return { success: false, error: "API request failed" };
    }
};

const DomainChangeStatusController = async (ThemeContextData, id, statusText) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        domain_id: id,
        status: statusText

    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw
    }


    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}user-domain/change-domain-status`,
            requestOptions
        );
        const result = await response.json();
        
        if (result.response.ok) {
            return { success: true};
        } else {
            return { success: false };
        }
    } catch (error) {
        console.error("Error creating chat:", error);
        return { success: false, error: "API request failed" };
    }
};


export {
    addSendingMethod,
    getAllSendingMethod,
    deleteSendingMethodController,
    updateSendingMethodController,
    addBrandKitController,
    deleteBrandKitController,
    getAllBrankKitController,
    updateBrandKitController,
    DomainChangeStatusController
}