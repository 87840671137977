import {getTokenSession, subUser} from "./Auth";

async function getProjects(ThemeContextData) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };

    try {
        const response = await fetch(ThemeContextData.apiURL + 'projects/get-project', requestOptions);
        const result = await response.json();

        if (result.response.ok) {
            let projectData = [];
            let projectTitles = [];

            result.response.data.forEach((item) => {
                if (item.title !== "") {
                    projectData.push({ id: `${item.id}`, text: `${item.title}` });
                    projectTitles.push(item.title);
                }
            });

            return {
                projectTitles,
                projectData,
            };
        } else {
            console.error('Error: Project data not found');
            return null;
        }
    } catch (error) {
        console.error('Error fetching projects:', error);
        return null;
    }
}

const getCategoriesAPI = async (ThemeContextData, e) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var raw;
    if (e.id) {
        raw = JSON.stringify({ id: e.id });
    } else {
        raw = JSON.stringify({ id: e.value });
    }

    var requestOptions = {
        method: "Post",
        headers: myHeaders,
        redirect: "follow",
        body: raw
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'projects/getProjectCategory',
            requestOptions
        );
        const result = await response.json();

        if (result.ok) {
            let catData = [];
            let idsTitle = [];
            let _categoryData = result.response;

            result.response.forEach((item) => {
                if (item.title !== "") {
                    catData.push(item.title);
                    idsTitle.push({ id: item.id, title: item.title });
                }
            });

            return {
                _categoryData,
                catData,
                idsTitle
            };
        }
    } catch (error) {
        console.error("Error fetching categories:", error);
        return null;
    }
};

const emailUnsubscribe = async (ThemeContextData, setTryLater,  e) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify({ list_data_id: e })
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'email-lists/unsubscribe-email',
            requestOptions
        );
        const result = await response.json();

        if (result.response.status == 200) {
            return {success: true}
        }
    } catch (error) {
        setTryLater(true)
        console.error("Error fetching categories:", error);
    }
};

const emailSubscribe = async (ThemeContextData, setTryLater, e) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify({ list_data_id: e })
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'email-lists/subscribe-email',
            requestOptions
        );
        const result = await response.json();

        
        if (result.response.status == 200) {
            return {success: true}
        }
    } catch (error) {
        setTryLater(true)
        console.error("Error fetching categories:", error);
    }
};

export {
    getProjects,
    getCategoriesAPI,
    emailUnsubscribe, 
    emailSubscribe
}