import English from "../assets/flags/English.png";
import Arabic from "../assets/flags/Arabic.png";
import Bulgarian from "../assets/flags/Bulgarian.png";
import Chinese from "../assets/flags/Chinese.png";
import Czech from "../assets/flags/Czech.png";
import Danish from "../assets/flags/Danish.png";
import Dutch from "../assets/flags/Dutch.png";
import Persian from "../assets/flags/Persian.png";
import Filipino from "../assets/flags/Filipino.png";
import Finnish from "../assets/flags/Finnish.png";
import French from "../assets/flags/French.png";
import German from "../assets/flags/German.png";
import Greek from "../assets/flags/Greek.png";
import Hebrew from "../assets/flags/Hebrew.png";
import Hindi from "../assets/flags/Hindi.png";
import Hungarian from "../assets/flags/Hungarian.png";
import Italian from "../assets/flags/Italian.png";
import Indonesian from "../assets/flags/Indonesian.png";
import Japanese from "../assets/flags/Japanese.png";
import Korean from "../assets/flags/Korean.png";
import Lithuanian from "../assets/flags/Lithuanian.png";
import Malaysia from "../assets/flags/Malaysia.png";
import Norwegian from "../assets/flags/Norwegian.png";
import Polish from "../assets/flags/Polish.png";
import Portuguese from "../assets/flags/Portuguese.png";
import Romanian from "../assets/flags/Romanian.png";
import Russia from "../assets/flags/Russia.png";
import Slovak from "../assets/flags/Slovak.png";
import Spanish from "../assets/flags/Spanish.png";
import Swedish from "../assets/flags/Swedish.png";
import Thai from "../assets/flags/Thai.png";
import Turkish from "../assets/flags/Turkish.png";
import Vietnamese from "../assets/flags/Vietnamese.png";
import React from "react";
import Algeria from "../assets/location-flags/Algeria.png";
import Angola from "../assets/location-flags/Angola.png";
import Argentina from "../assets/location-flags/Argentina.png";
import Armenia from "../assets/location-flags/Armenia.png";
import Australia from "../assets/location-flags/Australia.png";
import Austria from "../assets/location-flags/Austria.png";
import Azerbaijan from "../assets/location-flags/Azerbaijan.png";
import Bahrain from "../assets/location-flags/Bahrain.png";
import Bangladesh from "../assets/location-flags/Bangladesh.png";
import Belgium from "../assets/location-flags/Belgium.png";
import Bolivia from "../assets/location-flags/Bolivia.png";
import Bulgaria from "../assets/location-flags/Bulgaria.png";
import BurkinaFaso from "../assets/location-flags/BurkinaFaso.png";
import Cambodia from "../assets/location-flags/Cambodia.png";
import Cameroon from "../assets/location-flags/Cameroon.png";
import Canada from "../assets/location-flags/Canada.png";
import Chile from "../assets/location-flags/Chile.png";
import Colombia from "../assets/location-flags/Colombia.png";
import CostaRica from "../assets/location-flags/CostaRica.png";
import Cyprus from "../assets/location-flags/Cyprus.png";
import CzechRepublic from "../assets/location-flags/CzechRepublic.png";
import Denmark from "../assets/location-flags/Denmark.png";
import Ecuador from "../assets/location-flags/Ecuador.png";
import Egypt from "../assets/location-flags/Egypt.png";
import ElSalvador from "../assets/location-flags/ElSalvador.png";
import Estonia from "../assets/location-flags/Estonia.png";
import Finland from "../assets/location-flags/Finland.png";
import France from "../assets/location-flags/France.png";
import Germany from "../assets/location-flags/Germany.png";
import Ghana from "../assets/location-flags/Ghana.png";
import Greece from "../assets/location-flags/Greece.png";
import Guatemala from "../assets/location-flags/Guatemala.png";
import India from "../assets/location-flags/India.png";
import Indonesia from "../assets/location-flags/Indonesia.png";
import Ireland from "../assets/location-flags/Ireland.png";
import Israel from "../assets/location-flags/Israel.png";
import Italy from "../assets/location-flags/Italy.png";
import Japan from "../assets/location-flags/Japan.png";
import Jordan from "../assets/location-flags/Jordan.png";
import Kazakhstan from "../assets/location-flags/Kazakhstan.png";
import Kenya from "../assets/location-flags/Kenya.png";
import KoreaNorth from "../assets/location-flags/KoreaNorth.png";
import Latvia from "../assets/location-flags/Latvia.png";
import Lithuania from "../assets/location-flags/Lithuania.png";
import Malta from "../assets/location-flags/Malta.png";
import Mexico from "../assets/location-flags/Mexico.png";
import Morocco from "../assets/location-flags/Morocco.png";
import Myanmar from "../assets/location-flags/Myanmar.png";
import Netherlands from "../assets/location-flags/Netherlands.png";
import NewZealand from "../assets/location-flags/NewZealand.png";
import Nicaragua from "../assets/location-flags/Nicaragua.png";
import Niger from "../assets/location-flags/Niger.png";
import Nigeria from "../assets/location-flags/Nigeria.png";
import NorthMacedonia from "../assets/location-flags/NorthMacedonia.png";
import Norway from "../assets/location-flags/Norway.png";
import Pakistan from "../assets/location-flags/Pakistan.png";
import Panama from "../assets/location-flags/Panama.png";
import Paraguay from "../assets/location-flags/Paraguay.png";
import Peru from "../assets/location-flags/Peru.png";
import Philippines from "../assets/location-flags/Philippines.png";
import Poland from "../assets/location-flags/Poland.png";
import Portugal from "../assets/location-flags/Portugal.png";
import Romania from "../assets/location-flags/Romania.png";
import SaudiArabia from "../assets/location-flags/SaudiArabia.png";
import Senegal from "../assets/location-flags/Senegal.png";
import Serbia from "../assets/location-flags/Serbia.png";
import Singapore from "../assets/location-flags/Singapore.png";
import Slovakia from "../assets/location-flags/Slovakia.png";
import SouthAfrica from "../assets/location-flags/SouthAfrica.png";
import Spain from "../assets/location-flags/Spain.png";
import SriLanka from "../assets/location-flags/SriLanka.png";
import Sweden from "../assets/location-flags/Sweden.png";
import Switzerland from "../assets/location-flags/Switzerland.png";
import Taiwan from "../assets/location-flags/Taiwan.png";
import Thailand from "../assets/location-flags/Thailand.png";
import Tunisia from "../assets/location-flags/Tunisia.png";
import Ukraine from "../assets/location-flags/Ukraine.png";
import UnitedArabEmirates from "../assets/location-flags/UnitedArabEmirates.png";
import UnitedKingdom from "../assets/location-flags/UnitedKingdom.png";
import UnitedStates from "../assets/location-flags/UnitedStates.png";
import Uruguay from "../assets/location-flags/Uruguay.png";
import Macedonian from "../assets/flags/Macedonian.png";

function langDataExportFunction(){
    return [
        {
            value: 'english',
            text: 'English',
            flag: English
        },
        {
            value: 'arabic',
            text: 'Arabic',
            flag: Arabic
        },
        {
            value: 'bulgarian',
            text: 'Bulgarian',
            flag: Bulgarian
        },
        {
            value: 'chinese (S)',
            text: 'Chinese (S)',
            flag: Chinese
        },
        {
            value: 'chinese (T)',
            text: 'Chinese (T)',
            flag: Chinese
        },
        {
            value: 'czech',
            text: 'Czech',
            flag: Czech
        },
        {
            value: 'danish',
            text: 'Danish',
            flag: Danish
        },
        {
            value: 'dutch',
            text: 'Dutch',
            flag: Dutch
        },
        {
            value: 'persian',
            text: 'Persian',
            flag: Persian
        },
        {
            value: 'filipino',
            text: 'Filipino',
            flag: Filipino
        },
        {
            value: 'finnish',
            text: 'Finnish',
            flag: Finnish
        },
        {
            value: 'french',
            text: 'French',
            flag: French
        },
        {
            value: 'german',
            text: 'German',
            flag: German
        },
        {
            value: 'greek',
            text: 'Greek',
            flag: Greek
        },
        {
            value: 'hebrew',
            text: 'Hebrew',
            flag: Hebrew
        },
        {
            value: 'hindi',
            text: 'Hindi',
            flag: Hindi
        },
        {
            value: 'hungarian',
            text: 'Hungarian',
            flag: Hungarian
        },
        {
            value: 'italian',
            text: 'Italian',
            flag: Italian
        },
        {
            value: 'indonesian',
            text: 'Indonesian',
            flag: Indonesian
        },
        {
            value: 'japanese',
            text: 'Japanese',
            flag: Japanese
        },
        {
            value: 'korean',
            text: 'Korean',
            flag: Korean
        },
        {
            value: 'lithuanian',
            text: 'Lithuanian',
            flag: Lithuanian
        },
        {
            value: 'malaysia',
            text: 'Malaysia',
            flag: Malaysia
        },
        {
            value: 'norwegian',
            text: 'Norwegian',
            flag: Norwegian
        },
        {
            value: 'polish',
            text: 'Polish',
            flag: Polish
        },
        {
            value: 'portuguese',
            text: 'Portuguese',
            flag: Portuguese
        },
        {
            value: 'romanian',
            text: 'Romanian',
            flag: Romanian
        },
        {
            value: 'russia',
            text: 'Russia',
            flag: Russia
        },
        {
            value: 'slovak',
            text: 'Slovak',
            flag: Slovak
        },
        {
            value: 'spanish',
            text: 'Spanish',
            flag: Spanish
        },
        {
            value: 'swedish',
            text: 'Swedish',
            flag: Swedish
        },
        {
            value: 'thai',
            text: 'Thai',
            flag: Thai
        },
        {
            value: 'turkish',
            text: 'Turkish',
            flag: Turkish
        },
        {
            value: 'vietnamese',
            text: 'Vietnamese',
            flag: Vietnamese
        }
    ];
}

function langDataExportAutomateFunction(){
    return [
        {
            value: 1,
            text: 'English',
            flag: English
        },
        {
            value: 2,
            text: 'Arabic',
            flag: Arabic
        },
        {
            value: 3,
            text: 'Bulgarian',
            flag: Bulgarian
        },
        {
            value: 4,
            text: 'Chinese (S)',
            flag: Chinese
        },
        {
            value: 5,
            text: 'Chinese (T)',
            flag: Chinese
        },
        {
            value: 6,
            text: 'Czech',
            flag: Czech
        },
        {
            value: 7,
            text: 'Danish',
            flag: Danish
        },
        {
            value: 8,
            text: 'Dutch',
            flag: Dutch
        },
        {
            value: 9,
            text: 'Persian',
            flag: Persian
        },
        {
            value: 10,
            text: 'Filipino',
            flag: Filipino
        },
        {
            value: 11,
            text: 'Finnish',
            flag: Finnish
        },
        {
            value: 12,
            text: 'French',
            flag: French
        },
        {
            value: 13,
            text: 'German',
            flag: German
        },
        {
            value: 14,
            text: 'Greek',
            flag: Greek
        },
        {
            value: 15,
            text: 'Hebrew',
            flag: Hebrew
        },
        {
            value: 16,
            text: 'Hindi',
            flag: Hindi
        },
        {
            value: 17,
            text: 'Hungarian',
            flag: Hungarian
        },
        {
            value: 18,
            text: 'Italian',
            flag: Italian
        },
        {
            value: 19,
            text: 'Indonesian',
            flag: Indonesian
        },
        {
            value: 20,
            text: 'Japanese',
            flag: Japanese
        },
        {
            value: 21,
            text: 'Korean',
            flag: Korean
        },
        {
            value: 22,
            text: 'Lithuanian',
            flag: Lithuanian
        },
        {
            value: 23,
            text: 'Malaysia',
            flag: Malaysia
        },
        {
            value: 24,
            text: 'Norwegian',
            flag: Norwegian
        },
        {
            value: 25,
            text: 'Polish',
            flag: Polish
        },
        {
            value: 26,
            text: 'Portuguese',
            flag: Portuguese
        },
        {
            value: 27,
            text: 'Romanian',
            flag: Romanian
        },
        {
            value: 28,
            text: 'Russia',
            flag: Russia
        },
        {
            value: 29,
            text: 'Slovak',
            flag: Slovak
        },
        {
            value: 30,
            text: 'Spanish',
            flag: Spanish
        },
        {
            value: 31,
            text: 'Swedish',
            flag: Swedish
        },
        {
            value: 32,
            text: 'Thai',
            flag: Thai
        },
        {
            value: 33,
            text: 'Turkish',
            flag: Turkish
        },
        {
            value: 34,
            text: 'Vietnamese',
            flag: Vietnamese
        }
    ];
}

function intentDataExportFunction(){
    return [
        {
            value: 1,
            text: 'Informational Intent',
            icon: <span className='icon-notify'></span>
        },
        {
            value: 2,
            text: 'Navigational Intent',
            icon: <span className='icon-timer'></span>
        },
        {
            value: 3,
            text: 'Transactional Intent',
            icon: <span className='icon-cart'></span>
        },
        {
            value: 4,
            text: 'Commercial Investigation',
            icon: <span className='icon-commercial'></span>
        },
    ];
}

function KeywordSearchEngineDataExportFunction(){
    return [
        {
            value: 1,
            text: 'Google',
            icon:
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_279_1406)">
                        <path d="M15.3957 6.60895L8.86944 6.60864C8.58125 6.60864 8.34766 6.84221 8.34766 7.13039V9.21524C8.34766 9.50336 8.58125 9.73699 8.86941 9.73699H12.5446C12.1422 10.7814 11.391 11.656 10.4327 12.2118L11.9998 14.9246C14.5136 13.4707 15.9998 10.9198 15.9998 8.06427C15.9998 7.65767 15.9698 7.36702 15.9099 7.03974C15.8643 6.79108 15.6485 6.60895 15.3957 6.60895Z" fill="#167EE6" />
                        <path d="M7.99985 12.8695C6.20129 12.8695 4.63116 11.8868 3.78788 10.4326L1.0752 11.9962C2.45566 14.3887 5.04173 15.9999 7.99985 15.9999C9.45101 15.9999 10.8203 15.6092 11.9999 14.9283V14.9246L10.4328 12.2117C9.71595 12.6275 8.88645 12.8695 7.99985 12.8695Z" fill="#12B347" />
                        <path d="M12 14.9284V14.9247L10.4329 12.2119C9.71609 12.6276 8.88666 12.8696 8 12.8696V16.0001C9.45116 16.0001 10.8205 15.6094 12 14.9284Z" fill="#0F993E" />
                        <path d="M3.13044 7.99994C3.13044 7.11341 3.37238 6.28401 3.78803 5.56722L1.07534 4.00366C0.390688 5.17951 0 6.54507 0 7.99994C0 9.45482 0.390688 10.8204 1.07534 11.9962L3.78803 10.4327C3.37238 9.71588 3.13044 8.88647 3.13044 7.99994Z" fill="#FFD500" />
                        <path d="M7.99985 3.13044C9.1727 3.13044 10.25 3.54719 11.0915 4.24041C11.2991 4.41141 11.6008 4.39906 11.7909 4.20891L13.2681 2.73172C13.4839 2.51597 13.4685 2.16281 13.238 1.96288C11.8282 0.739781 9.99382 0 7.99985 0C5.04173 0 2.45566 1.61116 1.0752 4.00372L3.78788 5.56728C4.63116 4.11313 6.20129 3.13044 7.99985 3.13044Z" fill="#FF4B26" />
                        <path d="M11.0916 4.24041C11.2992 4.41141 11.601 4.39906 11.7911 4.20891L13.2683 2.73172C13.484 2.51597 13.4686 2.16281 13.2382 1.96288C11.8283 0.73975 9.99397 0 8 0V3.13044C9.17281 3.13044 10.2502 3.54719 11.0916 4.24041Z" fill="#D93F21" />
                    </g>
                    <defs>
                        <clipPath id="clip0_279_1406">
                            <rect width="16" height="16" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
        },
        {
            value: 2,
            text: 'Bing',
            icon:
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.14285 5.34702V0.98218C3.14285 0.710932 3.03292 0.465371 2.85523 0.287685C2.67748 0.109937 2.43192 0 2.16067 0C1.88949 0 1.64393 0.109937 1.46618 0.287685C1.28843 0.465371 1.17856 0.710932 1.17856 0.98218V9.15343L1.17781 9.15624C1.17749 9.17211 1.17831 9.14536 1.17831 9.1758C1.17831 9.20518 1.17749 9.17893 1.17781 9.19443L1.17856 9.19786V9.97186C1.17856 11.6301 1.94042 13.1347 3.17054 14.2254C4.40659 15.3213 6.11483 16 8.00019 16C9.88568 16 11.594 15.3213 12.83 14.2254C14.0602 13.1348 14.8221 11.6301 14.8221 9.97186C14.8221 8.31368 14.0602 6.80901 12.83 5.71833C11.594 4.62246 9.88568 3.94372 8.00025 3.94372C7.15226 3.94372 6.33108 4.08447 5.56915 4.34197C4.78159 4.60809 4.05647 4.99852 3.42973 5.48664L3.14285 5.71002V5.34702ZM3.14773 9.2553C3.36366 8.30512 4.00247 7.46438 4.87884 6.86351C5.74558 6.26926 6.84676 5.90808 8.00019 5.90808C9.33237 5.90808 10.5425 6.35982 11.421 7.08926C12.3082 7.826 12.8578 8.84555 12.8578 9.97186C12.8578 11.0982 12.3083 12.1178 11.421 12.8546C10.5425 13.5839 9.33243 14.0357 8.00025 14.0357C6.66808 14.0357 5.45796 13.5839 4.57965 12.8545C3.69241 12.1177 3.14285 11.0982 3.14285 9.97186V9.29411L3.14773 9.2553Z" fill="#0075BE" />
                </svg>
        },
        {
            value: 3,
            text: 'Amazon',
            icon:

                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_396_569)">
                        <path d="M12.6219 8.83276C12.6154 8.82202 12.0002 7.75301 12.0002 7.00008V4.2924C12.0002 2.02222 10.7541 0.666748 8.66683 0.666748C6.34912 0.666748 5.08024 1.59904 4.67334 3.60034L4.60498 3.93888L7.25537 4.38061L7.32503 4.0743C7.44548 3.54793 7.73779 3.33341 8.3335 3.33341C8.78076 3.33341 9.23714 3.4633 9.32048 4.35815C8.64339 4.4161 7.0887 4.59188 6.18701 5.03426C4.93962 5.64591 4.3335 6.61597 4.3335 8.00008C4.3335 9.59513 5.40511 10.6667 7.00016 10.6667C8.49886 10.6667 9.28271 10.2328 9.68571 9.83634C10.2039 10.321 10.8263 10.6199 10.8569 10.6345L11.0698 10.7354L12.7502 9.05444L12.6219 8.83276ZM8.00016 8.33341C7.55745 8.33341 7.3335 8.10913 7.3335 7.66675C7.3335 6.63485 8.53402 6.39233 9.31787 6.34416C9.18376 7.79696 8.21891 8.33081 8.00016 8.33341Z" fill="#333333" />
                        <path opacity="0.1" d="M8.66683 0.833415C10.7541 0.833415 12.0002 2.18888 12.0002 4.45907V4.2924C12.0002 2.02222 10.7541 0.666748 8.66683 0.666748C6.34912 0.666748 5.08024 1.59904 4.67334 3.60034L4.60498 3.93888L4.63753 3.9443L4.67334 3.76701C5.08024 1.76571 6.34912 0.833415 8.66683 0.833415Z" fill="white" />
                        <path opacity="0.2" d="M11.0698 10.5688L10.8569 10.4679C10.8263 10.4532 10.2039 10.1544 9.68571 9.66968C9.28271 10.0662 8.49886 10.5001 7.00016 10.5001C5.42826 10.5001 4.36971 9.45671 4.33919 7.8999C4.33842 7.93404 4.3335 7.96541 4.3335 8.00008C4.3335 9.59513 5.40511 10.6667 7.00016 10.6667C8.49886 10.6667 9.28271 10.2328 9.68571 9.83634C10.2039 10.321 10.8263 10.6199 10.8569 10.6345L11.0698 10.7354L12.7502 9.05444L12.6891 8.94889L11.0698 10.5688Z" fill="#010101" />
                        <path d="M14.3626 12.4691C14.2793 12.3561 14.1354 12.3067 14 12.347C13.9661 12.3568 10.8737 13.3333 7.99998 13.3333C4.93748 13.3333 1.43097 12.2927 0.568988 11.431C0.441384 11.3041 0.236956 11.3001 0.106097 11.4229C-0.0254133 11.5453 -0.0358299 11.7497 0.0813575 11.8854C0.203754 12.0261 3.13344 15.3333 7.99998 15.3333C12.8926 15.3333 14.3034 12.9535 14.3711 12.8522C14.4492 12.7354 14.4459 12.5824 14.3626 12.4691Z" fill="#F79120" />
                        <path d="M14.6667 11C13.8092 11 12.9173 11.3411 12.8796 11.3558C12.735 11.4118 12.6478 11.5605 12.6699 11.7142C12.6921 11.8678 12.8177 11.986 12.9727 11.9987C13.6003 12.0511 14.3555 12.1608 14.612 12.2542C14.6204 12.5303 14.4889 13.2975 14.3418 13.9242C14.3066 14.0752 14.3802 14.2308 14.5202 14.2992C14.5671 14.3223 14.6172 14.3333 14.6667 14.3333C14.7643 14.3333 14.8607 14.29 14.9251 14.2103C15.0352 14.0745 16 12.862 16 12C16 11 14.9967 11 14.6667 11Z" fill="#F79120" />
                        <path opacity="0.1" d="M14.3712 12.6856C14.3035 12.7869 12.8927 15.1667 8.00008 15.1667C3.13355 15.1667 0.203861 11.8595 0.0814649 11.7188C0.0482211 11.6803 0.0279981 11.6359 0.01514 11.5898C-0.00919266 11.6912 0.00854821 11.801 0.0814649 11.8855C0.203861 12.0261 3.13355 15.3334 8.00008 15.3334C12.8927 15.3334 14.3035 12.9535 14.3712 12.8523C14.426 12.7702 14.4262 12.6739 14.4013 12.5824C14.392 12.6174 14.3921 12.6544 14.3712 12.6856Z" fill="#010101" />
                        <path opacity="0.2" d="M12.8796 11.5225C12.9173 11.5078 13.8092 11.1667 14.6667 11.1667C14.9867 11.1667 15.9316 11.1749 15.9893 12.0862C15.9912 12.058 16 12.0276 16 12C16 11 14.9967 11 14.6667 11C13.8092 11 12.9173 11.3411 12.8796 11.3558C12.735 11.4118 12.6478 11.5605 12.6699 11.7142C12.6716 11.7261 12.6778 11.7361 12.6807 11.7476C12.7078 11.6475 12.7783 11.5617 12.8796 11.5225Z" fill="white" />
                        <path d="M4.33331 8.00008C4.33331 9.59513 5.40493 10.6667 6.99998 10.6667C8.49868 10.6667 9.28253 10.2328 9.68552 9.83634C10.2038 10.321 10.8261 10.6199 10.8567 10.6345L11.0696 10.7354L12.75 9.05444L12.6217 8.83276C12.6152 8.82202 12 7.75301 12 7.00008V4.2924C12 2.02222 10.7539 0.666748 8.66664 0.666748C6.34894 0.666748 5.08006 1.59904 4.67315 3.60034L4.6048 3.93888L7.25519 4.38062L7.32485 4.0743C7.44529 3.54793 7.73761 3.33341 8.33331 3.33341C8.78058 3.33341 9.23696 3.4633 9.32029 4.35815C8.64321 4.4161 7.08852 4.59188 6.18683 5.03426C4.93943 5.64592 4.33331 6.61597 4.33331 8.00008ZM9.31769 6.34416C9.18357 7.79696 8.21873 8.33081 7.99998 8.33342C7.55727 8.33342 7.33331 8.10913 7.33331 7.66675C7.33331 6.63485 8.53383 6.39233 9.31769 6.34416ZM14.3626 12.4692C14.2793 12.3562 14.1354 12.3067 14 12.3471C13.9661 12.3569 10.8737 13.3334 7.99998 13.3334C4.93748 13.3334 1.43097 12.2927 0.568988 11.4311C0.441384 11.3041 0.236956 11.3002 0.106097 11.4229C-0.0254133 11.5453 -0.0358299 11.7498 0.0813575 11.8855C0.203754 12.0261 3.13344 15.3334 7.99998 15.3334C12.8926 15.3334 14.3034 12.9535 14.3711 12.8523C14.4492 12.7354 14.4459 12.5824 14.3626 12.4692ZM14.6666 11.0001C13.8092 11.0001 12.9173 11.3412 12.8795 11.3559C12.735 11.4119 12.6478 11.5606 12.6699 11.7143C12.692 11.8679 12.8177 11.9861 12.9726 11.9988C13.6002 12.0512 14.3554 12.1609 14.612 12.2543C14.6204 12.5304 14.4889 13.2976 14.3418 13.9242C14.3066 14.0753 14.3802 14.2309 14.5202 14.2992C14.567 14.3223 14.6172 14.3334 14.6666 14.3334C14.7643 14.3334 14.8607 14.2901 14.9251 14.2104C15.0351 14.0746 16 12.8621 16 12.0001C16 11.0001 14.9967 11.0001 14.6666 11.0001Z" fill="url(#paint0_linear_396_569)" />
                    </g>
                    <defs>
                        <linearGradient id="paint0_linear_396_569" x1="2.73332" y1="5.73141" x2="16.1773" y2="12.0001" gradientUnits="userSpaceOnUse">
                            <stop stop-color="white" stop-opacity="0.2" />
                            <stop offset="1" stop-color="white" stop-opacity="0" />
                        </linearGradient>
                        <clipPath id="clip0_396_569">
                            <rect width="16" height="16" fill="white" />
                        </clipPath>
                    </defs>
                </svg>

        }
    ];
}

function KeywordDensityDataExportFunction(){
    return [
        {
            value: 1,
            text: '1%'
        },
        {
            value: 2,
            text: '2%'
        },
    ];
}

function EmailTypeDataExportFunction(){
    return [
        {
            value: 1,
            text: 'Email Type'
        },
        {
            value: 2,
            text: 'Newsletter Template'
        },
    ];
}

function locationDataExportFunction(){
    return [

        {
            value: 'Algeria',
            text: 'Algeria',
            icon: Algeria,
            code: 2012
        },
        // {
        //     value: 'Andorra',
        //     text: 'Andorra',
        //     icon: Andorra,
        //     code: 2031
        // },
        {
            value: 'Angola',
            text: 'Angola',
            icon: Angola,
            code: 2024
        },
        // {
        //     value: 'Antigua and Barbuda',
        //     text: 'Antigua and Barbuda',
        //     icon: AntiguaBarbuda,
        //     code: 2250
        // },
        {
            value: 'Argentina',
            text: 'Argentina',
            icon: Argentina,
            code: 2032
        },
        {
            value: 'Armenia',
            text: 'Armenia',
            icon: Armenia,
            code: 2051
        },
        {
            value: 'Australia',
            text: 'Australia',
            icon: Australia,
            code: 2036
        },
        {
            value: 'Austria',
            text: 'Austria',
            icon: Austria,
            code: 2040
        },
        {
            value: 'Azerbaijan',
            text: 'Azerbaijan',
            icon: Azerbaijan,
            code: 2031
        },
        // {
        //     value: 'The Bahamas',
        //     text: 'The Bahamas',
        //     icon: Bahamas,
        //     code: 2191
        // },
        {
            value: 'Bahrain',
            text: 'Bahrain',
            icon: Bahrain,
            code: 2048
        },
        {
            value: 'Bangladesh',
            text: 'Bangladesh',
            icon: Bangladesh,
            code: 2050
        },
        // {
        //     value: 'Barbados',
        //     text: 'Barbados',
        //     icon: Barbados,
        //     code: 2233
        // },
        // {
        //     value: 'Belarus',
        //     text: 'Belarus',
        //     icon: Belarus,
        //     code: 2246
        // },
        {
            value: 'Belgium',
            text: 'Belgium',
            icon: Belgium,
            code: 2056
        },
        // {
        //     value: 'Belize',
        //     text: 'Belize',
        //     icon: Belize,
        //     code: 2360
        // },
        // {
        //     value: 'Benin',
        //     text: 'Benin',
        //     icon: Benin,
        //     code: 2376
        // },
        // {
        //     value: 'Bhutan',
        //     text: 'Bhutan',
        //     icon: Bhutan,
        //     code: 2392
        // },
        {
            value: 'Bolivia',
            text: 'Bolivia',
            icon: Bolivia,
            code: 2068

        },
        // {
        //     value: 'Bosnia and Herzegovina',
        //     text: 'Bosnia and Herzegovina',
        //     icon: BosniaHerzegovina,
        //     code: 2410
        // },
        // {
        //     value: 'Botswana',
        //     text: 'Botswana',
        //     icon: Botswana,
        //     code: 2428
        // },
        // {
        //     value: 'Brunei',
        //     text: 'Brunei',
        //     icon: Brunei,
        //     code: 2440
        // },
        {
            value: 'Bulgaria',
            text: 'Bulgaria',
            icon: Bulgaria,
            code: 2100
        },
        {
            value: 'Burkina Faso',
            text: 'Burkina Faso',
            icon: BurkinaFaso,
            code: 2854
        },
        // {
        //     value: 'Burundi',
        //     text: 'Burundi',
        //     icon: Burundi,
        //     code: 2586
        // },
        // {
        //     value: 'Cabo Verde',
        //     text: 'Cabo Verde',
        //     icon: Filipino,
        //     code: 2608
        // },
        {
            value: 'Cambodia',
            text: 'Cambodia',
            icon: Cambodia,
            code: 2116
        },
        {
            value: 'Cameroon',
            text: 'Cameroon',
            icon: Cameroon,
            code: 2120
        },
        {
            value: 'Canada',
            text: 'Canada',
            icon: Canada,
            code: 2124
        },
        // {
        //     value: 'Central African Republic',
        //     text: 'Central African Republic',
        //     icon: CentralAfricanRepublic,
        //     code: 2703
        // },
        // {
        //     value: 'Chad',
        //     text: 'Chad',
        //     icon: Chad,
        //     code: 2704
        // },
        {
            value: 'Chile',
            text: 'Chile',
            icon: Chile,
            code: 2152
        },
        // {
        //     value: 'China',
        //     text: 'China',
        //     icon: China,
        //     code: 2752
        // },
        {
            value: 'Colombia',
            text: 'Colombia',
            icon: Colombia,
            code: 2170
        },
        // {
        //     value: 'Comoros',
        //     text: 'Comoros',
        //     icon: Comoros,
        //     code: 2764
        // },
        // {
        //     value: 'Congo, Democratic Republic of the',
        //     text: 'Congo, Democratic Republic of the',
        //     icon: CongoDemocratic,
        //     code: 2792
        // },
        // {
        //     value: 'Congo, Republic of the',
        //     text: 'Congo, Republic of the',
        //     icon: Congo,
        //     code: 2804
        // },
        {
            value: 'Costa Rica',
            text: 'Costa Rica',
            icon: CostaRica,
            code: 2188
        },
        // {
        //     value: 'Côte d’Ivoire',
        //     text: 'Côte d’Ivoire',
        //     icon: CôtedIvoire,
        //     code: 2384
        // },
        // {
        //     value: 'Croatia',
        //     text: 'Croatia',
        //     icon: Croatia,
        //     code: 2191
        // },
        // {
        //     value: 'Cuba',
        //     text: 'Cuba',
        //     icon: Cuba,
        //     code: 2807
        // },
        {
            value: 'Cyprus',
            text: 'Cyprus',
            icon: Cyprus,
            code: 2196
        },
        {
            value: 'Czech Republic',
            text: 'Czech Republic',
            icon: CzechRepublic,
            code: 2203
        },
        {
            value: 'Denmark',
            text: 'Denmark',
            icon: Denmark,
            code: 2208
        },
        // {
        //     value: 'Djibouti',
        //     text: 'Djibouti',
        //     icon: Djibouti,
        //     code: 2807
        // },
        // {
        //     value: 'Dominica',
        //     text: 'Dominica',
        //     icon: Dominica,
        //     code: 2807
        // },
        // {
        //     value: 'Dominican Republic',
        //     text: 'Dominican Republic',
        //     icon: DominicanRepublic,
        //     code: 2807
        // },
        // {
        //     value: 'East Timor (Timor-Leste)',
        //     text: 'East Timor (Timor-Leste)',
        //     icon: EastTimor,
        //     code: 2807
        // },
        {
            value: 'Ecuador',
            text: 'Ecuador',
            icon: Ecuador,
            code: 2218
        },
        {
            value: 'Egypt',
            text: 'Egypt',
            icon: Egypt,
            code: 2818
        },
        {
            value: 'El Salvador',
            text: 'El Salvador',
            icon: ElSalvador,
            code: 2222
        },
        // {
        //     value: 'Equatorial Guinea',
        //     text: 'Equatorial Guinea',
        //     icon: EquatorialGuinea,
        //     code: 2807
        // },
        // {
        //     value: 'Eritrea',
        //     text: 'Eritrea',
        //     icon: Eritrea,
        //     code: 2807
        // },
        {
            value: 'Estonia',
            text: 'Estonia',
            icon: Estonia,
            code: 2233
        },
        // {
        //     value: 'Eswatini',
        //     text: 'Eswatini',
        //     icon: Eswatini,
        //     code: 2807
        // },
        // {
        //     value: 'Ethiopia',
        //     text: 'Ethiopia',
        //     icon: Ethiopia,
        //     code: 2807
        // },
        // {
        //     value: 'Fiji',
        //     text: 'Fiji',
        //     icon: Fiji,
        //     code: 2807
        // },
        {
            value: 'Finland',
            text: 'Finland',
            icon: Finland,
            code: 2246
        },
        {
            value: 'France',
            text: 'France',
            icon: France,
            code: 2250
        },
        // {
        //     value: 'Gabon',
        //     text: 'Gabon',
        //     icon: Gabon,
        //     code: 2807
        // },
        // {
        //     value: 'The Gambia',
        //     text: 'The Gambia',
        //     icon: TheGambia,
        //     code: 2807
        // },
        // {
        //     value: 'Georgia',
        //     text: 'Georgia',
        //     icon: Macedonian,
        //     code: 2807
        // },
        {
            value: 'Germany',
            text: 'Germany',
            icon: Germany,
            code: 2276
        },
        {
            value: 'Ghana',
            text: 'Ghana',
            icon: Ghana,
            code: 2288
        },
        {
            value: 'Greece',
            text: 'Greece',
            icon: Greece,
            code: 2300
        },
        // {
        //     value: 'Grenada',
        //     text: 'Grenada',
        //     icon: Grenada,
        //     code: 2807
        // },
        {
            value: 'Guatemala',
            text: 'Guatemala',
            icon: Guatemala,
            code: 2320
        },
        // {
        //     value: 'Guinea',
        //     text: 'Guinea',
        //     icon: Guinea,
        //     code: 2807
        // },
        // {
        //     value: 'Guinea-Bissau',
        //     text: 'Guinea-Bissau',
        //     icon: Macedonian,
        //     code: 2807
        // },
        // {
        //     value: 'Guyana',
        //     text: 'Guyana',
        //     icon: Guyana,
        //     code: 2807
        // },
        // {
        //     value: 'Haiti',
        //     text: 'Haiti',
        //     icon: Haiti,
        //     code: 2807
        // },
        // {
        //     value: 'Honduras',
        //     text: 'Honduras',
        //     icon: Honduras,
        //     code: 2807
        // },
        // {
        //     value: 'Hungary',
        //     text: 'Hungary',
        //     icon: Hungary,
        //     code: 2348
        // },
        // {
        //     value: 'Iceland',
        //     text: 'Iceland',
        //     icon: Iceland,
        //     code: 2807
        // },
        {
            value: 'India',
            text: 'India',
            icon: India,
            code: 2356
        },
        {
            value: 'Indonesia',
            text: 'Indonesia',
            icon: Indonesia,
            code: 2360
        },
        // {
        //     value: 'Iran',
        //     text: 'Iran',
        //     icon: Iran,
        //     code: 2807
        // },
        // {
        //     value: 'Iraq',
        //     text: 'Iraq',
        //     icon: Macedonian,
        //     code: 2807
        // },
        {
            value: 'Ireland',
            text: 'Ireland',
            icon: Ireland,
            code: 2372
        },
        {
            value: 'Israel',
            text: 'Israel',
            icon: Israel,
            code: 2376
        },
        {
            value: 'Italy',
            text: 'Italy',
            icon: Italy,
            code: 2380
        },
        // {
        //     value: 'Jamaica',
        //     text: 'Jamaica',
        //     icon: Jamaica,
        //     code: 2807
        // },
        {
            value: 'Japan',
            text: 'Japan',
            icon: Japan,
            code: 2392
        },
        {
            value: 'Jordan',
            text: 'Jordan',
            icon: Jordan,
            code: 2400
        },
        {
            value: 'Kazakhstan',
            text: 'Kazakhstan',
            icon: Kazakhstan,
            code: 2398
        },
        {
            value: 'Kenya',
            text: 'Kenya',
            icon: Kenya,
            code: 2404
        },
        // {
        //     value: 'Kiribati',
        //     text: 'Kiribati',
        //     icon: Kiribati,
        //     code: 2807
        // },
        {
            value: 'Korea, North',
            text: 'Korea, North',
            icon: KoreaNorth,
            code: 2410
        },
        // {
        //     value: 'Kosovo',
        //     text: 'Kosovo',
        //     icon: Kosovo,
        //     code: 2807
        // },
        // {
        //     value: 'Kuwait',
        //     text: 'Kuwait',
        //     icon: Kuwait,
        //     code: 2807
        // },
        // {
        //     value: 'Kyrgyzstan',
        //     text: 'Kyrgyzstan',
        //     icon: Kyrgyzstan,
        //     code: 2807
        // },
        // {
        //     value: 'Laos',
        //     text: 'Laos',
        //     icon: Laos,
        //     code: 2807
        // },
        {
            value: 'Latvia',
            text: 'Latvia',
            icon: Latvia,
            code: 2428
        },
        // {
        //     value: 'Lebanon',
        //     text: 'Lebanon',
        //     icon: Lebanon,
        //     code: 2807
        // },
        // {
        //     value: 'Lesotho',
        //     text: 'Lesotho',
        //     icon: Lesotho,
        //     code: 2807
        // },
        // {
        //     value: 'Liberia',
        //     text: 'Liberia',
        //     icon: Liberia,
        //     code: 2807
        // },
        // {
        //     value: 'Libya',
        //     text: 'Libya',
        //     icon: Libya,
        //     code: 2807
        // },
        // {
        //     value: 'Liechtenstein',
        //     text: 'Liechtenstein',
        //     icon: Liechtenstein,
        //     code: 2807
        // },
        {
            value: 'Lithuania',
            text: 'Lithuania',
            icon: Lithuania,
            code: 2440
        },
        // {
        //     value: 'Luxembourg',
        //     text: 'Luxembourg',
        //     icon: Luxembourg,
        //     code: 2807
        // },
        // {
        //     value: 'Madagascar',
        //     text: 'Madagascar',
        //     icon: Macedonian,
        //     code: 2807
        // },
        // {
        //     value: 'Malawi',
        //     text: 'Malawi',
        //     icon: Macedonian,
        //     code: 2807
        // },
        {
            value: 'Malaysia',
            text: 'Malaysia',
            icon: Malaysia,
            code: 2458
        },
        // {
        //     value: 'Maldives',
        //     text: 'Maldives',
        //     icon: Macedonian,
        //     code: 2807
        // },
        // {
        //     value: 'Mali',
        //     text: 'Mali',
        //     icon: Macedonian,
        //     code: 2807
        // },
        {
            value: 'Malta',
            text: 'Malta',
            icon: Malta,
            code: 2470
        },
        // {
        //     value: 'Marshall Islands',
        //     text: 'Marshall Islands',
        //     icon: Macedonian,
        //     code: 2807
        // },
        // {
        //     value: 'Mauritania',
        //     text: 'Mauritania',
        //     icon: Macedonian,
        //     code: 2807
        // },
        // {
        //     value: 'Mauritius',
        //     text: 'Mauritius',
        //     icon: Macedonian,
        //     code: 2807
        // },
        {
            value: 'Mexico',
            text: 'Mexico',
            icon: Mexico,
            code: 2484
        },
        // {
        //     value: 'Micronesia, Federated States of',
        //     text: 'Micronesia, Federated States of',
        //     icon: Macedonian,
        //     code: 2807
        // },
        // {
        //     value: 'Moldova',
        //     text: 'Moldova',
        //     icon: Macedonian,
        //     code: 2807
        // },
        // {
        //     value: 'Monaco',
        //     text: 'Monaco',
        //     icon: Macedonian,
        //     code: 2807
        // },
        // {
        //     value: 'Mongolia',
        //     text: 'Mongolia',
        //     icon: Macedonian,
        //     code: 2807
        // },
        // {
        //     value: 'Montenegro',
        //     text: 'Montenegro',
        //     icon: Macedonian,
        //     code: 2807
        // },
        {
            value: 'Morocco',
            text: 'Morocco',
            icon: Morocco,
            code: 2504
        },
        // {
        //     value: 'Mozambique',
        //     text: 'Mozambique',
        //     icon: Macedonian,
        //     code: 2807
        // },
        {
            value: 'Myanmar (Burma)',
            text: 'Myanmar (Burma)',
            icon: Myanmar,
            code: 2104
        },
        // {
        //     value: 'Namibia',
        //     text: 'Namibia',
        //     icon: Macedonian,
        //     code: 2807
        // },
        // {
        //     value: 'Nauru',
        //     text: 'Nauru',
        //     icon: Macedonian,
        //     code: 2807
        // },
        // {
        //     value: 'Nepal',
        //     text: 'Nepal',
        //     icon: Macedonian,
        //     code: 2807
        // },
        {
            value: 'Netherlands',
            text: 'Netherlands',
            icon: Netherlands,
            code: 2528
        },
        {
            value: 'New Zealand',
            text: 'New Zealand',
            icon: NewZealand,
            code: 2554
        },
        {
            value: 'Nicaragua',
            text: 'Nicaragua',
            icon: Nicaragua,
            code: 2558
        },
        {
            value: 'Niger',
            text: 'Niger',
            icon: Niger,
            code: 2566
        },
        {
            value: 'Nigeria',
            text: 'Nigeria',
            icon: Nigeria,
            code: 2558
        },
        {
            value: 'North Macedonia',
            text: 'North Macedonia',
            icon: NorthMacedonia,
            code: 2807
        },
        {
            value: 'Norway',
            text: 'Norway',
            icon: Norway,
            code: 2578
        },
        // {
        //     value: 'Oman',
        //     text: 'Oman',
        //     icon: Macedonian,
        //     code: 2807
        // },
        {
            value: 'Pakistan',
            text: 'Pakistan',
            icon: Pakistan,
            code: 2586
        },
        // {
        //     value: 'Palau',
        //     text: 'Palau',
        //     icon: Macedonian,
        //     code: 2807
        // },
        {
            value: 'Panama',
            text: 'Panama',
            icon: Panama,
            code: 2591
        },
        // {
        //     value: 'Papua New Guinea',
        //     text: 'Papua New Guinea',
        //     icon: Macedonian,
        //     code: 2807
        // },
        {
            value: 'Paraguay',
            text: 'Paraguay',
            icon: Paraguay,
            code: 2600
        },
        {
            value: 'Peru',
            text: 'Peru',
            icon: Peru,
            code: 2604
        },
        {
            value: 'Philippines',
            text: 'Philippines',
            icon: Philippines,
            code: 2608
        },
        {
            value: 'Poland',
            text: 'Poland',
            icon: Poland,
            code: 2616
        },
        {
            value: 'Portugal',
            text: 'Portugal',
            icon: Portugal,
            code: 2620
        },
        // {
        //     value: 'Qatar',
        //     text: 'Qatar',
        //     icon: Macedonian,
        //     code: 2807
        // },
        {
            value: 'Romania',
            text: 'Romania',
            icon: Romania,
            code: 2642
        },
        {
            value: 'Russia',
            text: 'Russia',
            icon: Russia,
            code: 2804
        },
        // {
        //     value: 'Rwanda',
        //     text: 'Rwanda',
        //     icon: Macedonian,
        //     code: 2807
        // },
        // {
        //     value: 'Saint Kitts and Nevis',
        //     text: 'Saint Kitts and Nevis',
        //     icon: Macedonian,
        //     code: 2807
        // },
        // {
        //     value: 'Saint Lucia',
        //     text: 'Saint Lucia',
        //     icon: Macedonian,
        //     code: 2807
        // },
        // {
        //     value: 'Saint Vincent and the Grenadines',
        //     text: 'Saint Vincent and the Grenadines',
        //     icon: Macedonian,
        //     code: 2807
        // },
        // {
        //     value: 'Samoa',
        //     text: 'Samoa',
        //     icon: Macedonian,
        //     code: 2807
        // },
        // {
        //     value: 'San Marino',
        //     text: 'San Marino',
        //     icon: Macedonian,
        //     code: 2807
        // },
        // {
        //     value: 'Sao Tome and Principe',
        //     text: 'Sao Tome and Principe',
        //     icon: Macedonian,
        //     code: 2807
        // },
        {
            value: 'Saudi Arabia',
            text: 'Saudi Arabia',
            icon: SaudiArabia,
            code: 2807
        },
        {
            value: 'Senegal',
            text: 'Senegal',
            icon: Senegal,
            code: 2686
        },
        {
            value: 'Serbia',
            text: 'Serbia',
            icon: Serbia,
            code: 2688
        },
        // {
        //     value: 'Seychelles',
        //     text: 'Seychelles',
        //     icon: Macedonian,
        //     code: 2807
        // },
        // {
        //     value: 'Sierra Leone',
        //     text: 'Sierra Leone',
        //     icon: Macedonian,
        //     code: 2807
        // },
        {
            value: 'Singapore',
            text: 'Singapore',
            icon: Singapore,
            code: 2702
        },
        {
            value: 'Slovakia',
            text: 'Slovakia',
            icon: Slovakia,
            code: 2703
        },
        // {
        //     value: 'Solomon Islands',
        //     text: 'Solomon Islands',
        //     icon: Macedonian,
        //     code: 2807
        // },
        // {
        //     value: 'Somalia',
        //     text: 'Somalia',
        //     icon: Macedonian,
        //     code: 2807
        // },
        {
            value: 'South Africa',
            text: 'South Africa',
            icon: SouthAfrica,
            code: 2710
        },
        {
            value: 'Spain',
            text: 'Spain',
            icon: Spain,
            code: 2724
        },
        {
            value: 'Sri Lanka',
            text: 'Sri Lanka',
            icon: SriLanka,
            code: 2144
        },
        // {
        //     value: 'Sudan',
        //     text: 'Sudan',
        //     icon: Macedonian,
        //     code: 2807
        // },
        // {
        //     value: 'Sudan, South',
        //     text: 'Sudan, South',
        //     icon: Macedonian,
        //     code: 2807
        // },
        // {
        //     value: 'Suriname',
        //     text: 'Suriname',
        //     icon: Macedonian,
        //     code: 2807
        // },
        {
            value: 'Sweden',
            text: 'Sweden',
            icon: Sweden,
            code: 2752
        },
        {
            value: 'Switzerland',
            text: 'Switzerland',
            icon: Switzerland,
            code: 2756
        },
        // {
        //     value: 'Syria',
        //     text: 'Syria',
        //     icon: Macedonian,
        //     code: 2807
        // },
        {
            value: 'Taiwan',
            text: 'Taiwan',
            icon: Taiwan,
            code: 2158
        },
        // {
        //     value: 'Tajikistan',
        //     text: 'Tajikistan',
        //     icon: Macedonian,
        //     code: 2807
        // },
        // {
        //     value: 'Tanzania',
        //     text: 'Tanzania',
        //     icon: Macedonian,
        //     code: 2807
        // },
        {
            value: 'Thailand',
            text: 'Thailand',
            icon: Thailand,
            code: 2764
        },
        // {
        //     value: 'Togo',
        //     text: 'Togo',
        //     icon: Macedonian,
        //     code: 2807
        // },
        // {
        //     value: 'Tonga',
        //     text: 'Tonga',
        //     icon: Macedonian,
        //     code: 2807
        // },
        // {
        //     value: 'Trinidad and Tobago',
        //     text: 'Trinidad and Tobago',
        //     icon: Macedonian,
        //     code: 2807
        // },
        {
            value: 'Tunisia',
            text: 'Tunisia',
            icon: Tunisia,
            code: 2788
        },
        // {
        //     value: 'Turkey',
        //     text: 'Turkey',
        //     icon: Macedonian,
        //     code: 2792
        // },
        // // {
        // //     value: 'Turkmenistan',
        // //     text: 'Turkmenistan',
        // //     icon: Macedonian,
        // //     code:
        // // },
        // // {
        // //     value: 'Tuvalu',
        // //     text: 'Tuvalu',
        // //     icon: Macedonian,
        // //     code: 2807
        // // },
        // // {
        // //     value: 'Uganda',
        // //     text: 'Uganda',
        // //     icon: Macedonian,
        // //     code:
        // // },
        {
            value: 'Ukraine',
            text: 'Ukraine',
            icon: Ukraine,
            code: 2804
        },
        {
            value: 'United Arab Emirates',
            text: 'United Arab Emirates',
            icon: UnitedArabEmirates,
            code: 2784
        },
        {
            value: 'United Kingdom',
            text: 'United Kingdom',
            icon: UnitedKingdom,
            code: 2826
        },
        {
            value: 'United States',
            text: 'United States',
            icon: UnitedStates,
            code: 2840
        },
        {
            value: 'Uruguay',
            text: 'Uruguay',
            icon: Uruguay,
            code: 2858
        },
        // {
        //     value: 'Uzbekistan',
        //     text: 'Uzbekistan',
        //     icon: Macedonian,
        //     code:
        // },
        // {
        //     value: 'Vanuatu',
        //     text: 'Vanuatu',
        //     icon: Macedonian,
        //     code:
        // },
        // {
        //     value: 'Vatican City',
        //     text: 'Vatican City',
        //     icon: Macedonian,
        //     code:
        // },
        {
            value: 'Venezuela',
            text: 'Venezuela',
            icon: Macedonian,
            code: 2862
        },
        {
            value: 'Vietnam',
            text: 'Vietnam',
            icon: Macedonian,
            code: 2704
        },
        // {
        //     value: 'Yemen',
        //     text: 'Yemen',
        //     icon: Macedonian,
        //     code:
        // },
        // {
        //     value: 'Zambia',
        //     text: 'Zambia',
        //     icon: Macedonian,
        //     code:
        // },
        // {
        //     value: 'Zimbabwe',
        //     text: 'Zimbabwe',
        //     icon: Macedonian,
        //     code:
        // },
    ];
}

function locationDataForAmazonExportFunction(){
    return [
        {
            value: 'United States',
            text: 'United States',
            icon: UnitedStates,
            code: 2840
        },
        {
            value: 'North Macedonia',
            text: 'North Macedonia',
            icon: NorthMacedonia,
            code: 2807
        },
        {
            value: 'United Arab Emirates',
            text: 'United Arab Emirates',
            icon: UnitedArabEmirates,
            code: 2784
        },
    ];
}

function locationDataForBingExportFunction(){
    return [
        {
            value: 'United States',
            text: 'United States',
            icon: UnitedStates,
            code: 2840
        }
    ];
}

function keywordSearchEngineExportFunction(){
    return {
        "value": 1,
        "text": "Google",
        "icon": <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_279_1406)">
                <path d="M15.3957 6.60895L8.86944 6.60864C8.58125 6.60864 8.34766 6.84221 8.34766 7.13039V9.21524C8.34766 9.50336 8.58125 9.73699 8.86941 9.73699H12.5446C12.1422 10.7814 11.391 11.656 10.4327 12.2118L11.9998 14.9246C14.5136 13.4707 15.9998 10.9198 15.9998 8.06427C15.9998 7.65767 15.9698 7.36702 15.9099 7.03974C15.8643 6.79108 15.6485 6.60895 15.3957 6.60895Z" fill="#167EE6" />
                <path d="M7.99985 12.8695C6.20129 12.8695 4.63116 11.8868 3.78788 10.4326L1.0752 11.9962C2.45566 14.3887 5.04173 15.9999 7.99985 15.9999C9.45101 15.9999 10.8203 15.6092 11.9999 14.9283V14.9246L10.4328 12.2117C9.71595 12.6275 8.88645 12.8695 7.99985 12.8695Z" fill="#12B347" />
                <path d="M12 14.9284V14.9247L10.4329 12.2119C9.71609 12.6276 8.88666 12.8696 8 12.8696V16.0001C9.45116 16.0001 10.8205 15.6094 12 14.9284Z" fill="#0F993E" />
                <path d="M3.13044 7.99994C3.13044 7.11341 3.37238 6.28401 3.78803 5.56722L1.07534 4.00366C0.390688 5.17951 0 6.54507 0 7.99994C0 9.45482 0.390688 10.8204 1.07534 11.9962L3.78803 10.4327C3.37238 9.71588 3.13044 8.88647 3.13044 7.99994Z" fill="#FFD500" />
                <path d="M7.99985 3.13044C9.1727 3.13044 10.25 3.54719 11.0915 4.24041C11.2991 4.41141 11.6008 4.39906 11.7909 4.20891L13.2681 2.73172C13.4839 2.51597 13.4685 2.16281 13.238 1.96288C11.8282 0.739781 9.99382 0 7.99985 0C5.04173 0 2.45566 1.61116 1.0752 4.00372L3.78788 5.56728C4.63116 4.11313 6.20129 3.13044 7.99985 3.13044Z" fill="#FF4B26" />
                <path d="M11.0916 4.24041C11.2992 4.41141 11.601 4.39906 11.7911 4.20891L13.2683 2.73172C13.484 2.51597 13.4686 2.16281 13.2382 1.96288C11.8283 0.73975 9.99397 0 8 0V3.13044C9.17281 3.13044 10.2502 3.54719 11.0916 4.24041Z" fill="#D93F21" />
            </g>
            <defs>
                <clipPath id="clip0_279_1406">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    };
}

function keywordDensityOptionExportFunction(){
    return {
        'value': 1,
        'text': '1%'
    };
}

export{
    langDataExportFunction,
    langDataExportAutomateFunction,
    intentDataExportFunction,
    KeywordSearchEngineDataExportFunction,
    KeywordDensityDataExportFunction,
    EmailTypeDataExportFunction,
    locationDataExportFunction,
    locationDataForAmazonExportFunction,
    locationDataForBingExportFunction,
    keywordSearchEngineExportFunction,
    keywordDensityOptionExportFunction
}