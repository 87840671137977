import { getTokenSession } from './Auth';

const CreateAudienceController = async (ThemeContextData, data, id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        audience_name: data.name,
        audience_desc: data.description,
        project_id: id
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw,
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'audience/create-audience',
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {
            return { success: true};
        } else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        console.error("Error scheduling article:", error);
        return false;
    }
};

const GetAudienceController = async (ThemeContextData, setTryLater, id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    
    const raw = JSON.stringify({
        project_id: id
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw,
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'audience/project-audiences',
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {
            return { success: true , data: result.response};
        } else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        setTryLater(true)
        console.error("Error scheduling article:", error);
        return false;
    }
};

const DeleteAudienceController = async (ThemeContextData, id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        audience_id: id,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw,
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'audience/delete-audience',
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {
            return { success: true};
        } else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        console.error("Error scheduling article:", error);
        return false;
    }
};

const UpdateAudienceController = async (ThemeContextData, data, id, project_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        audience_name: data.name,
        audience_desc: data.description,
        project_id: project_id,
        audience_id: id
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw,
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'audience/update-audience',
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {
            return { success: true};
        } else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        console.error("Error scheduling article:", error);
        return false;
    }
};

export{
    CreateAudienceController,
    GetAudienceController,
    DeleteAudienceController,
    UpdateAudienceController
}