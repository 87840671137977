import { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';

export default function SelectDropDown(props) {

    return (
        <>
            <Form.Group className="mb-3">
                {props.label !== undefined
                    ? <Form.Label> {props.label}</Form.Label>
                    : ""}
                <Select
                    value={props.value}
                    name={props.name}
                    placeholder={
                        props.type === "flag" ?
                            <div className='group'>
                                <img src={props.option[0].flag} alt='flag' />
                                <span>{props.option[0].text}</span>
                            </div>
                            : props.type === "icon" ?
                                <div className='group'>
                                    {props.option && props.option[0] && props.option[0].icon ? props.option[0].icon : ""}
                                    <span>{props.option && props.option[0] && props.option[0].text ? props.option[0].text : ""}</span>
                                </div>
                                :
                                <div className='group'>
                                    <span>{props.option[0].text}</span>
                                </div>
                    }
                    menuPlacement={
                        props.menuPlacement ? "top" : "bottom"
                    } 
                    options={props.option}
                    onChange={props.callfunction}
                    className={props.class}
                    isSearchable={false}
                    getOptionLabel={e =>
                        props.type === "flag" ?
                            <div className='group'>
                                <img src={e.flag} alt='flag' />
                                <span>{e.text}</span>
                            </div>
                            : props.type === "icon" ?
                                <div className='group'>
                                    {e.icon}
                                    <span>{e.text}</span>
                                </div> :
                                <div className='group'>
                                    <span>{e.text}</span>
                                </div>
                    }
                />
            </Form.Group >
        </>
    )
}