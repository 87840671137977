import React, { useContext, useEffect, useState } from 'react';

import { Box, Divider, Drawer, Stack, Typography } from '@mui/material';

import { useSamplesDrawerOpen, resetDocument } from '../documents/editor/EditorContext.tsx';
import { useLocation } from 'react-router-dom';
import SidebarButton from './SidebarButton.tsx';
import logo from './waypoint.svg';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { ThemeContext } from '../../../projectContext/ThemeContext';
import { GetAllEmailBuilderController } from '../../../provider/EmailBuilderControlller.js';
import dottedLoaderSmall from '../../../assets/images/dottedLoaderSmall.gif';
import { GetCustomEmailBuilderController } from '../../../provider/EmailBuilderControlller.js';

export const SAMPLES_DRAWER_WIDTH = 240;

export default function SamplesDrawer(props) {
  const samplesDrawerOpen = useSamplesDrawerOpen();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ThemeContextData = useContext(ThemeContext);
  const [templates, setTemplates] = useState([]);
  const [loader, setLoader] = useState(false);
  const [temp_id, setTemp_id] = useState(0);


  const getTemplateList = async () => {
    const result = await GetAllEmailBuilderController(ThemeContextData);
    if (result.success) {
      setTemplates(result.data);
      setLoader(true)
    }
  }

  useEffect(() => {
    getTemplateList()
  }, [])

  const getTemplateFunc = async (id) => {
    // const result = await GetEmailBuilderController(ThemeContextData, queryParams.get('campaign_id'));
    // email-templates/get-email-template
    const result = await GetCustomEmailBuilderController(ThemeContextData, id);

    if (result.success) {
      // let obj = result.content_json;
      // resetDocument(obj)

      if (result.data && result.data.template_data) {
        props.setPageTitle(result.data.template_name);
        let obj = JSON.parse(result.data.template_data);
        // console.log(obj);
        resetDocument(obj)
      }
    }
  }



  const createTemplateHandler = () => {

  }

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={samplesDrawerOpen}
      sx={{

      }}
    >
      {window.location.pathname === "/email-campaign" ?
        <div className='p-2 template-list-panel'>
          <Typography variant="h6" component="h1" sx={{ p: 0.75 }}>
            Pre-Build Templates
          </Typography>
          <ul className='d-flex flex-column list-unstyled folder-list'>
            {!loader ? <li style={{ display: 'flex', justifyContent: 'center', }}>
              <img src={dottedLoaderSmall} alt='dotted' className='img-fluid' style={{ width: '64px', height: '64px' }} />
            </li> :
              <>
                {templates && templates.length > 0 && templates.map(item =>
                  <li data-id={item.id} className={props.temp_id === item.id ? "default" : ""} style={{ cursor: 'pointer' }}>
                    <a onClick={() => {
                      props.setTemp_id(item.id);
                      getTemplateFunc(item.id);
                    }}>
                      <div className='d-flex flex-grow-1 align-left'>
                        <span className="icon-file"></span>
                        <span className='text'>{item.template_name}</span>
                      </div>
                    </a>
                  </li>
                )}
              </>
            }
          </ul>
        </div>
        : ""}
    </Drawer>
  );
}
