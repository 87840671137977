import React, { useContext, useEffect, useState } from 'react';

import { ArrowDownwardOutlined, ArrowUpwardOutlined, DeleteOutlined } from '@mui/icons-material';
import { IconButton, Paper, Stack, SxProps, Tooltip } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';

import { TEditorBlock } from '../../../editor/core.tsx';
import { resetDocument, setSelectedBlockId, useDocument } from '../../../editor/EditorContext.tsx';
import { ColumnsContainerProps } from '../../ColumnsContainer/ColumnsContainerPropsSchema.ts';
import { ThemeContext } from '../../../../../../projectContext/ThemeContext.js';
import UpgradeUserPlanModal from '../../../../../UpgradeUserPlanModal/UpgradeUserPlanModal.jsx';
import { EmailCampaignRegenerate, AudienceCampaignController } from '../../../../../../provider/EmailBuilderControlller.js';

const sx: SxProps = {
  position: 'absolute',
  top: 0,
  left: -56,
  borderRadius: 64,
  paddingX: 0.5,
  paddingY: 1,
  zIndex: 'fab',
};

type Props = {
  blockId: string;
};
export default function TuneMenu({ blockId }: Props) {
  const document = useDocument();

  const ThemeContextData = useContext(ThemeContext);
  const [blanceExhaustedModal, setBlanceExhaustedModal] = useState(false);
  const [reGenerate, setReGenerate] = useState(false);

  useEffect(() => {
    if (window.location.pathname !== "/email-template") {
      const nDocument: typeof document = { ...document };

      let obj = nDocument[blockId];
      if (obj.type === "Heading" || obj.type === "Text") {
        setReGenerate(true);
      } else {
        setReGenerate(false);
      }
    } else{
      setReGenerate(false);
    }
  }, [blockId])

  const AIHelp = async () => {
    const nDocument: typeof document = { ...document };

    let obj = nDocument[blockId];

    let newIndexObject = { role: "system", content: "You are a helpful assistant." };
    let messagesDataArr = [{ role: "user", content: "Regenerate: " + obj.data.props.text + " for " + localStorage.getItem("campaign_audience") }];

    messagesDataArr = [newIndexObject].concat(messagesDataArr);

    const result = await EmailCampaignRegenerate(ThemeContextData, messagesDataArr);

    if (result.success) {
      obj.data.props.text = result.data;
      console.log(obj);

      nDocument[blockId] = obj;
      console.log(nDocument);

      resetDocument(nDocument)
    } else if (result.message === "balance exhausted") {
      setBlanceExhaustedModal(true)
    }

  }

  const exhaustedModalCloser = () => {
    setBlanceExhaustedModal(false);
  }

  const handleDeleteClick = () => {
    const filterChildrenIds = (childrenIds: string[] | null | undefined) => {
      if (!childrenIds) {
        return childrenIds;
      }
      return childrenIds.filter((f) => f !== blockId);
    };
    const nDocument: typeof document = { ...document };
    for (const [id, b] of Object.entries(nDocument)) {
      const block = b as TEditorBlock;
      if (id === blockId) {
        continue;
      }
      switch (block.type) {
        case 'EmailLayout':
          nDocument[id] = {
            ...block,
            data: {
              ...block.data,
              childrenIds: filterChildrenIds(block.data.childrenIds),
            },
          };
          break;
        case 'Container':
          nDocument[id] = {
            ...block,
            data: {
              ...block.data,
              props: {
                ...block.data.props,
                childrenIds: filterChildrenIds(block.data.props?.childrenIds),
              },
            },
          };
          break;
        case 'ColumnsContainer':
          nDocument[id] = {
            type: 'ColumnsContainer',
            data: {
              style: block.data.style,
              props: {
                ...block.data.props,
                columns: block.data.props?.columns?.map((c) => ({
                  childrenIds: filterChildrenIds(c.childrenIds),
                })),
              },
            } as ColumnsContainerProps,
          };
          break;
        default:
          nDocument[id] = block;
      }
    }
    delete nDocument[blockId];
    resetDocument(nDocument);
  };

  const handleMoveClick = (direction: 'up' | 'down') => {
    const moveChildrenIds = (ids: string[] | null | undefined) => {
      if (!ids) {
        return ids;
      }
      const index = ids.indexOf(blockId);
      if (index < 0) {
        return ids;
      }
      const childrenIds = [...ids];
      if (direction === 'up' && index > 0) {
        [childrenIds[index], childrenIds[index - 1]] = [childrenIds[index - 1], childrenIds[index]];
      } else if (direction === 'down' && index < childrenIds.length - 1) {
        [childrenIds[index], childrenIds[index + 1]] = [childrenIds[index + 1], childrenIds[index]];
      }
      return childrenIds;
    };
    const nDocument: typeof document = { ...document };
    for (const [id, b] of Object.entries(nDocument)) {
      const block = b as TEditorBlock;
      if (id === blockId) {
        continue;
      }
      switch (block.type) {
        case 'EmailLayout':
          nDocument[id] = {
            ...block,
            data: {
              ...block.data,
              childrenIds: moveChildrenIds(block.data.childrenIds),
            },
          };
          break;
        case 'Container':
          nDocument[id] = {
            ...block,
            data: {
              ...block.data,
              props: {
                ...block.data.props,
                childrenIds: moveChildrenIds(block.data.props?.childrenIds),
              },
            },
          };
          break;
        case 'ColumnsContainer':
          nDocument[id] = {
            type: 'ColumnsContainer',
            data: {
              style: block.data.style,
              props: {
                ...block.data.props,
                columns: block.data.props?.columns?.map((c) => ({
                  childrenIds: moveChildrenIds(c.childrenIds),
                })),
              },
            } as ColumnsContainerProps,
          };
          break;
        default:
          nDocument[id] = block;
      }
    }

    resetDocument(nDocument);
    setSelectedBlockId(blockId);
  };

  return (
    <>
      <Paper sx={sx} onClick={(ev) => ev.stopPropagation()}>
        <Stack>
          <Tooltip title="Move up" placement="left-start">
            <IconButton onClick={() => handleMoveClick('up')} sx={{ color: 'text.primary' }}>
              <ArrowUpwardOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Move down" placement="left-start">
            <IconButton onClick={() => handleMoveClick('down')} sx={{ color: 'text.primary' }}>
              <ArrowDownwardOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="left-start">
            <IconButton onClick={handleDeleteClick} sx={{ color: 'text.primary' }}>
              <DeleteOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
          {reGenerate ? <Tooltip title="AI generate" placement="left-start">
            <IconButton onClick={() => AIHelp()} sx={{ color: 'text.primary' }}>
              <CachedIcon fontSize="small" />
            </IconButton>
          </Tooltip> : ""}
        </Stack>
      </Paper>
      <UpgradeUserPlanModal show={blanceExhaustedModal} exhaustedModalCloser={exhaustedModalCloser} />
    </>
  );
}
