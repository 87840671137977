import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { MessageString } from '../../stringOptions/MessageString';

export default function AnErrorOccured({showError,setShowError}) {
    
    // const [errorDisplay, setErrorDisplay] = useState(props.showError)
    const messageString = MessageString();

    useEffect(() => {
        if (showError) {
            const timer = setTimeout(() => {
                // Automatically hide the error message after 5 seconds
                setShowError(false);
            }, 5000);

            return () => clearTimeout(timer); // Cleanup timer on component unmount
        }
    }, [showError]);

    return (
        <>
            {showError ? (
                <Alert className="try-later" variant="danger">
                    {messageString.errorOccured}
                </Alert>
            ) : null}
        </>
    );
}
