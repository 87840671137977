import React, { useState, useContext, useEffect } from 'react'
import { Box, Typography } from '@mui/material';
import { Button } from 'react-bootstrap';
import { savedImages } from '../../../provider/PictureMeController';
import { getGalleryImage } from '../../../provider/ArticleWriterControllerTools';
import { fetchPictureMeAPI } from '../../../provider/ArticleWriterControllerTools';
import { ThemeContext } from '../../../projectContext/ThemeContext';
import dottedLoaderSmall from '../../../assets/images/dottedLoaderSmall.gif'
import { useLocation } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';
import { fetchFreepikImages } from '../../../provider/ArticleWriterControllerTools';
import GalleryImageModal from '../../GalleryImageModal/GalleryImageModal';

export default function Gallery(props) {

    const ThemeContextData = useContext(ThemeContext);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [selectedFile, setSelectedFile] = useState(null);
    const [galleryImageModalShow, setGalleryImageModalShow] = useState(false);
    const [key, setKey] = useState('pictureme');
    const [blanceExhaustedModal, setBlanceExhaustedModal] = useState(false);
    const [gallery, setGallery] = useState({
        loader: false,
        searchField: "",
        images: [],
        limit: 12
    })
    const [pictureme, setPictureme] = useState({
        loader: false,
        searchField: "",
        images: [],
        limit: 12
    })
    const [stockimageState, setStockimageState] = useState({
        loader: false,
        searchField: "",
        images: [],
        limit: 12
    })
    const [featureModalStockimageState, setFeatureModalStockimageState] = useState({
        loader: false,
        searchField: "",
        images: [],
        limit: 12
    })

    const GalleryAPI = async () => {

        setGallery((prev) => ({
            ...prev,
            loader: true,
        }));

        const result = await getGalleryImage(ThemeContextData);

        if (result.success) {
            let imageArr = [];
            result.data.forEach(item => {
                imageArr.push({ image_url: item.image_url });
            });

            setGallery((prev) => ({
                ...prev,
                loader: false,
                images: imageArr,
            }));

        }
    }
    const picturemeAPI2 = async () => {

        setPictureme((prev) => ({
            ...prev,
            loader: true,
        }));

        const result = await savedImages(ThemeContextData);

        if (result && result.length > 0) {
            let imageArr = [];
            result.forEach(item => {
                imageArr.push({ image_url: item.image_url });
            });

            setPictureme((prev) => ({
                ...prev,
                loader: false,
                images: imageArr,
            }));

        }
    }

    const PictureMeAPI = async () => {

        setPictureme((prev) => ({
            ...prev,
            loader: true,
        }));

        console.log(props.project_Id);
        const result = await fetchPictureMeAPI(ThemeContextData, props.project_Id);

        if (result && result.ok) {
            let imageArr = [];
            result.response.images.forEach(item => {
                imageArr.push({ image_url: item.image_url });
            });

            setPictureme((prev) => ({
                ...prev,
                loader: false,
                images: imageArr,
            }));
        }
    }

    useEffect(() => {
        GalleryAPI()
        if (queryParams.get('email-template')) {
            picturemeAPI2()
        } else
            if (queryParams.get('email-campaign')) {
                PictureMeAPI()
            }

    }, [])

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.size <= 2 * 1024 * 1024) {
            setSelectedFile(file);
        } else {
            alert("File size should be less than 2MB");
        }
    };

    const freepikAPICall = async (e) => {
        e.preventDefault();

        setStockimageState((prev) => ({
            ...prev,
            loader: true,
            limit: 12,
            images: [],
        }));

        setFeatureModalStockimageState((prev) => ({
            ...prev,
            loader: true,
            limit: 12,
            images: [],
        }));

        const searchField =
            stockimageState.searchField || featureModalStockimageState.searchField;

        const result = await fetchFreepikImages(ThemeContextData, searchField);

        if (result && result.response) {
            if (!result.response.ok && result.response.message === "Balance Exhausted") {
                setBlanceExhaustedModal(true);
            } else {
                setStockimageState((prev) => ({
                    ...prev,
                    loader: false,
                    images: result.response,
                }));
                setFeatureModalStockimageState((prev) => ({
                    ...prev,
                    loader: false,
                    images: result.response,
                }));
            }
        } else {
            setStockimageState((prev) => ({
                ...prev,
                loader: false,
            }));
            setFeatureModalStockimageState((prev) => ({
                ...prev,
                loader: false,
            }));
        }
    }

    return (
        <div>
            <Box sx={{ m: 1, p: 1, }}>
                <Tabs
                    id="Article-writer-tab"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="article-writer-tabs"
                >
                    <Tab eventKey="pictureme" title={<>
                        <svg width="18" height="14" viewBox="0 0 18 14" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.875 0.125H2.125C1.79348 0.125 1.47554 0.256696 1.24112 0.491116C1.0067 0.725537 0.875 1.04348 0.875 1.375V12.625C0.875 12.9565 1.0067 13.2745 1.24112 13.5089C1.47554 13.7433 1.79348 13.875 2.125 13.875H15.875C16.2065 13.875 16.5245 13.7433 16.7589 13.5089C16.9933 13.2745 17.125 12.9565 17.125 12.625V1.375C17.125 1.04348 16.9933 0.725537 16.7589 0.491116C16.5245 0.256696 16.2065 0.125 15.875 0.125ZM15.875 1.375V9.40234L13.8383 7.36641C13.7222 7.2503 13.5844 7.1582 13.4327 7.09537C13.281 7.03253 13.1185 7.00019 12.9543 7.00019C12.7901 7.00019 12.6276 7.03253 12.4759 7.09537C12.3242 7.1582 12.1864 7.2503 12.0703 7.36641L10.5078 8.92891L7.07031 5.49141C6.83592 5.25716 6.5181 5.12558 6.18672 5.12558C5.85534 5.12558 5.53752 5.25716 5.30312 5.49141L2.125 8.66953V1.375H15.875ZM2.125 10.4375L6.1875 6.375L12.4375 12.625H2.125V10.4375ZM15.875 12.625H14.2055L11.393 9.8125L12.9555 8.25L15.875 11.1703V12.625ZM10.25 4.8125C10.25 4.62708 10.305 4.44582 10.408 4.29165C10.511 4.13748 10.6574 4.01732 10.8287 3.94636C11 3.87541 11.1885 3.85684 11.3704 3.89301C11.5523 3.92919 11.7193 4.01848 11.8504 4.14959C11.9815 4.2807 12.0708 4.44775 12.107 4.6296C12.1432 4.81146 12.1246 4.99996 12.0536 5.17127C11.9827 5.34257 11.8625 5.48899 11.7083 5.592C11.5542 5.69502 11.3729 5.75 11.1875 5.75C10.9389 5.75 10.7004 5.65123 10.5246 5.47541C10.3488 5.2996 10.25 5.06114 10.25 4.8125Z"
                                fill="white" />
                        </svg>
                        PictureMe</>
                    }>
                        <div className='pictureme_tab_content'>
                            {pictureme.loader ?
                                <img className='smallDottedMedium' src={dottedLoaderSmall}
                                    alt='' /> :
                                <>
                                    {pictureme.images.length > 0 ? (
                                        pictureme.images.slice(0, pictureme.limit).map((item, index) => (
                                            <div className='img-wrap position-relative' key={index}>
                                                <span onClick={() => navigator.clipboard.writeText(item.image_url.includes('pictureme/') ? ThemeContextData.apiURL + item.image_url :
                                                    ThemeContextData.apiURL + 'pictureme/' + item.image_url)} className='position-absolute copy-icon' style={{ cursor: "pointer", }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" viewBox="0 0 256 256"><path d="M240,88.23a54.43,54.43,0,0,1-16,37L189.25,160a54.27,54.27,0,0,1-38.63,16h-.05A54.63,54.63,0,0,1,96,119.84a8,8,0,0,1,16,.45A38.62,38.62,0,0,0,150.58,160h0a38.39,38.39,0,0,0,27.31-11.31l34.75-34.75a38.63,38.63,0,0,0-54.63-54.63l-11,11A8,8,0,0,1,135.7,59l11-11A54.65,54.65,0,0,1,224,48,54.86,54.86,0,0,1,240,88.23ZM109,185.66l-11,11A38.41,38.41,0,0,1,70.6,208h0a38.63,38.63,0,0,1-27.29-65.94L78,107.31A38.63,38.63,0,0,1,144,135.71a8,8,0,0,0,16,.45A54.86,54.86,0,0,0,144,96a54.65,54.65,0,0,0-77.27,0L32,130.75A54.62,54.62,0,0,0,70.56,224h0a54.28,54.28,0,0,0,38.64-16l11-11A8,8,0,0,0,109,185.66Z"></path></svg>
                                                </span>
                                                <img
                                                    src={
                                                        item.image_url.includes('pictureme/') ? ThemeContextData.apiURL + item.image_url :
                                                            ThemeContextData.apiURL + 'pictureme/' + item.image_url}
                                                    alt="Gallery Image"
                                                    className='img-fluid'
                                                />
                                            </div>
                                        ))
                                    ) : null}
                                </>
                            }
                        </div>
                        {pictureme.limit < pictureme.images.length ?
                            <div className='pt-4 d-flex justify-content-center'>
                                <Button variant='warning' onClick={() => {
                                    setPictureme((prev) => {
                                        return {
                                            ...prev,
                                            limit: prev.limit + 12
                                        }
                                    })
                                }}
                                >Load More</Button>
                            </div> : ""}
                    </Tab>
                    <Tab eventKey="gallery" title={<>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.25 0.5H4.25C3.91848 0.5 3.60054 0.631696 3.36612 0.866116C3.1317 1.10054 3 1.41848 3 1.75V3H1.75C1.41848 3 1.10054 3.1317 0.866116 3.36612C0.631696 3.60054 0.5 3.91848 0.5 4.25V14.25C0.5 14.5815 0.631696 14.8995 0.866116 15.1339C1.10054 15.3683 1.41848 15.5 1.75 15.5H11.75C12.0815 15.5 12.3995 15.3683 12.6339 15.1339C12.8683 14.8995 13 14.5815 13 14.25V13H14.25C14.5815 13 14.8995 12.8683 15.1339 12.6339C15.3683 12.3995 15.5 12.0815 15.5 11.75V1.75C15.5 1.41848 15.3683 1.10054 15.1339 0.866116C14.8995 0.631696 14.5815 0.5 14.25 0.5ZM4.25 1.75H14.25V7.17031L12.9453 5.86562C12.7109 5.63138 12.3931 5.4998 12.0617 5.4998C11.7303 5.4998 11.4125 5.63138 11.1781 5.86562L5.29453 11.75H4.25V1.75ZM11.75 14.25H1.75V4.25H3V11.75C3 12.0815 3.1317 12.3995 3.36612 12.6339C3.60054 12.8683 3.91848 13 4.25 13H11.75V14.25ZM14.25 11.75H7.0625L12.0625 6.75L14.25 8.9375V11.75ZM7.375 6.75C7.74584 6.75 8.10835 6.64003 8.41669 6.43401C8.72504 6.22798 8.96536 5.93514 9.10727 5.59253C9.24919 5.24992 9.28632 4.87292 9.21397 4.50921C9.14163 4.14549 8.96305 3.8114 8.70083 3.54917C8.4386 3.28695 8.10451 3.10837 7.74079 3.03603C7.37708 2.96368 7.00008 3.00081 6.65747 3.14273C6.31486 3.28464 6.02202 3.52496 5.81599 3.83331C5.60997 4.14165 5.5 4.50416 5.5 4.875C5.5 5.37228 5.69754 5.84919 6.04917 6.20083C6.40081 6.55246 6.87772 6.75 7.375 6.75ZM7.375 4.25C7.49861 4.25 7.61945 4.28666 7.72223 4.35533C7.82501 4.42401 7.90512 4.52162 7.95243 4.63582C7.99973 4.75003 8.01211 4.87569 7.98799 4.99693C7.96388 5.11817 7.90435 5.22953 7.81694 5.31694C7.72953 5.40435 7.61817 5.46388 7.49693 5.48799C7.37569 5.51211 7.25003 5.49973 7.13582 5.45243C7.02162 5.40512 6.92401 5.32501 6.85533 5.22223C6.78666 5.11945 6.75 4.99861 6.75 4.875C6.75 4.70924 6.81585 4.55027 6.93306 4.43306C7.05027 4.31585 7.20924 4.25 7.375 4.25Z" fill="#552DEA" />
                        </svg>
                        Gallery</>}>
                        <div className='pictureme_tab_content'>
                            {gallery.loader ?
                                <img className='smallDottedMedium' src={dottedLoaderSmall}
                                    alt='' /> :
                                <>
                                    {gallery.images.length > 0 ? (
                                        gallery.images.slice(0, gallery.limit).map((item, index) => (
                                            <div className='img-wrap position-relative' key={index}>
                                                <span onClick={() => navigator.clipboard.writeText(item.image_url.includes('pictureme/') ? ThemeContextData.apiURL + item.image_url :
                                                    ThemeContextData.apiURL + 'pictureme/' + item.image_url)} className='position-absolute copy-icon' style={{ cursor: "pointer", }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" viewBox="0 0 256 256"><path d="M240,88.23a54.43,54.43,0,0,1-16,37L189.25,160a54.27,54.27,0,0,1-38.63,16h-.05A54.63,54.63,0,0,1,96,119.84a8,8,0,0,1,16,.45A38.62,38.62,0,0,0,150.58,160h0a38.39,38.39,0,0,0,27.31-11.31l34.75-34.75a38.63,38.63,0,0,0-54.63-54.63l-11,11A8,8,0,0,1,135.7,59l11-11A54.65,54.65,0,0,1,224,48,54.86,54.86,0,0,1,240,88.23ZM109,185.66l-11,11A38.41,38.41,0,0,1,70.6,208h0a38.63,38.63,0,0,1-27.29-65.94L78,107.31A38.63,38.63,0,0,1,144,135.71a8,8,0,0,0,16,.45A54.86,54.86,0,0,0,144,96a54.65,54.65,0,0,0-77.27,0L32,130.75A54.62,54.62,0,0,0,70.56,224h0a54.28,54.28,0,0,0,38.64-16l11-11A8,8,0,0,0,109,185.66Z"></path></svg>
                                                </span>
                                                <img
                                                    src={
                                                        item.image_url.includes('pictureme/') ? ThemeContextData.apiURL + item.image_url :
                                                            ThemeContextData.apiURL + 'pictureme/' + item.image_url}
                                                    alt="Gallery Image"
                                                    className='img-fluid'
                                                />
                                            </div>
                                        ))
                                    ) : null}
                                </>
                            }
                        </div>
                        {gallery.limit < gallery.images.length ?
                            <div className='pt-4 d-flex justify-content-center'>
                                <Button variant='warning' onClick={() => {
                                    setGallery((prev) => {
                                        return {
                                            ...prev,
                                            limit: prev.limit + 12
                                        }
                                    })
                                }}
                                >Load More</Button>
                            </div> : ""}


                        <button type='button' className='btn custom-btn w-100 gap-3 mt-2' onClick={() => {
                            setGalleryImageModalShow(true);
                        }}>
                            Add Gallery Images
                        </button>
                    </Tab>
                    <Tab eventKey="stockimages" title={<>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.25 0.5H4.25C3.91848 0.5 3.60054 0.631696 3.36612 0.866116C3.1317 1.10054 3 1.41848 3 1.75V3H1.75C1.41848 3 1.10054 3.1317 0.866116 3.36612C0.631696 3.60054 0.5 3.91848 0.5 4.25V14.25C0.5 14.5815 0.631696 14.8995 0.866116 15.1339C1.10054 15.3683 1.41848 15.5 1.75 15.5H11.75C12.0815 15.5 12.3995 15.3683 12.6339 15.1339C12.8683 14.8995 13 14.5815 13 14.25V13H14.25C14.5815 13 14.8995 12.8683 15.1339 12.6339C15.3683 12.3995 15.5 12.0815 15.5 11.75V1.75C15.5 1.41848 15.3683 1.10054 15.1339 0.866116C14.8995 0.631696 14.5815 0.5 14.25 0.5ZM4.25 1.75H14.25V7.17031L12.9453 5.86562C12.7109 5.63138 12.3931 5.4998 12.0617 5.4998C11.7303 5.4998 11.4125 5.63138 11.1781 5.86562L5.29453 11.75H4.25V1.75ZM11.75 14.25H1.75V4.25H3V11.75C3 12.0815 3.1317 12.3995 3.36612 12.6339C3.60054 12.8683 3.91848 13 4.25 13H11.75V14.25ZM14.25 11.75H7.0625L12.0625 6.75L14.25 8.9375V11.75ZM7.375 6.75C7.74584 6.75 8.10835 6.64003 8.41669 6.43401C8.72504 6.22798 8.96536 5.93514 9.10727 5.59253C9.24919 5.24992 9.28632 4.87292 9.21397 4.50921C9.14163 4.14549 8.96305 3.8114 8.70083 3.54917C8.4386 3.28695 8.10451 3.10837 7.74079 3.03603C7.37708 2.96368 7.00008 3.00081 6.65747 3.14273C6.31486 3.28464 6.02202 3.52496 5.81599 3.83331C5.60997 4.14165 5.5 4.50416 5.5 4.875C5.5 5.37228 5.69754 5.84919 6.04917 6.20083C6.40081 6.55246 6.87772 6.75 7.375 6.75ZM7.375 4.25C7.49861 4.25 7.61945 4.28666 7.72223 4.35533C7.82501 4.42401 7.90512 4.52162 7.95243 4.63582C7.99973 4.75003 8.01211 4.87569 7.98799 4.99693C7.96388 5.11817 7.90435 5.22953 7.81694 5.31694C7.72953 5.40435 7.61817 5.46388 7.49693 5.48799C7.37569 5.51211 7.25003 5.49973 7.13582 5.45243C7.02162 5.40512 6.92401 5.32501 6.85533 5.22223C6.78666 5.11945 6.75 4.99861 6.75 4.875C6.75 4.70924 6.81585 4.55027 6.93306 4.43306C7.05027 4.31585 7.20924 4.25 7.375 4.25Z" fill="#552DEA" />
                        </svg>
                        Stock Images</>}>
                        <Form onSubmit={(e) =>
                            freepikAPICall(e)
                        }>
                            <InputGroup className='search-folder-form'>
                                <Form.Control
                                    placeholder="Search Images"
                                    aria-label="Search Projects"
                                    aria-describedby="basic-addon1"
                                    value={stockimageState.searchField}
                                    onChange={(e) => {
                                        setStockimageState((prev) => {
                                            return {
                                                ...prev,
                                                searchField: e.target.value,
                                            }
                                        })
                                    }}
                                />
                                <Button type='submit'><span className='icon-search me-0'></span></Button>
                            </InputGroup>
                        </Form>
                        <div className='pictureme_tab_content'>
                            {stockimageState.loader ? <img className='smallDottedMedium' src={dottedLoaderSmall} alt='' /> :
                                <>
                                    {stockimageState.images.length > 0 ? (
                                        stockimageState.images.slice(0, stockimageState.limit).map((item, index) => (
                                            <div className='img-wrap position-relative' key={index}>
                                                <span onClick={() => navigator.clipboard.writeText(item)} className='position-absolute copy-icon' style={{ cursor: "pointer", }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" viewBox="0 0 256 256"><path d="M240,88.23a54.43,54.43,0,0,1-16,37L189.25,160a54.27,54.27,0,0,1-38.63,16h-.05A54.63,54.63,0,0,1,96,119.84a8,8,0,0,1,16,.45A38.62,38.62,0,0,0,150.58,160h0a38.39,38.39,0,0,0,27.31-11.31l34.75-34.75a38.63,38.63,0,0,0-54.63-54.63l-11,11A8,8,0,0,1,135.7,59l11-11A54.65,54.65,0,0,1,224,48,54.86,54.86,0,0,1,240,88.23ZM109,185.66l-11,11A38.41,38.41,0,0,1,70.6,208h0a38.63,38.63,0,0,1-27.29-65.94L78,107.31A38.63,38.63,0,0,1,144,135.71a8,8,0,0,0,16,.45A54.86,54.86,0,0,0,144,96a54.65,54.65,0,0,0-77.27,0L32,130.75A54.62,54.62,0,0,0,70.56,224h0a54.28,54.28,0,0,0,38.64-16l11-11A8,8,0,0,0,109,185.66Z"></path></svg>
                                                </span>
                                                <img
                                                    src={item}
                                                    alt=""
                                                    className='img-fluid'
                                                />
                                            </div>
                                        ))
                                    ) : null}
                                </>
                            }
                        </div>
                        {stockimageState.limit < stockimageState.images.length ?
                            <div className='pt-4 d-flex justify-content-center'>
                                <Button variant='warning' onClick={() => {
                                    setStockimageState((prev) => {
                                        return {
                                            ...prev,
                                            limit: prev.limit + 12
                                        }
                                    })
                                }}
                                >Load More</Button>
                            </div> : ""}
                    </Tab>
                </Tabs>

                {/* <div className='pictureme_tab_content'>
                    {pictureme.loader ?
                        <img className='smallDottedMedium' src={dottedLoaderSmall}
                            alt='' /> :
                        <>
                            {pictureme.images.length > 0 ? (
                                pictureme.images.slice(0, pictureme.limit).map((item, index) => (
                                    <div className='img-wrap position-relative' key={index}>
                                        <span onClick={() => navigator.clipboard.writeText(item.image_url.includes('pictureme/') ? ThemeContextData.apiURL + item.image_url :
                                            ThemeContextData.apiURL + 'pictureme/' + item.image_url)} className='position-absolute copy-icon' style={{ cursor: "pointer", }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" viewBox="0 0 256 256"><path d="M240,88.23a54.43,54.43,0,0,1-16,37L189.25,160a54.27,54.27,0,0,1-38.63,16h-.05A54.63,54.63,0,0,1,96,119.84a8,8,0,0,1,16,.45A38.62,38.62,0,0,0,150.58,160h0a38.39,38.39,0,0,0,27.31-11.31l34.75-34.75a38.63,38.63,0,0,0-54.63-54.63l-11,11A8,8,0,0,1,135.7,59l11-11A54.65,54.65,0,0,1,224,48,54.86,54.86,0,0,1,240,88.23ZM109,185.66l-11,11A38.41,38.41,0,0,1,70.6,208h0a38.63,38.63,0,0,1-27.29-65.94L78,107.31A38.63,38.63,0,0,1,144,135.71a8,8,0,0,0,16,.45A54.86,54.86,0,0,0,144,96a54.65,54.65,0,0,0-77.27,0L32,130.75A54.62,54.62,0,0,0,70.56,224h0a54.28,54.28,0,0,0,38.64-16l11-11A8,8,0,0,0,109,185.66Z"></path></svg>
                                        </span>
                                        <img
                                            src={
                                                item.image_url.includes('pictureme/') ? ThemeContextData.apiURL + item.image_url :
                                                    ThemeContextData.apiURL + 'pictureme/' + item.image_url}
                                            alt="Gallery Image"
                                            className='img-fluid'
                                        />
                                    </div>
                                ))
                            ) : null}
                        </>
                    }
                </div>
                {pictureme.limit < pictureme.images.length ?
                    <div className='pt-4 d-flex justify-content-center'>
                        <Button variant='warning' onClick={() => {
                            setPictureme((prev) => {
                                return {
                                    ...prev,
                                    limit: prev.limit + 12
                                }
                            })
                        }}
                        >Load More</Button>
                    </div> : ""} */}

                {/* <Button type='button' className='btn custom-btn w-100 gap-3 mt-3'
                    onClick={() => setGalleryImageModalShow(true)}
                >Add Gallery Images </Button> */}
            </Box>
            {galleryImageModalShow ? <GalleryImageModal
                galleryAPI={GalleryAPI}
                show={galleryImageModalShow}
                setGalleryImageModalShow={setGalleryImageModalShow}
            /> : ""}
        </div>
    )
}
