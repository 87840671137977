import React, { useState, useContext } from 'react';

import { HtmlProps, HtmlPropsSchema } from '@usewaypoint/block-html';
import { Box } from '@mui/material';
import BaseSidebarPanel from './helpers/BaseSidebarPanel.tsx';
import TextInput from './helpers/inputs/TextInput.tsx';
import MultiStylePropertyPanel from './helpers/style-inputs/MultiStylePropertyPanel.tsx';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { ThemeContext } from '../../../../../projectContext/ThemeContext.js';
import { getTokenSession } from '../../../../../provider/Auth.js';
import LoaderJSON from '../../../../../assets/images/loading.json';
import Lottie from 'lottie-react';
import { Button } from 'react-bootstrap';
import ReactQuill, { Quill } from 'react-quill';
import dottedLoaderSmall from '../../../../../assets/images/dottedLoaderSmall.gif';
import UpgradeUserPlanModal from '../../../../UpgradeUserPlanModal/UpgradeUserPlanModal.jsx';

type HtmlSidebarPanelProps = {
  data: HtmlProps;
  setData: (v: HtmlProps) => void;
};
export default function HtmlSidebarPanel({ data, setData }: HtmlSidebarPanelProps) {
  const [, setErrors] = useState<Zod.ZodError | null>(null);

  const ThemeContextData = useContext(ThemeContext);
  const [generating, setGenerating] = useState(false);
  const [aiHelp, setAiHelp] = useState(false);
  const [fieldCounter, setFieldCounter] = useState(0);
  const [promptValue, setPromptValue] = useState("");
  const [generatedResult, setGeneratedResult] = useState("");
  const [blanceExhaustedModal, setBlanceExhaustedModal] = useState(false);

  const quillRef = React.useRef(null);

  const modules = {
    toolbar: [
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['bold', 'italic'],
      [{ 'color': [] }],
      ['link'],
      ['clean']
    ],
  };
  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video',
    'align', 'color', 'script'
  ];

  // const chatAPIHandler = () => {

  //   let newIndexObject = { role: "system", content: "You are a helpful assistant." };
  //   let messagesDataArr = [{ role: "user", content: data.props?.text + " " + promptValue }];

  //   messagesDataArr = [newIndexObject].concat(messagesDataArr);

  //   setGenerating(true);

  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", "Bearer " + getTokenSession());
  //   myHeaders.append("accept", "application/json");
  //   myHeaders.append("Content-Type", "application/json");

  //   var raw = JSON.stringify({
  //     GPT: "GPT-3.5-Ext",
  //     copy: 1,
  //     language: "English",
  //     productName: messagesDataArr,
  //     tone: "Appreciative",
  //     usecase: "GrammarImprove"
  //   });

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     redirect: "follow",
  //     body: raw,
  //   };


  //   fetch(
  //     ThemeContextData.apiURL + 'users/article-generate',
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result.response.data) {
  //         // setGeneratedResult(result.response.data);
  //         let text = result.response.data[0];
  //         console.log(text);
  //         updateData({ ...data, props: { ...data.props, text } });
  //       }
  //       else if (result.response.message === "balance exhausted") {
  //         setBlanceExhaustedModal(true)
  //       }

  //       setGenerating(false);
  //     })
  //     .catch((error) => { });
  // }

  const updateData = (d: unknown) => {
    const res = HtmlPropsSchema.safeParse(d);
    if (res.success) {
      setData(res.data);
      setErrors(null);
    } else {
      setErrors(res.error);
    }
  };

  // const exhaustedModalCloser = () => {
  //   setBlanceExhaustedModal(false);
  // }

  return (
    <>
    <BaseSidebarPanel title="Html block">
      <ReactQuill
        theme="snow"
        ref={quillRef}
        value={data.props?.contents ?? ''}
        modules={modules}
        formats={formats}
        onChange={(contents) => updateData({ ...data, props: { ...data.props, contents } })}
      // onChangeSelection={handleSelectionChange}
      />

{/* {generating ? <img src={dottedLoaderSmall} alt='loader' className='img-fluid' style={{ width: '32px', height: '32px', margin: '0 0 0 auto' }} /> :
          <Box style={{ textAlign: 'right', marginTop: 5, cursor: 'pointer' }} onClick={chatAPIHandler}>AI Help?</Box>}

      {aiHelp ?
        <Box style={{ gap: 15, display: 'flex', flexDirection: 'column' }}>
            <TextField
              label="Enter Prompt"
              type="text"
              value={promptValue}
              onChange={(e) => setPromptValue(e.target.value)}
            />
            {generating ? <Button variant='dark' className='mx-auto' onClick={(e) => { e.preventDefault(); }}>
              <Lottie className='LoaderJSONSVG' animationData={LoaderJSON} />
              Generating...
            </Button> :
              <Button
                variant='primary' className='mx-auto' onClick={chatAPIHandler}>
                <span className='icon-lg icon-article'></span>
                Generate Prompt
              </Button>
            }
        </Box>
        : ""} */}

      <MultiStylePropertyPanel
        names={['color', 'backgroundColor', 'fontFamily', 'fontSize', 'textAlign', 'padding']}
        value={data.style}
        onChange={(style) => updateData({ ...data, style })}
      />
    </BaseSidebarPanel>
    {/* <UpgradeUserPlanModal show={blanceExhaustedModal} exhaustedModalCloser={exhaustedModalCloser} /> */}
    </>
  );
}
