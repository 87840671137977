import { Container, Row, Col, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import CloseButton from 'react-bootstrap/CloseButton';
import Worldwide from './../../assets/images/worldwide.png';
import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import {
    getLastName,
    getFirstName,
    getemail,
    getToken,
    getPicture,
    savePicture,
    logout,
    getPassword, getTokenSession,
} from '../../provider/Auth';
import { ThemeContext } from "../../projectContext/ThemeContext";
import WordPress from "../../assets/website/wordpress.png";
import Shopify from "../../assets/website/shopify.png";
import TimeCalendar from './../../assets/images/time-calendar.png';
import { EditCustomEmailBuilderController } from '../../provider/EmailBuilderControlller';



export default function EditNewTemplateModal(props) {

    const ThemeContextData = useContext(ThemeContext);
    const [form, setForm] = useState({
        temp_name: "",
        temp_descrip: ""
    });

    useEffect(()=>{
        setForm({
            temp_name: props.tempData.temp_name,
            temp_descrip: props.tempData.temp_desc
        })
    }, [])


    const handleTextChange = e => {
        setForm((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            }
        })
    }

    const updateTemplateFunc = async () => {
        const result = await EditCustomEmailBuilderController(ThemeContextData, props.tempData.id, form.temp_name, form.temp_descrip)
        
        if(result.success){
            props.getAllTemplatesFunc();
            props.setEditNewTemplateModalShow(false);
        }
    }


    return (
        <>
            <Modal className='credit-report-modal' show={props.show} centered>
                    <>
                        <Modal.Header>
                            <div>
                                <Modal.Title className='fw-semibold flex-grow-1 pb-1'>
                                    <img src={TimeCalendar} className='img-responsove' alt='folder-image' />
                                    Update New Template
                                    <CloseButton
                                        onClick={() => {
                                            props.setEditNewTemplateModalShow(false);
                                        }}
                                    />
                                </Modal.Title>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={(e)=> {e.preventDefault();updateTemplateFunc()}}>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Template name</Form.Label>
                                            <Form.Control type="text" name="temp_name" placeholder="Name" value={form.temp_name} onChange={handleTextChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Template Description</Form.Label>
                                            <Form.Control as="textarea" row={3} name="temp_descrip" placeholder="Write description.." value={form.temp_descrip} onChange={handleTextChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Group>
                                    <Button disabled={form && form.temp_name && form.temp_descrip && form.temp_name.length > 0 && form.temp_descrip.length > 0 ? false : true} variant='primary' type={"submit"} className='px-3'>
                                        <span className='me-2 icon icon-file'>
                                        </span>
                                        Update Template
                                    </Button>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                    </>
            </Modal>
        </>
    )
}