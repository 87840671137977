function MessageString() {
    return {
        voceGenerating: "Hold tight! We're generating your voice...",
        someThingsWrong: "Yikes! Something went wrong. Let’s try that again!",
        articleSaving: "Hang on! Saving your article...",
        articleSaved: "Your article is all saved and ready to go!",
        articleUpdated: "Done and dusted! Your article is updated!",
        articleLive: "Boom! Your article is now live!",
        emailSaved: "Got it! Your email is saved.",
        emailSent: "Success! Your email is flying to its destination.",
        campaignGenerating: "Magic is happening! Your campaign is on its way... Just a few minutes more.",
        reCheckFile: "Uh-oh! Something’s not right. Double-check that file and give it another shot!",
        errorOccured: "An Error Occurred. Please Try Again."
    }
}

export {
    MessageString
}